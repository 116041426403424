const perks = [
    {
        title: '所有功能供您使用。',
        children: ['升级至高分辨率', '快速生成速度']
    },
    '无水印',
    '保持上传和生成的图像私密'
];

export const upgrade = {
    title: 'collov.ai 专业版',
    message: '升级至Pro以访问我们的AI室内设计工具和高级功能。根据需要调整你的积分计划，使你的愿景栩栩如生。',
    perks: JSON.stringify(perks),
    text: '专为室内设计师、房地产经纪人、家具供应商以及与家居行业相关的专业人士而设计。',
    perMonth: '每月',
    usage: {
        title: '有多个AI功能可用，每个功能具有不同的能力和价格。',
    },
    partnership: {
      title: '将AI整合进您的网站和公司',
      text: `开启新的流量之旅，十倍速达成营销成果。`,
      contact: '联系我们',
      LearnMore: '了解更多',
    },
};
