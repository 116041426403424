export const team = {
  // yourClient
  teamMenu:{
    createTeam:'Create Team',
    yourTeam:'Your Team',
    teamMembers:'Team Members',
    teamBilling:'Team Billing',
    yourDigital:'Your Digital Assets',
    yourProducts:'Your Products',
    yourStyle:'Your Styles & Materials',
    yourClient:'Your Client',
    teamName:'Team name',
    teamDescription:'Team description',
    manageTeam:'Manage your team',
    deleteTeam:'Delete Team',
    addMember:'Add member',
    purchaseMore: 'Purchase more',
    EditInfo: 'Edit info',
  },
  teamBilling:{
    title:'Team Billing',
    label:'Boost your business with Collov AI',
    savePlan:'Save more than 15% with our annual plan',
    howMember:'How many members do you have?',
    addOn:'Add-on',
    PurchaseAmount:'Purchase Amount',
    continueCheckout:'Continue to checkout',
    'SKU Management':'SKU Management',
    'Team Management':'Team Management',
    'Customer CRM':'Customer CRM',
    'Landing Page Integration':'Landing Page Integration',
    'Personalized Onboarding' :'Personalized Onboarding',
    'Customer Support':'Customer Support',
    'White Lable' :'White Lable',
    'Reporting & Analytics':'Reporting & Analytics',
    'Premium Styles Training Services' :'Premium Styles Training Services',
  },
  yourClient: {
    title: 'Contacts',
    import: 'Import',
    createContact: 'Create contact',
    search: 'Search',
    records: 'records',
    export: 'Export',
    editColumns: 'Edit columns',
    table: {
      name: 'Name',
      leadScore: 'Lead Score',
      email: 'Email',
      status: 'Status',
      createDate: 'Create Date',
      location: 'Location',
    }
  },
  yourProducts: {
    title: 'Your Products',
    table: {
      photo: 'Photo',
      name: 'Name',
      removeBg: 'Remove bg',
      category: 'Category',
      vendor: 'Vendor',
      price: 'Price',
      action: 'Action',
    },
    edit: 'Edit',
    no: 'No',
    remove: 'Remove',
    addProduct: 'Add Product',
    addExcel: 'Add Excel',
  },
  yourClientEdit: {
    basicInfo: 'Basic Info',
    save: 'Save',
    firstName: 'First Name',
    lastName: 'Last Name',
    status: 'Status',
    email: 'Email',
    location: 'Location',
    lastActivityDate: 'Last Activity Date',
    createDate: 'Create Date',
    assignTo: 'Assign to',
  }
}
