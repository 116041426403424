export const enterprise = {
  buttons: {
    learnMore: '了解更多',
    successfulStory: '成功案例',
    contactNow: '立即联系'
  },
  landing: {
    cover: {
      title: `创新、迅捷、交付`,
      subTitle: '让设计的未来提升你的业务',
      name: `利用尖端的生成式AI技术将您独特的设计风格转化为数字资产，允许实时，可定制的设计可视化。`,
      contactNow: '马上联系',
      TryNow: '马上尝试',
      demoVideo: '演示视频',
      submit: '提交你的商业兴趣',
      talk: '与我们交谈'
    },
    boost: {
      title: '今天开始，让你的事业激增',
      list: ['增加收入和设计效率', '降低内部设计成本', '提高客户参与度']
    },
    fastGen: {
      name: '闪电般的迭代生成',
      desc: '通过我们的快速生成功能实现即时设计迭代，加速您的创意和协作过程。',
      btn: '立即生成',
      SeeDetails: '查看详情'
    },
    realWorld: {
      name: '真实世界的材质库',
      desc: '直接从地板、墙壁和油漆供应商处直接采购的大量真实材料可供选择，将家居设计与您的购物体验无缝连接起来。',
      btn: '现在试试',
      SeeDetails: '查看详情'
    },
    future: {
      title: '设计的未来'
    },
    digital: {
      title: '大规模创建你的数字资产',
      name: `colllov.ai的专有AI模型通过学习您的项目来理解独特的设计风格，将风格转换为统一的数字资产。`,
      list: ['可定制您的品牌', '跨所有设备统一资产', '集中控制和管理'],
      products: ['Dreambooth 木', '碳灰', '云白', '雾灰', '摩卡', '流星灰', '星际银', '晨曦金', '烟灰']
    },
    elevate: {
      title: '在几秒钟内提升你的设计',
      name: `利用您独特的风格库，Collov AI在几秒钟内检测，适应和可视化客户空间中的首选风格和产品。`,
      list: ['结构保持', '高质量3D渲染', '可购买家具清单']
    },
    mask: {
      title: '用几次点击迭代你的设计',
      name: `AI标记编辑器实现交互式设计体验，只需点击几下即可生成实时设计迭代。`,
      list: ['部分家具修改', '灵活的风格变化', '智能AI标记']
    },
    leaders: {
      title: `备受业界领袖信赖`,
      name: `我是一个段落，点击这里添加你自己的文字和编辑我。非常容易，只需点击“编辑文本”或双击我，添加自己的内容，并对字体进行更改。我是你讲故事的好地方，让你的用户更多地了解你。`
    },
    clientSay: {
      title: `客户好评`,
      name: `准备最大化你的生产力与未来的设计`,
      list: [
        {
          name: 'Isabella Turner',
          address: 'CEO, Furniture Industry',
          content: `“Collov AI已经改变了我们品牌的游戏规则。通过整合他们的技术，我们将自己定位为创新者。客户喜欢人工智能驱动的设计建议，这总是把他们引向我们的高级系列。”`
        },
        {
          name: 'Emily Peterson',
          address: 'Real Estate Developer, Real Estates Industry',
          content: `“房地产展示从来没有像现在这样高效。有了这个AI解决方案，我们的潜在买家可以看到个性化的内饰，从而更快地做出决策，增加销量。”`
        },
        {
          name: 'Liam Howard',
          address: 'Sales Director, Kitchen Cabinet Industry',
          content: `“自从我们开始使用Collov AI以来，我们的橱柜销量大幅增长。根据客户的喜好进行可视化的实时设计，这无疑给了我们竞争优势。”`
        },
        {
          name: 'Sophia Martinez',
          address: 'Digital Strategist, Online Furniture E-commerce',
          content: `Collov AI检测和推荐相似家具的功能降低了我们的购物车放弃率。我们的客户现在对他们的选择充满信心，这显著地促进了我们的销售。”`
        },
        {
          name: 'Lucas Kim',
          address: 'Founder, Construction Industry',
          content: `“在家具界，保持时尚和技术前沿是必须的。有了Collov AI，我们成功地为客户提供了符合他们品味的即时灵感，使我们成为他们最喜欢的设计站。”`
        },
        {
          name: 'Ava Chen',
          address: 'Property Staging Specialist, Real Estates Industry',
          content: `“我们准备出售房屋，Collov AI的工具可以最大限度地减少我们的工作量。个性化的设计产生给我们的客户和买家留下了深刻的印象，从而实现了快速的周转。”`
        },
        {
          name: 'Ethan Ruiz',
          address: 'Marketing Manager, Commercial Design Industry',
          content: `“我们的网站流量和参与度大幅提升。通过嵌入Collov的人工智能工具，我们不仅可以提供独特的设计，还可以毫不费力地捕捉潜在的线索。”`
        },
        {
          name: 'Olivia Fischer',
          address: 'Chief Designer, Decor Company',
          content: `“作为一名设计师，有时候我需要灵感。Collov AI在几秒钟内提供无限的想法，使过程更顺畅，更高效。这是设计师的梦想!”`
        },
        {
          name: 'Noah Singh',
          address: 'COO, Tiles Industry',
          content: `“Collov AI的精准销售功能是一个启示。我们的销售团队与潜在客户进行了更有意义的对话，从而显著提高了转化率。”
`
        },
        {
          name: 'Michael Thompson, Creative Director',
          address: '',
          content: `“Collov AI Real Time has revolutionized our workflow. The real-time design feature has significantly reduced our project turnaround time, allowing for more efficient and creative outcomes. It's a must-have tool for any design professional.”`
        },
        {
          name: 'Sarah Kim, Freelance Graphic Designer',
          address: '',
          content: `“As a freelance graphic designer, Collov AI's real-time design capabilities have transformed the way I work. It's intuitive, fast, and incredibly reliable. This tool has become an indispensable part of my creative process.”`
        },
        {
          name: 'Alex Martinez, Senior Interior Designer',
          address: '',
          content: `“I've used various design tools in my career, but Collov AI stands out for its real-time design features. It offers unparalleled precision and speed, making complex design tasks simpler and more enjoyable.”`
        },
        {
          name: 'Emily Patel, Lead Product Designer',
          address: '',
          content: `“ Collov AI Real Time is a breakthrough in the design industry. Its real-time design interface is not only innovative but also extremely user-friendly, making it ideal for both beginners and experienced designers.”`
        },
        {
          name: 'David Liu, Project Manager',
          address: '',
          content: `“Our team has been using Collov AI for several projects, and the real-time design aspect has greatly enhanced our collaboration and creativity. It allows for immediate feedback and adjustments, which is invaluable in a fast-paced design environment.”`
        }
      ]
    }
  },
  dashboard: {
    title: 'AI设计仪表盘',
    cards: [
      { name: '生成的照片数量', value: '', key: 'recordCount' },
      // { name: '独立用户数', value: '3443' },
      // { name: '新独立用户', value: '359' },
      { name: '平均时间', value: '', key: 'avgTime', unit: '秒' }
    ],
    tabs: ['总体', '上周', '上个月'],
    Product: '产品',
    Add: '添加',
    columns: [
      { title: '房间类型', width: 100, dataIndex: 'roomType' },
      { title: '风格', dataIndex: 'style', width: 150 },
      { title: '隐私', dataIndex: 'privacy' },
      { title: '状态', dataIndex: 'status', scopedSlots: { customRender: 'status' } },
      { title: '原图', dataIndex: 'uploadUrl', width: 100, scopedSlots: { customRender: 'original' } },
      { title: '生成', dataIndex: 'generateUrl', width: 100, scopedSlots: { customRender: 'generate' } },
      { title: '时间', dataIndex: 'time', width: 150 },
      {
        title: '操作',
        dataIndex: 'privacy',
        width: 80,
        scopedSlots: { customRender: 'action' }
      }
    ],
    success: {
      title: '感谢您发送所请求的信息。',
      subtitle: '我们的专家将对其进行审核，并尽快与您联系。感谢您的合作，期待为您提供帮助。'
    }
  },

  aiCabinetDesign: {
    buttons: {
      tryItNow: '立即尝试',
      purchaseNow: '立即购买',
      successStory: '成功案例',
      tryNow: '立即尝试',
      contactUs: '联系我们',
      buyNow: '立即购买'
    },
    cabinetRealFill: {
      title: '橱柜真实填充',
      text: '全球首个唯一的供应商提供橱柜渲染的AI设计平台'
    },
    sku: {
      title: 'SKU转数字资产',
      text: '专有AI了解并学习您的SKU，精准将橱柜升级为数字资产。'
    },
    realCabinet: {
      title: 'AI设计中的真实橱柜',
      text: '精确再现样式、材料和所有细节，以AI技术可视化真实橱柜设计。'
    },
    bridging: {
      title: '连接设计与销售',
      text: '通过AI设计中的真实、可购买的橱柜，简化从设计到销售的流程，提高销售效率。'
    },
    buyNow: {
      cabinetRealFill: {
        key: 'cabinetRealFill',
        title: '橱柜真实填充',
        text: '白标整合<br/>50种风格',
        billedAnnually: '年度结算',
        price: '6,000',
        year: '年',
        mo_price: '2,499',
        mo_unit: '月'
      },
      addOn: {
        key: 'addOn',
        title: '附加服务',
        text: '$5/额外设计风格<br/>部分翻新：$100/月<br/>魔术橡皮擦：$100/月'
      },
      billedAnnually: '按年付费'
    }
  },
  aiNativeHardware: {
    cover: {
      title1: 'AI原生硬件',
      title2: '人工智能办公桌A1',
      subTitle: '彻底改变客户获取',
      desc: '利用先进的人工智能原生硬件屏幕提高商店和博览会的参与度',
      contact: '联系我们',
      pricing: '价钱',
      try: '现在试试',
      demo: '试试Demo'
    },
    attract: {
      title: '吸引人流量',
      desc: '无论是在商店还是博览会，您的室内设计或房地产业务都可以通过人工智能原生大屏幕来增加人流量。 Golden Home & Collov 在 KBIS2024 上展示了人工智能硬件带来的流量显着增加。',
      success: '成功的故事',
      increase: '增加',
      increase1: {
        percent: '10x',
        desc: '在客户参与度方面'
      },
      increase2: {
        percent: '5x',
        desc: '询盘率'
      },
      increase3: {
        percent: '100%',
        desc: '现场优惠'
      }
    },
    spot: {
      title: '提升你的业务',
      desc: 'Collov AI的革命性工具通过学习和复制品牌特定的室内设计风格来改变设计，实现快速定制设计和全球扩展，重新定义效率和定制。AI Desk A1是世界上第一个也是唯一一个AI原生硬件。',
      try: '现在试试'
    },
    cards: {
      card1: {
        title: '展览',
        text: '告知并吸引参观者参与展览。人工智能办公桌，其特点是将大屏幕与生成式人工智能相结合，用于室内设计，所有这些都通过定制的本地Android应用程序集成。'
      },
      card2: {
        title: '零售',
        text: '优化任何室内商业商店，以获得更好的购物体验。毫不费力，即时创建和个性化演示立即与Collov AI，并确保演示始终如一地展示室内设计的顶级偏好。'
      },
      card3: {
        title: '企业',
        text: '将现场查询无缝转换为现场交易。智能屏幕和应用程序提供访问更多内容和设备。'
      }
    },
    SuccessStories: {
      title: '成功案例',
      card1: {
        title: 'Collov AI',
        text: '凭借展台上的人工智能室内办公桌，Collov在短短三天内获得了600份咨询，并获得了30多份合同，其中包括与John Clark达成的一项引人注目的现场协议，即与位于圣克莱门特的室内设计工作室Modern Shelving签订人工智能办公桌合同。'
      },
      card2: {
        title: 'GoldenHome',
        text: '人工智能大屏幕可以提高商店和展会的访客数量。gold home Living Co.， Ltd是一家以橱柜和个性化家具闻名的上市公司，它很好地利用了我们的人工智能解决方案。他们的战略使用使KBIS 2024获得了3000多笔交易。'
      },
      card3: {
        title: '现代的架子',
        text: '现代货架提供可定制的，多功能货架可调节，联锁设计独特，灵活的存储，补充任何家庭或办公室装饰。他们已经与Collov AI建立了战略合作伙伴关系，并将探索将生成式AI用于风格化设计'
      }
    },
    Efficiency: {
      btn: '成功的故事',
      after: '增加',
      text1: '在客户参与方面',
      text2: '查询率',
      text3: '现场交易'
    },
    TopExhibitions: {
      title: '顶级展览',
      info: `Collov AI的革命性工具通过学习和复制品牌特定的室内设计风格来改变设计，实现快速定制设计和全球扩展，重新定义效率和定制。AI Desk A1是世界上第一个也是唯一一个AI原生硬件。 `,
      type1: '厨房和浴室',
      type2: '室内设计',
      type3: '房地产'
    },
    PickPlan: {
      title: '选择最适合你的计划',
      item1: {
        title: '橱柜设计',
        info: '使用无代码模板进行10分钟设置。',
        mo: ' / 月 ',
        currency: '¥',
        price: '250',
        ps: '(按年收费)',
        btn: '立即开始'
      },
      item2: {
        title: 'AI桌面版',
        info: '购买软件后使用上面的Collov AI软件计划解锁全部功能。',
        mo: '',
        currency: '¥',
        price: '6,000',
        ps: '',
        btn: '立即开始'
      }
    },
    personalized: {
      title: '个性化定制服务',
      desc: '提高大屏幕的参与度：尺寸范围为 32 至 55 英寸。享受由生成式人工智能驱动的多方面互动体验。',
      contact: '联系我们'
    },
    ready: {
      title: '准备好利用 Collov AI 提高参与度并促进销售了吗？',
      contact: '现在联系'
    }
  },
  payment: {
    clientSay: {
      title: `客户见证`,
      list: [
        {
          name: 'Emily Thompson',
          address: '厨房设计师，Elite Interiors',
          content: `作为设计师，Collov AI改变了游戏规则。在客户实际的厨房空间中快速可视化一系列橱柜设计，极大地简化了设计流程。这不仅是为了节省时间；更是为了在几分钟内将客户的愿景栩栩如生地呈现出来。客户的积极反馈令人振奋。`
        },
        {
          name: 'Michael Anderson',
          address: '首席技术官，HomeCraft Cabinets',
          content: `将Collov AI集成到我们的网站中非常轻松。不到30分钟就搞定了，影响是立竿见影的。AI根据颜色、材料和图案偏好定制设计的能力不仅提高了我们的转化率，还增强了客户满意度。这是一项将我们推到数字零售领域前沿的技术飞跃。`
        },
        {
          name: 'Sarah Jenkins',
          address: '客户体验经理，Modern Kitchen Solutions',
          content: `Collov AI彻底改变了我们与客户互动的方式。降低的沟通障碍意味着我们的客户可以更自由地表达他们的偏好，并立即看到它们栩栩如生。这导致了更快的决策过程，减少了交货时间，为我们的客户提供了更愉快的体验。这是提高客户满意度的无价工具。`
        }
      ]
    }
  },
  solutions: {
    AISolutions: `Collov AI解决方案`,
    applicationsText: `高价值用例的即用AI应用程序。`,
    AIDesignStudio: 'AI设计工作室',
    AIVideoCreator: 'AI视频创作者',
    AIVideoGenerator: '短视频生成',
    AIRealtimeDesign: 'AI 实时设计',
    wallFlooringRealFill: '墙壁和地板真正填充',
    FlooringDesign: '地面设计',
    HomeRedesign: '主页重新设计',
    PartialRedesign: '部分重新设计',
    wallpaperDesign: '墙面设计',
    CountertopDesign: '台面设计',
    furnitureReplacement: '家具更换',
    aiHomeRedesign: 'AI主页更换',
    characterRealTime: '实时角色设计',
    RealTimeWebcam: '实时网络摄像头奇迹',
    RealTimePhoto: '实时照相馆',
    AIStoryteller: 'AI讲故事者',
    ProductEngine: '产品引擎',
    MarketingPersonalization: '营销个性化',
    SalesDigitalization: '销售数字化',
    AICabinetDesign: 'AI橱柜设计',
    AINativeHardware: 'AI原生硬件',
    Overview: '概览',
    FreePackage: '免费套餐',

    earlyTitle: '早期创新者试点计划',
    earlyText: `通过我们的免费试点计划，踏上一个充满远见的AI之旅，利用Collov爱好者庞大的社区，推动您的品牌走向未来。`,
    Free: '免费整合',
    core: '核心AI体验',
    onboarding: '简化的入门流程',
    CollovAIDeveloperTools: `Collov AI开发工具`,
    CollovAIDeveloperToolsText: `Collov AI为您提供一套全面的工具，以便快速开发和无缝部署基于人工智能的设计。`,
    CollovAIAPI: `Collov AI API`,
    CollovAITemplate: `Collov AI SDK和模板`,
    AIDatabase: `Collov AI数据库`,
    AIDatabaseText: `Collov AI将您的风格和SKU转化为可扩展的数字资产，用于AI设计`,
    DigitalSKU: `数字SKU`,
    DigitalStyles: `数字设计风格`,

    IgniteCover: '通过AI激发设计灵感',
    IgniteCoverDes: `Collov AI将您独特的风格转化为数字资产，实现实时的动态个性化室内设计。`,
    CreateAssets: '大规模创建您的数字资产',
    CreateAssetsDes: `CollovGPT的专有AI模型通过学习您的项目来理解独特的设计风格，将这些风格转化为统一的数字资产。`,
    brandAssets: '可定制以适应您的品牌',
    assetsDevices: '在所有设备上统一的资产',
    CentralizedControls: '集中控制',
    ElevateDesign: '在几秒钟内提升您的设计',
    ElevateDesignDes: `利用您的独特风格库，Collov AI能够在几秒钟内检测、调整并可视化客户空间中的首选风格和产品。`,
    structurePreserved: '结构保留',
    HighQuality: '高质量3D渲染',
    FurnitureList: '可购物的家具清单',
    Iterate: '通过点击几下迭代您的设计',
    IterateDes: `一套AI驱动的编辑工具支持交互式设计体验，只需点击几下即可生成实时设计迭代`,
    PartialFurnitureModification: '部分家具修改',
    BackgroundRemover: '背景移除器',
    SmartAIMarkups: '智能AI标记',
    Ready: '准备好通过Collov AI改变您的设计体验了吗？',
    BetterStorytelling: '更好的叙事',
    BetterStorytellingDes: '在更好的叙事背景下，通过将您的产品和概念无缝整合到动态环境中，增强吸引力，创造令人难忘、沉浸式的设计体验。',
    EffortlessTransformation: '轻松转换风格',
    EffortlessTransformationDes:
      '通过家具背景交换重新定义您的空间。轻松切换不同的生活方式，找到家居的完美搭配，将真实的家具可视化在您的环境中。',
    VisualizeSpace: '在您的空间中可视化真实的装饰',
    VisualizeSpaceDes: '即时查看各种真实的地板、墙壁纹理和油漆颜色在您的实际环境中的效果，使选择完美材料变得更加容易。',
    BroughtLife: '呈现生活',
    BroughtLifeDes:
      '让您的设计故事栩栩如生。Collov AI storyboard使您能够将设计概念发展成引人入胜的故事情节，完整的视觉效果，为更具沉浸感的设计体验创造更引人注目的叙事。',
    videoContact: '准备通过Collov AI改变您的视频体验吗？',
    VideoContent: '通过AI重新想象的视频内容',
    VideoDes: '利用不仅可以使渲染动画化，而且还可以使头像栩栩如生并浓缩您的故事的AI，改变您对视频的看法。',
    VideoInterpreter: '视频解释器',
    VideoTransform:
      '使用我们由AI驱动的语音将文本转化为自然而富有表现力的语音，通过个性化的虚拟头像为设计演示注入生机，获得更顺畅、更定制的视频体验。',
    VideoSummarizer: '视频摘要生成器',
    VideoSummarizerdes: '通过简洁的视频摘要提升设计沟通，捕捉项目的精髓，并从充满灵感的视频中提炼出关键的设计见解。',
    VideoTransformer: '视频转换器',
    VideoTransformerText: '通过直接从渲染中创建视频，提升您的设计演示，用引人入胜的叙事内容丰富您的营销和社交媒体。',
    productshopping: '准备通过 Collov AI 改变您的产品购物体验吗？',
    productElevate: '通过人工智能提升购物体验',
    productElevateDes: 'Collov AI 通过上下文感知的设计渲染和智能产品推荐，改变了产品发现的方式。',
    DigitalProduct: '大规模数字产品资产',
    DigitalProductDes: '将产品转化为可定制的目录，增强产品发现旅程，无论是在设计前还是设计后。',
    PointPurchase: '购买时机',
    PointPurchaseDes: '即时可购买的产品入口，确保根据个体需求量身定制的优化销售匹配。Collov AI 抓住合适的时机以上下文化的方式吸引注意力。',
    VisualDiscovery: '视觉搜索与发现',
    VisualDiscoveryDes: 'AI 动力的推荐引擎帮助您的购物者在设计视觉中轻松发现和搜索策划的产品，无缝地将设计灵感与购买联系起来。',
    marketingEnhance: '通过视觉叙事和人工智能增强营销',
    marketingEnhanceDes: 'Collov AI 生成并将设计视觉转化为强大的营销资产，推动参与度，强化品牌故事。',
    VisualContentGenerator: '视觉内容生成器',
    VisualContentGeneratorDes:
      '生成为社交媒体、影响者活动、展示广告和电子邮件营销量身定制的丰富视觉。通过 Collov AI 在所有营销平台上打造一致而引人注目的视觉语言。',
    InteractiveLanding: '互动式着陆页',
    InteractiveLandingDes:
      '将 AI 设计的能力集成到您的网站中，仅需几行代码，将静态页面转变为互动体验。通过 Collov AI 提升品牌，设定创新 AI 区别的新行业标杆。',
    Showroom: '24小时数字展厅',
    ShowroomDes:
      '迅速启动一个 24 小时数字展厅，始终展示您的最新产品和设计。不仅仅是观看，还要培养一个充满活力的社区，在其中客户贡献并共鸣于动态内容。',
    marketingReady: '准备通过 Collov AI 转变您的品牌和营销体验吗？',
    saleReady: '准备好通过设计的未来提升销售业绩吗？',
    saleDes: '通过快速生成的人工智能视觉增强客户互动，将您的销售推向数字时代。',
    ChatDesigner: '聊天设计师',
    ChatDesignerDes:
      '通过我们流畅的对话式人工智能界面体验无缝销售之旅。通过理解自然语言，它彻底改变了设计流程，促进了与客户更互动和引人入胜的体验。',
    collovCRM: 'Collov CRM',
    collovCRMDes: '深入研究用户网络互动的简化仪表板。结合先进的后台，它增强了潜在客户管理和参与度，树立了销售数字化的黄金标准。',
    scan: '扫描，看到，购买',
    scandes:
      '通过在展厅中使用 QR 扫描，将客户的移动设备变成强大的设计门户。在短短几分钟内，看着他们的设计灵感变为现实，突显您的产品在他们的个人空间中。',
    freeCover: `早期创新者试点，<br/><span class="free">免费。</span>`,
    freeCoverDes: '成为第一批利用我们人工智能驱动的室内设计工具的早期采用者之一，该工具旨在简化创意过程并推动您的品牌。',
    AIJourney: '踏上AI之旅',
    AIJourneyDes: '从有前途的试点过渡到实现AI设计的全部好处，使业务规模化。',
    Pilot: '试点',
    Scale: '规模化',
    Beyond: '超越',
    Integration: '集成',
    DesignStyles: '设计风格',
    RoomType: '房间类型',
    CatalogUpload: '目录上传',
    PartialMagic: '局部和魔术编辑器',
    AIDesignAnalyzer: 'AI设计分析器',
    Template: '模板',
    free: '免费',
    CustomDevelopment: '定制开发',
    styles: '风格',
    types: '类型',
    SKU: 'SKU',
    powerDiscover: '探索AI驱动的室内设计的力量',
    powerDiscoverDes: '轻松集成到您的网站，体验强大的AI之旅 - 上传照片，选择风格和房间类型，轻松获得完成的设计。',
    LaunchPilot: '启动您的AI试点',
    LaunchPilotDes: "我们从最初的'如何'指导到一个完全运作的试点，为创新铺平道路。",
    ModuleIntegration: '步骤1：模块集成',
    websiteEmbed: '将Collov的AI设计模块嵌入到您的网站中，只需三行代码。快速。简单。高效。',
    InterfaceConfiguration: '步骤2：界面配置',
    InterfaceConfigurationPersonalize: '使用您品牌的标志个性化用户界面，保持一致的专业外观。',
    EnjoyDesign: '步骤3：享受AI设计',
    LeverageDesign: '利用AI设计的力量提升您的产品，推动业务增长。',
    creditHistory: '积分记录',
    billingInformation: '账单信息',
    Pricing: '定价',
    Upgrade: '升级'
  },
  AITools: {
    videoGenerator: {
      title: '轻松创作视觉故事',
      subtitle: '从文字到世界 - Collov AI 视频生成器',
      text: '让你的想象力展翅高飞。Collov AI 领先的 AI 视频生成器将文字和图像转化为迷人的视频，轻松将你的创意编织成引人入胜的视觉故事。',
      try: '立即尝试',
      turnTitle: '将任何想法或图像转化为视频，瞬间完成',
      turnText: '发现视频生成器如何将文本提示和图像转化为引人入胜的视频',
      turnName: '从文字到视频',
      turnInfo: '只需一个文本提示，就可以创建你设想的任何视频。如果你能想到，你就能视觉实现它。',
      turnLabel: '幻想夜景，粉色天空，金色河流，Telon 风格的长城，发光的道路。',
      turnBtn: '立即生成',
      magicName: '从静态到魔法',
      magicText: '通过添加动态运动，将静态图像变得生动，将单一图像转化为引人入胜的视频叙事。',
      possName: '协同作用，无限可能',
      possText: '与 Collov 的重新设计工具包互通，将设计转化为视频，或为创意、营销和销售企业创造任何内容。',
      stepTitle: '3个简单步骤创建视频',
      ready: '视频创作的新时代',
      readyText: '从今天开始打造你的未来杰作，重新定义视频叙事的可能性。',
      tools: [
        { name: '生动叙事', text: '将简单的文字和图像转化为动态视频叙事' },
        { name: '高质量输出', text: '以卓越的清晰度和细节制作专业级视频' },
        { name: '高效简便', text: '用户友好的平台，快速生成高质量视频' }
      ],
      steps: [
        { name: '输入你的信息', text: '按照步骤输入你的脚本或参考图像' },
        { name: 'AI 创造你的视频', text: 'Collov 视频生成器分析你的输入并创建令人惊叹的视频' },
        { name: '下载并享受', text: '导出，享受并与世界分享你的视频' }
      ]
    },
    realtimeDesign: {
      title: '轻松创作视觉故事',
      subtitle: '从文字到世界 - Collov AI 视频生成器',
      text: '让你的想象力展翅高飞。Collov AI 领先的 AI 视频生成器将文字和图像转化为迷人的视频，轻松将你的创意编织成引人入胜的视觉故事。',
      try: '立即尝试',
      turnTitle: '将任何想法或图像转化为视频，瞬间完成',
      turnText: '发现视频生成器如何将文本提示和图像转化为引人入胜的视频',
      turnName: '从文字到视频',
      turnInfo: '只需一个文本提示，就可以创建你设想的任何视频。如果你能想到，你就能视觉实现它。',
      turnLabel: '幻想夜景，粉色天空，金色河流，Telon 风格的长城，发光的道路。',
      turnBtn: '立即生成',
      magicName: '从静态到魔法',
      magicText: '通过添加动态运动，将静态图像变得生动，将单一图像转化为引人入胜的视频叙事。',
      possName: '协同作用，无限可能',
      possText: '与 Collov 的重新设计工具包互通，将设计转化为视频，或为创意、营销和销售企业创造任何内容。',
      stepTitle: '3个简单步骤创建视频',
      ready: '视频创作的新时代',
      readyText: '从今天开始打造你的未来杰作，重新定义视频叙事的可能性。',
      tools: [
        { name: '生动叙事', text: '将简单的文字和图像转化为动态视频叙事' },
        { name: '高质量输出', text: '以卓越的清晰度和细节制作专业级视频' },
        { name: '高效简便', text: '用户友好的平台，快速生成高质量视频' }
      ],
      steps: [
        { name: '输入你的信息', text: '按照步骤输入你的脚本或参考图像' },
        { name: 'AI 创造你的视频', text: 'Collov 视频生成器分析你的输入并创建令人惊叹的视频' },
        { name: '下载并享受', text: '导出，享受并与世界分享你的视频' }
      ]
    },
    wallFlooringRealFill: {
      title: '墙壁和地板真实填充',
      subTitle: '通过 AI 精度可视化空间中的真实材料',
      describe: '改变您设计空间的方式，将地板、墙壁和绘画的真实材料与 AI 精度无缝集成，以令人惊叹的真实感将您的愿景变为现实。',
      tryNowBtn: '立即尝试',
      before: '之前',
      after: '之后',
      btnGroup: [
        {
          title: '逼真的可视化效果',
          subTitle: '准确地可视化真实材料，实现逼真的空间规划'
        },
        {
          title: '创意灵活性',
          subTitle: '尝试多种材料和颜色'
        },
        {
          title: '知情决策',
          subTitle: '查看您空间中的材料，以做出明智的设计和购买决策'
        }
      ],
      content: {
        title: '真实材料整合的空间变换',
        subTitle: '真实的材料，现实的设计，实现的梦想',
        panel: [
          {
            title: '真实世界的材质库',
            describe: '直接从地板、墙壁和油漆供应商处直接采购的大量真实材料可供选择，将家居设计与您的购物体验无缝连接起来。'
          },
          {
            title: '逼真的可视化',
            describe: '我们的新级别可视化功能可自动调整光照、阴影和图案，确保您的照片呈现超逼真的效果。'
          },
          {
            title: '智能房间识别',
            describe: '经过微调的人工智能模型可以准确理解房间结构和布局，优化材料放置和设计和谐。'
          }
        ]
      },
      userPanel: {
        title: '我们的客户怎么说',
        list: [
          {
            name: '阿丽亚·惠特莫尔',
            describe: `Collov AI Material Real Fill 彻底改变了我的家居设计方式。能够在自己的空间中看到真实的地板帮助我自信地做出决定。这就像拥有一个触手可及的专业设计师和陈列室！`
          },
          {
            name: '莱昂·布莱克本',
            describe: `作为一名室内设计师，Colov AI 改变了游戏规则。逼真的可视化效果和丰富的素材库节省了我无数的时间。我的客户对我们在做出任何承诺之前能够如此准确地设想他们的空间印象深刻。`
          },
          {
            name: '西耶娜·帕特尔',
            describe: `起初我对使用人工智能设计工具持怀疑态度，但 Collov AI 赢得了我的支持。在我的实际房间中轻松可视化不同的材料和图案使我的装修项目变得更加简单和愉快。看到我的想法如此准确、快速地变成现实，真是太神奇了！`
          }
        ]
      },
      discoverMore: {
        title: '发现更多'
      },
      discoverNow: {
        title: '立即发现',
        describe: `借助 Collov AI 深入真实材料和高级可视化的世界。今天就开始改造和增强您的空间的旅程 - 您的理想设计正在等待着您。`
      }
    },
    characterRealTime: {
      title: '实时角色设计',
      subTitle: '即时角色魔法',
      desc: '将 3D 基础模型实时转换为逼真的角色。通过元素拖放、魔笔和智能文本提示编辑来释放创造力。您的想象力，现在即刻实现。',
      try: '现在试试',
      iconGroup: {
        rapid: {
          name: '快速创作',
          desc: '将角色设计从几小时简化为几秒钟'
        },
        unlimited: {
          name: '无限定制',
          desc: '使用多功能工具集轻松为您的角色添加独特特征'
        },
        smart: {
          name: '智能简单',
          desc: '通过文本引导编辑轻松实现复杂的设计'
        }
      },
      future: {
        title: '角色设计的未来触手可及',
        desc1: '创造、定制、吸引',
        subtitle: '实时角色渲染',
        desc2: '借助 Collov 的尖端技术，瞬间将 3D 基础模型转变为逼真的人物，让角色立即栩栩如生。'
      },
      brush: {
        subtitle: '魔法画笔',
        desc: '通过触摸提升你的角色。神奇的画笔和拖放功能使添加复杂的细节和独特的元素变得轻松而有趣。'
      },
      prompt: {
        subtitle: '文本提示智能编辑',
        desc: '使用智能文本提示简化复杂的编辑。通过直观、用户友好的命令定制角色的每个细节，以获得精确、专业的结果。'
      },
      precision: {
        title: '精准和谐',
        subtitle: '角色设计的独特优势',
        control: {
          title: '精准控制',
          desc: '通过精确调整基础模型的角度和表情来保留原创性'
        },
        gaming: {
          title: '游戏和动漫就绪',
          desc: '针对充满活力、行业特定的角色创建进行了优化'
        }
      },
      discoverMore: {
        title: '发现更多：'
      },
      discoverNow: {
        title: '立即开始创建您的角色',
        describe: '今天就使用我们的工具释放您的创造力。潜入一个让您的想象力与现实相遇的世界。单击即可开始您的下一级别角色设计之旅'
      }
    },
    RealTimeWebcam: {
      title: '实时网络摄像头奇迹',
      subTitle: '通过网络摄像头看到您的世界发生变化',
      desc: 'Collov AI 的网络摄像头奇迹彻底改变了您的网络摄像头体验。该工具可立即将您的视频源转换为令人惊叹的艺术渲染，将技术与艺术融为一体。它非常适合爆头、流媒体或只是为了好玩，它为您的数字交互增添了创意。有了 Webcam Wonders，每一刻都是潜在的杰作。',
      try: '现在试试',
      generate: '现在试试',
      items1: {
        name: '好玩的视频',
        desc: '将每个直播或个人录音变成有趣的视觉奇观'
      },
      items2: {
        name: '富有表现力的创造力',
        desc: '为您的数字形象增添个性化和有趣的风格'
      },
      items3: {
        name: '参与互动',
        desc: '通过动态、视觉刺激的内容提升您的在线参与度'
      },
      content: {
        title: '网络摄像头转型的艺术',
        subTitle: '转变、定制和超越您的网络摄像头体验',
        info1: {
          name: '即时艺术转换',
          desc: '立即将您的网络摄像头转变成艺术。 Webcam Wonders 带来实时创造力，将日常视频通话变成令人惊叹的视觉体验。'
        },
        info2: {
          name: '多功能风格调色板',
          desc: '从多种风格中进行选择，以匹配您的心情和环境。从专业的头像到生动的场景诠释，Webcam Wonders 可满足您的所有创意需求。'
        },
        info3: {
          name: '创意增强工具包',
          desc: '使用功能强大、易于使用的工具为您的网络摄像头添加独特的风格。这些功能非常适合专业打磨或有趣的参与，让每次互动都令人难忘。'
        }
      },
      precision: {
        title: '精准和谐',
        subTitle: '角色设计的独特优势',
        item1: {
          name: '人工智能驱动的适应',
          desc: '根据视频源的场景、灯光和动作智能地改变效果。'
        },
        item2: {
          name: '智能提示',
          desc: '智能提示让您可以尝试无限的视觉可能性'
        }
      },
      creative: {
        title: '通过网络摄像头奇迹释放您的创意视野',
        subTitle: '立即尝试 Webcam Wonders，将您的日常网络摄像头使用变成非凡的创造力展示。您的精彩视频互动之旅从这里开始！'
      }
    },
    RealTimePhoto: {
      title: '实时照相馆',
      subTitle: '照片转换，专业风格，即时',
      desc: '使用 Museclip AI 的 Photo Studio 释放专业照片造型的力量。轻松将您的照片或实时拍摄变成令人惊叹的图像，从时尚的头像到有趣的动漫风格。潜入一个品质与便利并存的世界，用魔法提升您的照片。',
      try: '现在试试',
      generate: '现在试试',
      items1: {
        name: '即时专业',
        desc: '瞬间将任何照片升级为专业品质，非常适合个人资料和演示。'
      },
      items2: {
        name: '创意表达',
        desc: '探索从现实到动画的多种风格，以匹配您的个性和项目。'
      },
      items3: {
        name: '方便使用的',
        desc: '无需复杂的软件或技能即可获得高端照片效果，让所有人都能发挥创造力。'
      },
      content: {
        title: '即时工作室: 提升您的照片',
        subTitle: '捕捉、设计风格、给人留下深刻印象——毫不费力',
        info1: {
          name: '真实感增强',
          desc: '将普通照片转变为逼真的杰作，确保每张图像不仅符合甚至超越专业标准，就像在高端工作室环境中拍摄的一样。'
        },
        info2: {
          name: '简单的文本提示设置',
          desc: '通过输入文本提示来选择不同的背景、一天中的时间，甚至特定的服装，轻松自定义照片，从而轻松地将照片与所需的心情或背景相匹配。'
        },
        info3: {
          name: '多功能用例',
          desc: '满足各种用例，从创建完美的专业爆头到设计独特的游戏头像。无论是商业、社交媒体还是个人项目，创建完全适合他们需求的图像。'
        }
      },
      precision: {
        title: '精准和谐',
        // subTitle: 'Unique Edge in Character Design',
        item1: {
          name: '高级编辑套件',
          desc: '使用精确的编辑工具提升您的图像，实现详细的调整和增强。'
        },
        item2: {
          name: 'AI 照片转视频',
          desc: '将人工智能生成的照片转换为动态视频，为静态图像添加动感。'
        }
      },
      creative: {
        title: '使用 Collov 的 Photo Studio 改造您的照片',
        subTitle: `无论您是要完善您的专业形象还是打造完美的头像，Studio Magic 都能让您变得简单而令人惊叹。现在就开始创作，看看你的想象力将带你去往何方！`
      }
    }
  },
  scheduleMeeting: {
    title: '企业: 使用Collov AI构建',
    desc: '与我们的试点合作伙伴一起计划开启一段奇妙惊喜的旅程，踏入广阔的Collov爱好者的社区，推动您的品牌走向未来。',
    form: {
      name: '你的名字',
      mail: '你的邮箱地址',
      org: '组织名称',
      businessType: '请选择您的业务类型',
      message: '写下你的留言...',
      submit: '联系Collov企业微信',
      inputTips: '请输入 ',
      showDialog: '打开CollovAI企业会议' // Select a Date & Time
    }
  }
}
