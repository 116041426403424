export const allInOneForm = {
  tabs: {
    ItemReplace: 'Item Replace',
    HomeRedesign: 'Home Redesign',
  },
  navs: {
    Furnitures: 'Furnitures',
    Cabinets: 'Cabinets',
    Floors: 'Floors',
    Walls: 'Walls',
    FurnitureSet: 'Furniture Set',
    Countertops: 'Countertops',
  },
  contenNavs: {
    Product: 'Product',
    History: 'History',
    Favorites: 'Favorites',
  },
  btn: {
    Filter: 'Filter',
    Replace: 'Replace',
    Add: 'Add',
    Remove: 'Remove',
    Clearall: 'Clear all',
    WholeRoomRedesign: 'Whole Room Redesign',
    PartialRemodel: 'Partial Remodel',
    visitProductPage: 'Visit product page',
  },
  filter: {
    Price: 'Price',
    Vendor: 'Vendor',
    Color: 'Color',
    Filter: 'Filter',
    ColorBlack: 'Black',
    ColorBlue: 'Blue',
    ColorRed: 'Red',
    ColorYellow: 'Yellow',
    ColorGray: 'Gray',
    ColorGreen: 'Green',
    ColorOrange: 'Orange',
    ColorWhite: 'White',
    title: 'Filters',
    clear: 'Clear',
    done: 'Done',
  },
  replaceImage: 'Change Room',
  Rotate: 'Rotate',
  Regenerate: 'Regenerate',
  Before: 'Before',
  After: 'After',
  PoweredBy: 'Powered By',
  DesignComparison: 'Before / After',
}
