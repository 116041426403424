export const description = {
    redesign: '家居重新设计提供基于您的照片的人工智能驱动的房间重新设计。',
    fastPreserved: '即时家居重新设计提供基于您的照片的人工智能驱动的房间重新设计。',
    partial: '使用我们的人工智能辅助部分改造工具选择并翻新一个房间部分。',
    WALL_FLOOR_CEILING: '使用我们的人工智能辅助部分改造工具选择并翻新一个房间部分。',
    ALL_IN_ONE: '整合家具、橱柜、壁纸、地板，利用人工智能将这些元素无缝融入到实际环境中。',
    kitchenRemodeling: '上传您的厨房图像并选择橱柜风格；我们的人工智能立即创建个性化、未来感的设计。',
    realTimeDesign: 'AI 动力的室内设计工具：在左侧调整设置，在右侧通过并排比较看到即时变化。',
    eraser: '使用我们的AI魔法橡皮擦工具轻松擦除房间元素。',
    ChatDesigner: '通过AI驱动的对话式聊天生成艺术设计灵感。',
    ProductVideoStoryboard: '产品故事板导演，致力于将您的产品脚本创意转化为引人入胜的视觉关键帧！',
    GenVideo: '使用AI驱动的文本转视频生成创建和探索室内设计概念。',
    TETX_2_IMG: '利用文本转图像AI技术即时构想和完善房间设计。',
    MIDJOURNEY_V6:'利用文本转图像AI技术即时构想和完善房间设计。',
    FLOORPLAN_GPT: '这一独特功能允许基于提供的平面图可视化房间。',
    DRAFT_IMAGE: '草图图像：在房间被可视化后，用户可以灵活地对这个可视化空间进行任何修改。',
    editing: '使用我们的AI动力家具编辑套件定制您房间的家具。',
    GEN_BGIMAGE: 'AI 可在 30 秒内轻松打造迷人的产品背景，增强视觉吸引力并精确讲述故事。',
    INTELIGENT_CUSTOMER_SERVICER:'设计小白，秒变设计大师；客服小白，秒变百万销冠🏆',
    enterpriseBuild: '想要 Collov Business 的高级功能吗？',
};

