<template>
  <div class="component-login">
    <h5 class="title" v-t="'title.welcome'" v-if="false"></h5>
    <div class="emphasis-footnote" v-if="false">
      <span v-t="'form.first'"></span>
      <span class="btn" @click="store.loginType='register'"  v-t="'btn.signUp'"></span>
    </div>
    <oauth-login or v-if="false"/>
    <div class="custom-form-error" v-if="store.errMessage">
      {{ store.errMessage }}
    </div>
    <login-form v-if="loginType==='password'"/>
    <mobile-code v-else loginType="login"/>
    <div class="tip" style="min-height: 24px">{{loginType==='password'? $t('form.passwordTip'):' ' }}</div>
    <div style="display: flex;justify-content: flex-end;position: relative;top: -12px;" class="emphasis-footnote"  v-if="$i18n.locale==='zh' && false">
      <span class="cursor" @click="changeType()">{{loginType==='code'?'密码登录':'手机验证码登录'}}</span>
    </div>
    <div class="login-btn">
      <c-button type="primary" square @toClick="toLogin" color="white"  :loading="store.loading">
        <span  v-t="'btn.signIn'"></span>
      </c-button>
    </div>
  </div>
</template>
<script setup lang="ts">
import LoginForm from '../form/index.vue'
import MobileCode from '../form/mobileCode.vue'
import OauthLogin from '../oauthLogin.vue'
import {clientStore} from "@/stores/client";
import { useI18n } from 'vue-i18n'
import {ref} from "vue";
const loginType=ref('password')
const { locale}  = useI18n()
const store = clientStore()
//去登录
const toLogin=()=>{
  store.Login(locale.value,loginType.value)
}
const changeType=()=>{
  if(loginType.value==='code'){
    loginType.value='password'
  }else {
    loginType.value='code'
  }
}

</script>
<style scoped lang="less">
.component-login {
  width: 456px;
  .title{
    font-size: clamp(18px,2.42vw,24px);
    color: #44484c;
    line-height: 1.33;
    font-weight: 600;
    letter-spacing: .01em;
  }
  .custom-form-error {
    color: @color-error;
    font-size: 13px;
    margin-bottom: 15px;
    margin-top: -10px;
  }
  .emphasis-footnote{
    font-size: 14px;
    color: #71787f;
    line-height: 1.57;
    font-weight: 600;
    letter-spacing: .0175em;
    .btn{
      cursor: pointer;
      line-height: 1;
      color: #806b5a;
      display: inline-block;
      border-bottom: 1px solid #806b5a;
    }
  }
  .login-btn{
    .flex-end();
    margin-top: 12px;
  }
  .tip{
    font-size: 12px;
    line-height: 18px;
    color: #aaaeb2;
    font-family: ProximaNova;
  }
}
</style>
