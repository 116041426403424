export const designForm = {
  title: {
    uploadUrl: 'Upload your own room image',
    publish: 'Publish the Al rendering to the community',
    privacy: 'Publish the Al rendering to the community',
    style: 'Choose your preferred color and material',
    repair: 'Space conditions',
    selectRepair: 'Select the status of the space',
    mode: 'Mode',
    designType: 'Area of retouch',
    maskUrl: 'Area of retouch',
    area: 'Choose area for adding item',
    category: 'Select the furniture you want to edit',
    productImage: 'Color and material',
    assistant: 'Select the area you want to assist',
    prompt: 'Enter prompt',
    refine: 'Refine with Hi-Fi Redesign (Optional)',
    TETX_2_IMG: 'Text 2 img',
    roomType: 'Tell us about your space details',
    emptyRoom: 'Empty Room?',
    uploadReference: 'Upload reference',
    uploadReferenceMessage: 'Upload your style reference, and our AI will generate matching designs.',
    analysing: 'Analyzing Image',
    selectFurniture: 'Select the furniture',
    assist: 'Select the area you want to assist',
    selectArea: 'Select the area',
    ask: 'How would you like to ask?',
    enhancing: 'Enhancing rendering (10s)'
  },
  checkList: {
    lamp: 'lamp',
    painting: 'painting',
    sofa: 'sofa',
    blanket: 'blanket',
    tray: 'tray',
    cushion: 'cushion',
    coffee: 'coffee',
    rug: 'rug',
    stool: 'stool',
    table: 'table',
    curtain: 'curtain',
    door: 'door',
    windowpane: 'windowpane',
    chandelier: 'chandelier',
    chair: 'chair',
    ball: 'ball',
    food: 'food',
    vase: 'vase',
    glass: 'glass',
    Wall: 'Wall',
    Floor: 'Floor',
    Ceiling: 'Ceiling',
    television: 'television',
    armchair: 'armchair',
    bookcase: 'bookcase',
    flower: 'flower',
    book: 'book',
    ottoman: 'ottoman',
    pot: 'pot',
    plate: 'plate',
    plant: 'plant',
    cabinet: 'cabinet',
    light: 'light',
    washer: 'washer',
    bosket: 'bosket',
    mirror: 'mirror',
    bed: 'bed',
    pillow: 'pillow',
    chest: 'chest',
    box: 'box',
    fan: 'fan',
    bench: 'bench',
    stairs: 'stairs',
    bottle: 'bottle',
    basket: 'basket',
    refrigerator: 'refrigerator',
    sink: 'sink',
    kitchen: 'kitchen',
    shelf: 'shelf',
    oven: 'oven',
    plaything: 'plaything',
    stove: 'stove',
    hood: 'hood',
    person: 'person',
    counter: 'counter',
    wardrobe: 'wardrobe',
    apparel: 'apparel',
    bag: 'bag',
    clock: 'clock',
    fireplace: 'fireplace',
    countertop: 'Countertop'
  },
  value: {
    furnishing: 'Furniture',
    architectural: 'Architectural',
    promptHolder: 'Enter the style you like',
    uploadUrl: 'Uploaded',
    style: 'Choose interior design style',
    roomType: 'Choose a Room Type',
    emptyRoom: 'Empty Room?',
    area: 'Choose area for adding item',
    selected: 'Selected',
    productImage: 'Specify furniture style you like',
    colorMaterial: 'Specify the color and material you like',
    mode: 'Choose mode',
    PRESERVED: 'Structure-preserved',
    WELL: 'Well-designed',
    select: 'Select',
    Lasso: 'Lasso',
    Eraser: 'Eraser',
    Deselect: 'Deselect',
    Mask: 'Mask',
    WholeRoomRedesign: 'Whole Room Redesign',
    PartialRemodel: 'Partial Remodel',
    'Hide dots': 'Hide dots',
    'Show dots': 'Show dots',
    Furniture: 'Furniture',
    Brush: 'Brush',
    Pen: 'Pen',
    Add: 'Add',
    Shapes: 'Shapes',
    Upload: 'Upload',
    palette: 'Color Palette',
    Webcam: 'Webcam',
    AddFurniture: 'Add furniture',
    SimilarItems: 'Detection',
    backOriginal: 'Back to original image',
    saveGenerated: 'Save Generated Photo',
    RemoveObject: 'Remove Object',
    Program: 'External Program',
    Architectural: 'Architectural',
    Detection: 'Detection',
    YES: 'Yes',
    NO: 'No',
    Wall: 'Wall',
    Floor: 'Floor',
    Ceiling: 'Ceiling',
    PUBLIC: 'Public (Free)',
    maskUrl: 'Choose area for adding item',
    PRIVATE: 'Private (Pro)',
    Bed: 'Bed',
    Bench: 'Bench',
    Chair: 'Chair',
    home_decor: 'Home Décor',
    'Home Décor': 'Home Décor',
    Lighting: 'Lighting',
    Mirror: 'Mirror',
    'Ottoman Pouf': 'Ottoman Pouf',
    'Pillow Throw': 'Pillow Throw',
    Rack: 'Rack',
    Rug: 'Rug',
    'Sofa Sectional': 'Sofa Sectional',
    'Storage Cabinet': 'Storage Cabinet',
    Table: 'Table',
    Beds: 'Beds',
    Headboard: 'Headboard',
    Mattress: 'Mattress',
    'Metal Bench': 'Metal Bench',
    'Upholstered Bench': 'Upholstered Bench',
    'Wood Bench': 'Wood Bench',
    'Accent Chair': 'Accent Chair',
    Armchair: 'Armchair',
    'Bar Stool': 'Bar Stool',
    'Counter Stool': 'Counter Stool',
    'Dining Chair': 'Dining Chair',
    'Lounge Chair': 'Lounge Chair',
    'Office Chair': 'Office Chair',
    Bowls: 'Bowls',
    'Indoor Plants and Terrarium': 'Indoor Plants and Terrarium',
    Objects: 'Objects',
    Trays: 'Trays',
    Vases: 'Vases',
    'Wall Art': 'Wall Art',
    Chandeliers: 'Chandeliers',
    'Floor Lighting': 'Floor Lighting',
    'Flush Mount': 'Flush Mount',
    Pendants: 'Pendants',
    'Semi Flush Mount': 'Semi Flush Mount',
    'Table Lamp': 'Table Lamp',
    'Wall Sconces': 'Wall Sconces',
    'Decorative Mirror': 'Decorative Mirror',
    'Floor Mirror': 'Floor Mirror',
    'Wall Mirror': 'Wall Mirror',
    Ottoman: 'Ottoman',
    Pouf: 'Pouf',
    'Decorative Pillows and Cushion': 'Decorative Pillows and Cushion',
    'Throw blanket': 'Throw blanket',
    'Floor Racks': 'Floor Racks',
    'Wall Racks': 'Wall Racks',
    'Area Rug': 'Area Rug',
    'Door Mat': 'Door Mat',
    'Round Rug': 'Round Rug',
    Runner: 'Runner',
    '2 Seater': '2 Seater',
    '3 Seater': '3 Seater',
    '4 Seater': '4 Seater',
    'Left Sectional': 'Left Sectional',
    'Recliner Sofa': 'Recliner Sofa',
    'Right Sectional': 'Right Sectional',
    'U Sectional and Corner Sectional': 'U Sectional and Corner Sectional',
    Bookcase: 'Bookcase',
    'Buffet and Sideboard': 'Buffet and Sideboard',
    'Dresser and Wardrobe': 'Dresser and Wardrobe',
    'Media Console': 'Media Console',
    'Accent Table': 'Accent Table',
    'Bar Cart': 'Bar Cart',
    'Bar Table': 'Bar Table',
    'Coffee Table Cocktail Table': 'Coffee Table Cocktail Table',
    'Console Table': 'Console Table',
    'Counter Table': 'Counter Table',
    Desk: 'Desk',
    'Dining Table': 'Dining Table',
    'Game Table': 'Game Table',
    'Nesting Table': 'Nesting Table',
    Nightstand: 'Nightstand',
    'Side Table': 'Side Table',
    'Vanity Table': 'Vanity Table',
    prompt: 'Enter prompt',
    GenerateImage: 'Generate Image',
    False: 'Normal',
    EMPTY_ROOM: 'Rough House',
    True: 'Messy Room',
    FURNISHED_ROOM: 'Normal'
  },
  select: 'select',
  customerStyle: {
    title: 'Upload reference',
    desc: 'Upload your style reference, and our AI will generate matching designs.'
  },
  RoomType: {
    tabs: {
      kitchen: 'Kitchen',
      bathroom: 'Bathroom',
      bedroom: 'Bedroom',
      'living room': 'Living Room',
      'dining room': 'Dining Room',
      patio: 'Patio',
      kids: 'Kid’s Room'
    }
  }
}
