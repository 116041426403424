<template>
  <teleport to="body">
    <Transition name="modal">
      <div class="modal-mask" v-if="store.loginVisible && !isPreview">
        <div class="modal-container">
          <div class="close-wrap" @click="closeModal" v-if="false">
            <img src="@/assets/icons/close.svg" alt="" />
          </div>
          <div class="form-background" v-if="store.loginType !== 'created'">
            <img src="@/assets/images/form-background.png" alt="background" />
          </div>
          <Login v-if="store.loginType === 'login'" />
          <created v-else-if="store.loginType === 'created'" />
          <register v-else-if="store.loginType === 'register'" />
          <login-wx v-else-if="store.loginType === 'LOGIN_WX'" />
        </div>
      </div>
    </Transition>
  </teleport>
</template>
<script setup lang="ts">
import Login from './login/index.vue'
import LoginWx from './login/login_wx.vue'
import Register from './register/index.vue'
import Created from './created/index.vue'
import { clientStore } from '@/stores/client'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
const store = clientStore()

const route = useRoute()

const isPreview = computed(() => route.query.isPreview === '1')
// const isPreview = route.query.isPreview === '1'

const closeModal = () => {
  store.loginVisible = false
  store.loginType = ''
}
</script>
<style scoped lang="less">
.modal-container {
  margin: auto;
  background-color: @color-hover;
  padding: 32px;
  border-radius: 8px;
  position: relative;
  max-width: 100%;
  @media screen and (max-width: @tablet-landscape) {
    width: 100%;
  }
  .flex-center();

  .close-wrap {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }

  .form-background {
    max-width: 400px;
    margin-right: 24px;

    img {
      width: 100%;
    }
  }

  @media screen and (max-width: @tablet-landscape) {
    .form-background {
      display: none;
      margin: 0;
    }
    .component-login {
      width: 100%;
    }
  }
}
</style>
