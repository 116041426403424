import {useEventBus, useIntersectionObserver} from "@vueuse/core";
import loadingImg from '../assets/images/loading.gif'
const visibleDoms=[]
export const lazyPlugin = {
    install(app) {
// 自定义指令：
        app.directive('lazy', {
            mounted(el, binding) {
                // el 指令绑定得那个元素 img
                //bindding: binding.value 指令等于号后面绑定得表达式得值 这里指图片url地址

                const {stop} = useIntersectionObserver(
                    el, ([{isIntersecting}], observerElement) => {
                        if (isIntersecting) {
                            //图片进入视觉入口了
                            el.src = loadingImg
                            const image = new Image()
                            image.src = binding.value
                            image.onload = function () {
                                el.src = image.src
                            }
                            stop();
                        }
                    },
                )
            },
        })
        app.directive('generate', {
            mounted(el, binding) {
                const {stop} = useIntersectionObserver(
                    el, ([{isIntersecting}]) => {
                        if (isIntersecting) {
                            const eleGenerate = useEventBus<string>('generate')
                            visibleDoms.push(binding.value)
                            eleGenerate.emit('generate',visibleDoms)
                        }
                    },
                )
            },

        })
        app.directive('img-lazy', {
            mounted(el, binding) {
                // el 指令绑定得那个元素 img
                //bindding: binding.value 指令等于号后面绑定得表达式得值 这里指图片url地址
                el.src = loadingImg
                const image = new Image()
                image.src = binding.value
                image.onload = function () {
                    el.src = image.src
                }
            }
        })
        app.directive('tip', {
            mounted(el, binding) {
                // el 指令绑定得那个元素 img
                //bindding: binding.value 指令等于号后面绑定得表达式得值 这里指图片url地址
                // el.innerHTML=binding.value||'This function is only available to pro users'
                el.innerHTML = binding.value || 'Your credits is insufficient, please Upgrade'
                el.setAttribute('class', 'upgrade-tip')
            }
        })
        app.directive('outside', {
            mounted(el,binding) {
                const handleClickOutside = (event) => {
                    if (!el.contains(event.target)) {
                        binding.value()
                    }
                };
                // 添加点击事件监听器到document
                document.addEventListener('click', handleClickOutside);

                // 在unbind钩子中移除事件监听器，防止内存泄漏
                el.__handleClickOutside__ = handleClickOutside;
            },
            unbind(el) {
                // 移除事件监听器
                document.removeEventListener('click', el.__handleClickOutside__);
                delete el.__handleClickOutside__;
            }
        })
    }
}
