export const designAi = {
    Exclusive: 'Exclusive',
    // KBISONLYPILOT: 'KBIS ONLY PILOT',
    KBISONLYPILOT: '$250',
    KBISONLYPILOTUNIT: '/ mo (Billed Annually)',
    forThe1stMonth: 'for the 1st month',
    dateTime: 'Until Mar 01,2024',
    AllInOneToolkit: 'All-In-One-Toolkit',
    experienceAI: 'Experience the only platform for AI-driven, <span style="color: #ff7e33;">vendor-sourced cabinet </span> rendering.',
    fullCapabilities: 'Access to the <span style="color: #ff7e33;">full capabilities</span> of Collov AI.',
    oneStyle: 'Dedicated training on <span style="color: #ff7e33;">one style</span> from your SKUs for personalized detail.',
    JoinNow: 'Join Now',
    TryDemo: 'Try Demo',
    CabinetDesignTitle: 'Revolutionizing Cabinet Design with AI',
    CabinetDesignName: 'The World‘s First and Only AI Design Platform for Real, Vendor-Sourced Cabinet Renderings',
    DesignAndSales: 'Bridging <span style="color: #ff7e33">Design</span> and <span style="color: #ff7e33">Sales</span>',
    DesignAndSalesDesc: 'Combines AI design with real product sales, streamlining the design-to-sale process. It accelerates purchasing decisions with realistic, purchasable cabinet designs. This dual functionality boosts sales productivity and shortens the sales cycle, transforming designs into revenue.',
    SalesEfficiencyRate: '60% Increase',
    SalesEfficiency: 'in sales efficiency<br/> and revenue',
    DesignCostRate: '50% Reduce',
    DesignCost: 'In-house design cost',
    CustomerEngagementRate: '40% Increase',
    CustomerEngagement: 'in customer engagement',
    collovApp: 'See Collov AI in action',
    coolovHandle: 'Snap a photo of your room, upload to Collov, and watch AI accurately recreate your cabinet details. Simplicity in action.',
    digitalTitle: 'Create unique design and sales experiences as exceptional as your products',
    digitalSubTitle: 'All tools you need in one platform for showcasing and selling your products.',
    digitalSku: 'SKU to Digital Assets',
    digitalName: `Collov's proprietary AI begins by learning from your SKU data, transforming cabinets into
    precise digital assets. This process ensures each product's unique details are accurately replicated, laying
    the groundwork for realistic design visualizations.`,
    lifelikeName: 'Real Cabinet in AI Design Lifelike Results',
    lifelikeInfo: `Collov's AI accurately renders cabinet designs, capturing every detail, style, and material in digital form. This step ensures realistic and precise visualizations in AI-powered designs, facilitating informed design choices.`,
    seamlessName: 'Seamless Integration for Design and Sales',
    seamlessInfo: 'Effortlessly integrates into both your online and offline workflows, whether through a few lines of code on your website or by incorporating the Collov AI Desk into your showroom. Our solutions enhance customer engagement across all platforms, ensuring an interactive experience.',
    scalableName: 'Scalable AI Design at Golden Home',
    scalableInfo: `By deploying Collov AI into its stores globally as a pilot, Golden Home is seeing significant
    improvement in design and sales performance.`,
    ReadFullStory: 'Read Full Story',
    manufacturerTitle: 'Manufacturer Partner Pilot',
    manufacturerSubtitle: 'Joining the MPP transforms your products into digital assets, readily accessible to your retail network and their customers. When a homeowner visualizes your products in their room, they are 5x more likely to purchase — which means more sales for you and your retail partners.',
    manufacturerToolPopularity: 'Increased brand awareness', 
    manufacturerToolRetailer: 'Stronger retailer relationships', 
    manufacturerToolChannel: 'Omnichannel merchandising', 
    LearnMore: 'Learn More',
    SubmitBusinessInterest: 'Submit Business Interest',
    planTitle: 'Pick the best plan for you',
    WhatOurClientsSay: 'What Our Clients Say',
    clientsSay: [
        {name:'Jane Doe',arrdress:'CEO, Modern Interiors',content:'Integrating Collov AI into our online platform has transformed how we showcase our cabinets. The precision and realism in the renderings have significantly improved our customer\'s shopping experience, leading to higher satisfaction and increased sales.'},
        {name:'John Smith',arrdress:'Director, Elegant Spaces',content:'Collov AI\'s technology has been a game-changer for our showroom. The ability to visualize products accurately in various settings has not only enhanced our design process but also boosted customer confidence in their purchasing decisions.'},
        {name:'Emily White',arrdress:'Sales Manager, The Cabinet Corner',content:'The seamless integration of Collov AI into our sales workflow has revolutionized the way we engage with customers. It\'s not just a design tool; it\'s a comprehensive solution that supports both our online and offline sales strategies.'},
        {name:'Michael Brown',arrdress:'Founder, DesignFirst Kitchens',content:'Our experience with Collov AI has been outstanding. The detail and accuracy of the AI-rendered cabinets have set a new standard in the industry, allowing us to offer a unique and immersive shopping experience to our clients.'},
        {name:'Lisa Green',arrdress:'Product Manager, FutureHome Cabinetry',content:'Collov AI has elevated our cabinet offerings to the next level. The technology\'s ability to learn our SKU and reproduce every detail with such precision has made it an indispensable part of our design and sales process.'},
    ],
    allinone: {
      unselectDot: 'Please select a white dot to replace.',
    },
};
