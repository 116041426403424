<template>
<div class="oauth-login">
  <template  v-if="$i18n.locale==='zh'">
    <c-button block @click="loginTowx()">
      <div class="ico">
        <img src="@/assets/images/wx.svg"/>
      </div>
      <div>微信登录</div>
    </c-button>
  </template>
  <template  v-else>
    <c-button block @click="oauthLogin()" v-if="hostName!=='instant.collov.ai'">
      <div class="ico">
        <img src="@/assets/images/google.png"/>
      </div>
      <div>CONTINUE WITH GOOGLE</div>
    </c-button>
  </template>
  <div class="c-line" v-if="or"><span>OR</span></div>
</div>
</template>
<script setup lang="ts">
import {useRoute,useRouter} from "vue-router";
import {clientStore} from "@/stores/client";
import {defineProps} from "vue";
const hostName =location.hostname
const store = clientStore()
const router=useRouter()
defineProps<{
  or?:boolean
}>()
const oauthLogin=()=>{
  store.oauthLogin()
}
const loginTowx=()=>{
  const userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf('micromessenger') !== -1) {
    store.loginVisible=false
    store.loginType=''
    router.push('/wx/login?sessionId='+store.token)
  } else {
    store.loginType='LOGIN_WX'
  }
}
</script>
<style scoped lang="less">
.oauth-login{
  margin-top: 16px;
  .ico{
    width: 20px;
    height: 20px;
    margin-right: 24px;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .c-line{
    border-bottom: 1px solid #ccc4bd;
    height: 15px;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 25px;
    span{
      display: inline-block;
      text-align: center;
      line-height: 28px;
      width: 25px;
      background: #f0ede9;
      font-size: 12px;
      color: #44484c;
    }
  }
}
</style>
