export default {
  Banner: {
    title: '世界上第一个真正的家具替换',
    subtitle: '即时改造，无限可能，借助 AI',
    info: '体验瞬间家具改造的魔力，无缝替换旧家具，只需几秒钟即可选择时尚的选项来自真正的供应商。',
    btn1: '立即加入',
    btn2: '尝试演示'
  },
  Bridging: {
    label: ['连接', '设计', '和', '电子商务'],
    info: '轻松选择完美的室内家具，伴随着直接的电子商务链接，将您的设计愿景变为现实，同时轻松集成购物便利。解锁一个内部设计与即时购买相结合的世界。'
  },
  Tools: [
    {
      title: '增加 60%',
      info: '销售效率和收入'
    },
    {
      title: '减少 50%',
      info: '内部设计成本'
    },
    {
      title: '增加 5 倍',
      info: '客户参与度'
    }
  ],
  h2: {
    title: '创造独特的设计和销售体验，与您的产品一样出色',
    info: '革命您的空间：AI 增强的家具转型'
  },
  TextImage: [
    {
      title: '智能 AI 家具检测',
      info: '轻松开始您的设计之旅：只需上传照片或渲染图。我们的 AI 智能地识别并精确选择要更换的家具，为完美的转型奠定基础。'
    },
    {
      title: '将 SKU 转化为 AI 设计的空间',
      info: '深入各种家具类别，从值得信赖的供应商中进行选择。我们将每个 SKU 变成可购买的数字资产，准备好让 AI 为您打造理想的空间，拥有无与伦比的多样性。'
    },
    {
      title: '受控家具定制',
      info: '通过高级结构控制掌握细节，专注于特定的家具件进行更换。轻松比较前后视图，享受无缝过渡到您新设想的空间。'
    },
    {
      title: '不仅仅是简单的家具更换',
      info: '我们智能的 AI 不仅更换家具；它还为完美的设计匹配和设置适合重新想象您的空间，轻松实现您的室内设计愿景。'
    },
    {
      title: '不仅仅是家具',
      info: '与 Collov 的一体化 AI 设计平台无缝集成，扩展到橱柜、地板和墙壁，以及智能设计匹配，轻松补充您的家具选择。'
    }
  ],
  Furniture: [
    {
      tag: '四只手',
      title: 'DOM 沙发',
      price: '价格：$2,190',
      size: '尺寸：85.00"w x 39.50"d'
    },
    {
      tag: '文章。',
      title: '奥蒂奥米斯特灰色',
      price: '价格：$699',
      size: '尺寸：33"H x 26"W x 36"D'
    }
  ],
  share: '访问产品页面',
  More: {
    title: '不仅仅是墙壁',
    info: '与 Collov 的一体化 AI 设计平台无缝集成，提供橱柜、地板和家具的扩展，以及智能设计匹配，轻松补充您的墙壁选择。',
    icons: ['一体化平台', '智能设计匹配', '多表面支持']
  },
  PickPlan: {
    title: '为您挑选最佳方案',
    item1: {
      title: '家具更换',
      info: '10 分钟设置，无代码模板。',
      mo: ' / 月',
      currency: '¥',
      price: '600',
      ps: '(按年计费)',
      btn: '开始'
    },
    item2: {
      title: 'AI 桌面',
      info: '通过 Collov AI 软件计划解锁全部功能。',
      mo: '',
      currency: '¥',
      price: '6,000',
      ps: '',
      btn: '开始'
    }
  }
}
