export default {
  Introduce: {
    Exclusive: 'Exclusive',
    title: 'Explore, Design, Transform',
    subtitle: 'Instantly visualize your wall design with AI',
    info: `Instantly visualize a wide array of tiles, wallpapers, and paintings in your room, making it easier than ever to explore, design, and transform your home into a reflection of your unique style.`,
    btn1: 'Join Now',
    btn2: 'Try Demo'
  },
  Sales: {
    l: ['Bridging', 'Design', 'and', 'Sales'],
    r: `Bridge the gap between design and sales and offer customers the ability to explore a rich variety of tiles, wallpapers, and paintings. This comprehensive access empowers customers to experiment
    with diverse options, ensuring informed decisions that align with their vision.`
  },
  h2: 'Create unique design and sales experiences as exceptional as your products',
  p: 'All tools you need in one platform for showcasing and selling your products.',
  Transformation: {
    title: 'Digital Showcase Transformation',
    info: `Transform your SKUs into dynamic digital assets with Collov AI, offering a rich variety of tiles, wallpapers, and paintings, making every vendor's collection instantly accessible and
    interactive.`,

    cards: {
      key: {
        size: 'Size: ',
        color: 'Color: ',
        type: 'Product type: '
      },
      list: [
        {
          name: 'Rubino',
          size: '30 * 60cm',
          color: 'Terracotta or red',
          type: 'wall'
        },
        {
          name: 'Indaco',
          size: '30 * 60cm',
          color: 'Dusty blue',
          type: 'wall'
        },
        {
          name: 'Brume',
          size: '30 * 60cm',
          color: 'Light taupe or beige',
          type: 'wall'
        }
      ]
    }
  },
  Precision: {
    title: 'AI-Powered Precision Fit',
    info: `Utilize advanced AI to detect and precisely scale tiles and wallpapers, ensuring customers receive an accurate representation of how products will enhance their space.`
  },
  Visuals: {
    title: 'Instant Ultra-Realistic Visuals',
    info: `Achieve ultra-realistic title, wallpaper and painting renderings in seconds, where cutting-edge technology meets design to bring your vision to life instantly and beautifully.`
  },
  Compare: {
    Before: 'Before',
    After: 'After',
    title: 'Compare and Contrast',
    info: `Effortlessly view before-and-after scenes or compare various design options side-by-side, empowering informed decisions through clear, visual transformation.`
  },
  More: {
    title: 'More Than Just Walls',
    info: `Seamlessly integrates with Collov’s All-in-One AI design platform, offering expansion into cabinets, flooring, and furniture, alongside intelligent design matching to effortlessly complement
    your wall selections.`,
    icons: ['All-in-One Platform', 'Intelligent Design Matching', 'Multi-surface Supported']
  },
  PickPlan: {
    title: 'Pick the best plan for you',
    item1: {
      title: 'Wall Design',
      info: '10-minute setup with a code-free template.',
      mo: ' / mo ',
      currency: '$',
      price: '250',
      ps: '(Billed Annually)',
      btn: 'Get Started'
    },
    item2: {
      title: 'AI Desk',
      info: 'Unlock Full Power with Collov AI Software Plans Above.',
      mo: '',
      currency: '$',
      price: '2,500',
      ps: '',
      btn: 'Get Started'
    }
  }
}
