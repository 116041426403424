import request from '@/utils/request'
const formatParam = function (params:any) {
  return {
    params: params
  }
};
export const reqSaveTeam = (parmas) => (request as any).post('/team/manage/save', parmas)
export const reqUpdateClient = (parmas) => (request as any).post('/team/manage/updateClient', parmas)
export const reqInviteMember = (parmas) => (request as any).post('/team/manage/inviteMember', parmas)
export const reqGetTeam = (parmas) => (request as any).get('/team/manage/getTeam', formatParam(parmas))
export const reqDeleteTeam = (parmas) => (request as any).post('/team/manage/deleteTeam', parmas)
export const reqListPriceScheme = () => (request as any).get('/team/payment/manage/listPriceScheme')
export const reqDeleteClient = (parmas) => (request as any).post('/team/manage/deleteTeamClients',parmas)
export const reqDeleteMember = (parmas) => (request as any).post('/team/manage/deleteMember',parmas)
export const reqsave = (parmas) => (request as any).post('/enterpriseGoods/save',parmas)
export const listTreeVisualCategory = (parmas) => (request as any).get('/enterpriseGoods/listTreeVisualCategory',parmas)
export const reqTeamGoods = (parmas) => (request as any).get('/team/manage/teamGoods', formatParam(parmas))
export const reqListTeamGoods = (parmas) => (request as any).get('/enterpriseGoods/manage/listTeamGoods', formatParam(parmas))
export const reqdelete = (parmas) => (request as any).post('team/manage/deleteTeamGoods', parmas)
export const reqDeleteDesignModel = (parmas) => (request as any).post('team/manage/deleteDesignModel', parmas)
export const reqgetDetail = (parmas) => (request as any).get('/enterpriseGoods/getDetail', formatParam(parmas))
export const reqDeleteDarft = (parmas) => (request as any).postByJson('/enterpriseGoods/deleteDarft',parmas)
export const reqaddGoods = (parmas) => (request as any).postByJson('/enterpriseGoods/addGoods',parmas)
export const reqTeamDesignModels = () => (request as any).get('/designModel/manage/teamDesignModels')
export const reqsearch = (parmas) => (request as any).get('/enterpriseGoods/search', formatParam(parmas))
export const addRemarks = (parmas) => (request as any).post('/enterprise/crm/manage/addRemarks', parmas)
export const getRemarks = (parmas) => (request as any).get('/enterprise/crm/manage/getRemarks', formatParam(parmas))
export const searchRenderingUser = (parmas) => (request as any).get('/team/manage/list', formatParam(parmas))
export const reqGetDetail = (parmas) => (request as any).get('/enterprise/crm/manage/getDetail', formatParam(parmas))
export const saveClient = (parmas) => (request as any).post('/enterprise/crm/manage/saveClient', parmas)
export const reqAssign = (parmas) => (request as any).post('/team/manage/assign', parmas)
export const reqGetMemberlist = (parmas) => (request as any).get('/team/manage/memberlist', formatParam(parmas))
export const reqTeamClients = (parmas) => (request as any).get('/team/manage/teamClients', formatParam(parmas))
export const reqGetCurrentUserTeam = () => (request as any).get('/team/manage/getCurrentUserTeam')

