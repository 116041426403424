<template>
  <login-input :name="$t('form.email')" type="text" v-model:value="form.email">
    <template v-if="$i18n.locale==='zh'">
      <svg viewBox="64 64 896 896" data-icon="mobile" width="1em" height="1em" fill="currentColor" aria-hidden="true"
           focusable="false" class="">
        <path
            d="M744 62H280c-35.3 0-64 28.7-64 64v768c0 35.3 28.7 64 64 64h464c35.3 0 64-28.7 64-64V126c0-35.3-28.7-64-64-64zm-8 824H288V134h448v752zM472 784a40 40 0 1 0 80 0 40 40 0 1 0-80 0z"></path>
      </svg>
    </template>
    <template v-else>
      <img src="@/assets/icons/email.svg" alt="" style="transform: scale(0.8)">
    </template>
  </login-input>
  <login-input :name="$t('form.password')" :type="type" v-model:value="form.password" style="margin-top: 24px;">
    <div style="opacity: 0.6;transform: scale(0.9)" class="cursor" @click="changeType">
      <img src="@/assets/icons/eye.svg" alt="" class="cursor" v-if="type==='password'">
      <img src="@/assets/icons/eye-close.svg" class="cursor" alt="" v-else>
    </div>
  </login-input>
</template>
<script setup lang="ts">
import {clientStore} from "@/stores/client";
import LoginInput from '../loginInput.vue'
import {ref} from "vue";

const {form} = clientStore()
const type = ref<'text' | 'password'>('password')
const changeType = () => {
  const val = type.value === 'password' ? 'text' : 'password'
  type.value = val
}
</script>
<style scoped lang="less">

</style>
