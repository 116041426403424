export const pricing={
    title: `企业解决方案`,
    subTitle: ['所有价格均以元为单位。您可以随时更改计划或取消。', '每个订阅计划都有一个每月的信用额度，无论使用情况如何，该额度都会在每个计费周期开始时自动重置。这些不会延续到下个月。'],
    ContactSales: '联系销售',
    Subscribe: '购买',
    Free:'免费',
    usage: {
        title: `有多种AI功能可供选择，<br/>每种功能具有不同的能力和价格点。`,
        rows: [
            {title: '功能', weight: '价位'},
            {title: 'AI设计', weight: '1 积分'},
            {title: '部分修改', weight: '2 积分'},
            {title: '您的设计模型', weight: '1 积分'},
            {title: '聊天设计师', weight: '1 积分 /1千字符'},
            {title: '图片放大', weight: '0.5 积分'},
        ]
    },
    tabs: {
      PackageComparison: '套餐比较',
      CreditsUsage: '积分使用',
    },
    packageComparison: {
      rows:[
        {first:'', free: '免费版', pro: '专业版', team: '团队版', enterprise: '企业版'},
        {first:'用户', free: '1', pro: '1', team: '无限制*', enterprise: '无限制'},
        {first:'积分', free: '5', pro: '无限制*', team: '无限制', enterprise: '无限制'},
        {first:'SKU管理', free: 'Collov 默认提供', pro: 'Collov 默认提供', team: '上传您自己的 SKU', enterprise: '上传您自己的 SKU'},
        {first:'团队管理', free: '-', pro: '-', team: 'check', enterprise: 'check'},
        {first:'项目管理', free: '-', pro: '-', team: 'check', enterprise: 'check'},
        {first:'网站整合', free: '托管于 ', pro: '托管于 ', team: '托管于 ', enterprise: '集成到您的网站'},
        {first:'CRM 系统', free: '-', pro: '-', team: 'check', enterprise: 'check'},
        {first:'即时家居重新设计风格', free: '8 风格', pro: '100+ 风格', team: '100+ 风格', enterprise: '100+ 风格'},
        {first:'实时设计', free: '1', pro: '无限制', team: '无限制', enterprise: '无限制'},
        {first:'个性化入职培训', free: '-', pro: '-', team: '2 节课', enterprise: 'check'},
        {first:'客户支持', free: '电子邮件支持', pro: '电子邮件支持', team: '24/7 电子邮件和聊天支持', enterprise: '24/7 专职成功经理和技术支持'},
        {first:'白色标签', free: '-', pro: '-', team: '-', enterprise: 'check'},
        {first:'报告与分析', free: '-', pro: '-', team: 'check', enterprise: 'check'},
        {first:'高级风格/房型培训服务', free: '-', pro: '-', team: '无限制*', enterprise: '无限制*'},
      ]
    },
    plans: [
        {
            title: 'collov.ai 免费版',
            title2: "免费",
            headerCta: "免费试用",
            moduleText: "免费探索我们的AI室内设计功能。",
            price: '免费',
            per: '',
            perks: [
                '可供您使用的功能有限。',
                '5个collov.ai积分'
            ],
            tip: '非常适合任何想要设计自己家的人'
        },
        {
            title: 'collov.ai 专业版',
            title2: "AI_DESIGN",
            headerCta: "购买",
            moduleText: "升级至专业版，获取我们的AI室内设计工具以及高级功能。根据需要调整积分计划，实现您的设计愿景。",
            price: '19.99',
            salePrice: '19.99',
            per: 'per month',
            perks: [
                {
                    title: '所有功能都可供您使用。',
                    children: ['升级至高分辨率', '生成速度快']
                },
                '无水印',
                '保持上传和生成的图像私密'
            ],
            tip: '非常适合家居行业的室内设计师、房地产经纪人、家具供应商和相关专业人士。'
        },
        {
            title: 'collov.ai 企业版',
            title2: "enterprise",
            headerCta: "联系销售",
            moduleText: "想要collov.ai企业版以获取高级功能吗？",
            perks: [
                'API调用',
                '定制AI学习和培训（设计风格和家具）',
                '一键生成众多设计图像',
                '技术支持',
                '一对一客户成功经理'
            ],
            billedmonthly:'$59 按月计费',
            price: '$49',
            mo:'每个用户 / 月',
            tip: '非常适合那些希望在其网站或后端展示人工智能功能的公司。'
        }
    ],

    upgradePlans: [
        {
            title: 'collov.ai 专业版',
            title2: "AI_DESIGN",
            headerCta: "Subscribe",
            moduleText: "Upgrade to Pro for access to our AI interior design tool and advanced features. Adjust your credits plan as needed to bring your vision to life.",
            price: '19.99',
            salePrice: '19.99',
            per: 'per month',
            perks: [
                {
                    title: 'All features available for your use.',
                    children: ['Up-scale to high resolution', 'Fast generation speed']
                },
                'No watermark',
                'Keep uploaded and generated images private'
            ],
            tip: 'Ideal for interior designers, realtors, furniture vendors, and related professionals in the field of home industry.'
        }
    ],
    upgrade: {
        tip: '有几种AI功能可供选择，每种功能都具有不同的能力和价格点。',
        ContactSales: '联系销售',
        Downgrade: '降级',
        Subscribe: '订阅',
        permonth: '/ 月',
        credits: '积分',
    },

}
