export const payment = {
  title: 'Choose Your Design Plan',
  name: 'Let’s find a plan that’s right for you.',
  PackageDetail: 'Package Detail',
  FURNITURE_REPLACEMENT: {
    title: 'Replacing & Adding Furniture',
    list: [
      'Accurate Furniture Models: Collov AI generates lifelike digital replicas from vendor data.',
      'Streamlined Design-to-Sale: Transforms SKU data into visual assets, enhancing efficiency.',
      'Easy Integration: Facilitates the inclusion of AI-designed furniture in projects effortlessly.'
    ]
  },
  WALL_FLOORING_REPLACEMENT:{
    title:'Flooring and Walls',
        list:[
      'Accurate Textures: Precisely replicates flooring and wall materials.',
      'Versatile Design: Simplifies experimenting with diverse options.',
      'Easy Customization: Streamlines selection and visualization processes.',
    ]
  },
  AI_DESK:{
    title:'Smart AI design device in your showroom',
        list:[
      'Complete Access: Enables full utilization of Collov AI\'s features.',
      'Showroom Innovation: Integrates AI into the showroom for real-time visualization.',
      'Interactive Experience: Offers engaging, AI-driven design consultations.',
    ]
  },
  SubmitConsultationAppointment: 'Submit Business Interest',
  ContinueToCheckout: 'Continue to checkout',
  imglist: [
    { title: 'Traditional American'},
    { title: 'Black & Walnut'},
    { title: 'Black'},
    { title: 'Black & White'},
    { title: 'Straight Bench'},
    { title: 'Hex Side Table'},
    { title: 'Abstract minimal'},
    { title: 'West elm x pbt'},
    { title: 'Wood 1'},
    { title: 'Wood 2'},
    { title: 'Wood 3'},
    { title: 'Wood 4'},
    { title: 'Rubino'},
    { title: 'Indaco'},
    { title: 'Brume'},
    { title: 'Camel Gray'},
    { title: 'Ash Gray'},
    { title: 'Camel Gray'},
    { title: 'Star Silver'},
  ],
};
