<template>
  <div class="input-wrap floating-label-wrapper"  :class="{'input-wrapper-number':name==='电话号码'&&$i18n.locale==='zh'}">
    <input :type="type" placeholder="floating-label placeholder" class="login-input" @blur="handleblur"  @focus="handleFocus"
           @input="handleInput"
           :value="value">
    <div class="number" v-if="name==='电话号码'&&$i18n.locale==='zh'"> +86</div>
    <div class="floating-label caption-2">{{ name }}</div>

   <div class="right-icon">
     <slot name="right"></slot>
   </div>
   <div class="icon">
     <slot></slot>
   </div>
  </div>
</template>
<script setup lang="ts">
import {defineProps, defineEmits} from "vue";
type Props={
  name:string
  value:string
  type:string
}
const props = defineProps<Props>()
const emits=defineEmits(['update:value'])
const handleInput=(event: Event) =>{
  const inputValue = (event.target as HTMLInputElement).value;
  emits('update:value', inputValue||'');
}
const handleFocus = (event)=>{
    let e = event.currentTarget;
    setTimeout(() => {
        e.scrollIntoView({
            block: 'start',
            behavior: 'smooth'
        });
    }, 300);
    resetSize()
}
const resetSize = () => {
  // let win = window.innerHeight
  // if (window.innerWidth < 480) {
  //   // 获取HTML元素
  //   var app = document.getElementById('app');
  //   // 设置HTML和BODY的滚动条样式为隐藏
  //   app.style.overflow = 'hidden';
  // }
}
const handleblur  = (event) => {
    let e = event.currentTarget;
    setTimeout(() => {
        e.scrollIntoView({
            block: 'end',
            behavior: 'smooth'
        });
    }, 300);
     window.scrollTo(0, 0);
}
</script>
<style scoped lang="less">
.floating-label-wrapper {
  position: relative;
  input{
    outline: none;
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    height: 40px;
    padding: 4px 11px;
    color: @color-font;
    font-size: 14px;
    line-height: 1.5;
    background-image: none;
    border: 1px solid @color-border;
    border-radius: 4px;
    transition: all .3s;
    background-color: white;
  }
  &.input-wrapper-number input{
    padding-left: 48px !important;
  }
  .icon{
    position: absolute;
    right: 8px;
    top: 8px;
    width: 24px;
    height: 24px;
    .flex-center();
  }
  .right-icon{
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
  input.login-input::placeholder { color: transparent; }
  input:focus{
    background: transparent;
  }
  input.login-input:focus ~ .floating-label,
  input.login-input:not(:placeholder-shown) ~ .floating-label{
    transform: translateY(-50%);
    left: 12px;
    top: 0;
    font-size: 11px;
    opacity: 1;
    background-color: @color-hover;
    color: @color-font-primary;
  }
  .floating-label {
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 50%;
    line-height: 1;
    transition: 0.2s ease all;
    padding: 0 4px;
    transform: translateY(-50%);
    color: rgba(0,0,0,.65)
  }
  &.input-wrapper-number .floating-label{
    left: 48px !important;
  }

  .number {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }
}
</style>
