export const client = {
    title: '公开资料',
    tip: 'Collov 上的用户将能够查看以下信息。',
    Profile: '资料信息',
    facebook: 'Facebook',
    instagram: 'Instagram',
    pinterest: 'Pinterest',
    youtube: 'Youtube',
    blog: 'Blog',
    views: '浏览量',
    aiDesign: 'AI 设计',
    designModel: '设计模型',
    navs: [
        { name: 'AI 设计', value: '0', key: 'AI', fixed: 'aiGenerateRecordCount' },
        { name: '设计模型', value: '0', key: 'MODEL', fixed: 'designModelCount' },
        { name: '我的收藏', value: '0', key: 'FAVORITE', fixed: 'collectCount' },
    ],
    buttons: {
        save: '保存',
    },
};
