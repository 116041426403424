export const designForm = {
  title: {
    uploadUrl: '上传您自己的房间图像',
    publish: '发布AI渲染至社区',
    privacy: '发布AI渲染至社区',
    style: '选择您喜欢的颜色和材质',
    repair: '空间条件',
    selectRepair: '选择空间的状态',
    mode: '模式',
    designType: '设计区域',
    maskUrl: '设计区域',
    area: '选择要添加项目的区域',
    category: '选择要编辑的家具',
    productImage: '颜色和材质',
    assistant: '选择要辅助的区域',
    prompt: '输入提示',
    refine: '通过家居重新设计进行优化（可选）',
    TETX_2_IMG: '文本转图像',
    roomType: '告诉我们有关您空间的详细信息',
    emptyRoom: '是否为空房间？',
    uploadReference: '上传参考图',
    uploadReferenceMessage: '上传您的风格参考图，我们的人工智能将生成相匹配的设计。',
    analysing: '分析图像',
    selectFurniture: '选择家具',
    assist: '选择您想要协助的区域',
    selectArea: '选择区域',
    ask: '您想要如何提问？',
    enhancing: '增强渲染（10秒）'
  },
  checkList: {
    lamp: '灯',
    painting: '装饰画',
    sofa: '沙发',
    blanket: '毯子',
    tray: '托盘',
    cushion: '垫子',
    coffee: '桌子',
    rug: '地毯',
    stool: '凳子',
    table: '桌子',
    curtain: '窗帘',
    door: '门',
    windowpane: '窗户',
    television: '电视',
    chandelier: '吊灯',
    armchair: '扶手椅',
    bookcase: '书柜',
    flower: '花',
    book: '书',
    ottoman: '箱式凳',
    pot: '瓶',
    plate: '盘子',
    plant: '植物',
    cabinet: '陈列柜',
    chair: '椅子',
    ball: '球',
    food: '食物',
    vase: '装饰瓶',
    glass: '玻璃',
    Wall: '墙',
    Floor: '地面',
    Ceiling: '天花板',
    light: '灯',
    washer: '洗衣机',
    bosket: '篮子',
    mirror: '镜子',
    bed: '床',
    pillow: '枕头',
    chest: '大箱子',
    box: '盒子',
    fan: '风扇',
    bench: '长凳',
    stairs: '楼梯',
    bottle: '瓶子',
    basket: '篮子',
    refrigerator: '冰箱',
    sink: '下陷',
    kitchen: '厨房',
    shelf: '架子',
    oven: '烤箱',
    plaything: '玩具',
    stove: '火炉',
    hood: '发动机罩',
    person: '物',
    counter: '柜台',
    wardrobe: '衣柜',
    apparel: '服装',
    bag: '纸袋',
    clock: '时钟',
    fireplace: '壁炉',
    countertop: '工作台面'
  },
  value: {
    furnishing: '家具',
    architectural: '建筑',
    promptHolder: '输入您喜欢的风格',
    select: '选择',
    Lasso: '套索工具',
    Eraser: '橡皮擦',
    Deselect: '取消选择',
    Mask: '蒙版',
    'Hide dots': '隐藏点',
    'Show dots': '显示点',
    Brush: '画笔',
    Add: '添加',
    Shapes: '形状',
    Upload: '上传',
    palette: '颜色',
    Webcam: '摄像头',
    AddFurniture: '添加家具',
    SimilarItems: '相似家具',
    Detection: '相似家具',
    backOriginal: '返回原始图像',
    saveGenerated: '保存生成的照片',
    RemoveObject: '删除对象',
    Program: '外部程序',
    Furniture: '家具',
    Architectural: '建筑',
    WholeRoomRedesign: '整家设计',
    PartialRemodel: '局部改造',
    uploadUrl: '已上传',
    style: '选择室内设计风格',
    roomType: '选择房间类型',
    emptyRoom: '是否为空房间？',
    area: '选择要添加项目的区域',
    selected: '已选择',
    productImage: '指定您喜欢的家具风格',
    colorMaterial: '指定您喜欢的颜色和材料',
    mode: '选择模式',
    PRESERVED: '结构保留',
    WELL: '精心设计',
    YES: '是',
    NO: '否',
    Wall: '墙',
    Floor: '地板',
    Ceiling: '天花板',
    PUBLIC: '公开 (免费)',
    maskUrl: '选择要添加项目的区域',
    PRIVATE: '私人 (会员)',

    bed_headboard: '床头板',
    bench: '长椅',
    chair: '椅子',
    lighting: '照明',
    mirror: '镜子',
    ottoman_pouf: '软椅',
    pillow_throw: '枕头和毯子',
    rack: '架子',
    rug: '地毯',
    sofa_sectional: '沙发和组合沙发',
    storage_cabinet: '储物柜',
    table: '桌子',
    Bed: '床',
    Bench: '长凳',
    Chair: '椅子',
    'Home Décor': '家居装饰',
    home_decor: '家居装饰',
    Lighting: '灯具',
    Mirror: '镜子',
    'Ottoman Pouf': '奥斯曼坐垫',
    'Pillow Throw': '靠垫',
    Rack: '架子',
    Rug: '地毯',
    'Sofa Sectional': '沙发组合',
    'Storage Cabinet': '储物柜',
    Table: '桌子',
    Beds: '床',
    Headboard: '床头板',
    Mattress: '床垫',
    'Metal Bench': '金属长椅',
    'Upholstered Bench': '软垫长椅',
    'Wood Bench': '木制长椅',
    'Accent Chair': '装饰椅',
    Armchair: '扶手椅',
    'Bar Stool': '吧台凳',
    'Counter Stool': '吧台椅',
    'Dining Chair': '餐椅',
    'Lounge Chair': '休闲椅',
    'Office Chair': '办公椅',
    Bowls: '碗',
    'Indoor Plants and Terrarium': '室内植物和玻璃容器',
    'Indoor Plants & Terrarium': '室内植物和玻璃容器',
    Objects: '物品',
    Trays: '托盘',
    Vases: '花瓶',
    'Wall Art': '墙艺术',
    Chandeliers: '吊灯',
    'Floor Lighting': '地灯',
    'Flush Mount': '吸顶灯',
    Pendants: '吊灯',
    'Semi Flush Mount': '半吸顶灯',
    'Table Lamp': '台灯',
    'Wall Sconces': '壁灯',
    'Decorative Mirror': '装饰镜',
    'Floor Mirror': '全身镜',
    'Wall Mirror': '壁挂镜',
    Ottoman: '儿童凳',
    Pouf: '小坐凳',
    'Decorative Pillows and Cushion': '装饰枕头和靠垫',
    'Throw blanket': '抱枕毯',
    'Floor Racks': '地面架',
    'Wall Racks': '壁挂架',
    'Area Rug': '地毯',
    'Door Mat': '门垫',
    'Round Rug': '圆形地毯',
    Runner: '窄长地毯',
    '2 Seater': '双人沙发',
    '3 Seater': '三人沙发',
    '4 Seater': '四人沙发',
    'Left Sectional': '左侧沙发',
    'Recliner Sofa': '躺椅沙发',
    'Right Sectional': '右侧沙发',
    'U Sectional and Corner Sectional': 'U型沙发和角落沙发',
    Bookcase: '书架',
    'Buffet and Sideboard': '餐边柜和镜柜',
    'Dresser and Wardrobe': '梳妆台和衣柜',
    'Media Console': '多媒体控制台',
    'Accent Table': '装饰桌',
    'Bar Cart': '酒吧推车',
    'Bar Table': '吧台',
    'Coffee Table Cocktail Table': '咖啡桌',
    'Console Table': '台式桌',
    'Counter Table': '吧台桌',
    Desk: '写字台',
    'Dining Table': '餐桌',
    'Game Table': '游戏桌',
    'Nesting Table': '嵌套桌',
    Nightstand: '床头柜',
    'Side Table': '边几',
    'Vanity Table': '梳妆台',
    prompt: '输入提示词',
    GenerateImage: '生成图片',
    False: '常规',
    EMPTY_ROOM: '毛坯',
    True: '杂乱',
    FURNISHED_ROOM: '常规'
  },
  select: '选择',
  customerStyle: {
    title: '上传参考风格图片',
    desc: '上传您的风格参考图片，我们的人工智能将生成匹配的设计。'
  },
  RoomType: {
    tabs: {
      kitchen: '厨房',
      bathroom: '浴室',
      bedroom: '卧室',
      'living room': '客厅',
      'dining room': '餐厅',
      patio: '阳台',
      kids: '儿童房'
    }
  }
}
