export const community={
    title: 'Welcome to collov.ai Style Community',
    subTitle: 'Explore thousands of good style, rendering example to kickstart your interior design idea.',
    name: 'Train your style',
    text: `Our AI can generate a personalized style for images using just one style image and a 50-word
            prompt. It combines the style of the reference image with the content from the prompt to create a new,
            unique image.`,
    try: 'Contact sales',
    Yourownstyle: 'Your own style',
    work: 'How it works',
    card: {
        name: 'Create your interface',
        text: ['Step 1. Select all the styles you want to include in your interface',
            'Step 2. The interface with the styles you selected are ready to use and to share'],
        try: 'Create Your Enterprise Package',
    },
    NewStyle: 'New Style',
    Search: 'Search…',
    MyOwnStyle: 'My Own Style',
    TEXT: 'TEXT',
    ROOM: 'ROOM',
    Edit: 'Edit',
    Duplicate: 'Duplicate',
    Delete: 'Delete',
    RealRenders:'Real-time Renders',
    ExploreRenders:'Explore Latest Renders',
    MyRendering:'My Rendering',
    YourRendering:'Your Rendering',
    createRendering:'Create New Rendering',
    cabinet: {
        title: 'Boost Your Cabinet Sales with AI',
        subTitle: `Are you tired of losing 70% of your customers who walk away with free cabinet mock-up designs? Our Generative-AI solution is here to solve this issue.
     <br/> <br/> No training needed for your sales staff and designer associates. With our AI, your customers can generate kitchen designs featuring your cabinets within 10 seconds. This results in cost savings for you and increased revenue.`,
        value: `Want Collov Enterprise for advanced functions?`,
    },
    tabs:['most popular', 'latest', 'Saved'],
    selectStyle:{
        title:`Please select the style you would like to showcase on your website.`,
        View:'View Selected Style',
    },
    works:{
        title:'Create your interface FAQ',
        subtitle:'Feature roadmap',
        features:['Choose the style you prefer or create your own unique style',
            'Create your very own AI design page, tailored to your unique preferences and style.',
            'Sharing with friends is effortless, and you can quickly grasp how to utilize AI for designing your room.'],
        how:'How to use this?',
        texts:[
            'Select an interior design style for your AI design website that truly represents your vision and creativity. Whether you prefer a modern, eclectic, or community-inspired style, let your website become a platform to showcase your unique aesthetic and invite others to be inspired by your designs.',
            'We offer a selection of official training styles that you can incorporate into your AI design website.',
            'Share your site with your friend, and both of you can enjoy unlimited free generation now.'
        ]
    },
    model:{
        AIGenerated:'AI Generated',
        list:[
            {name: 'Information', num: 1},
            {name: 'Setting', num: 2},
            {name: 'Publish', num: 3},
        ],
        Name:'Name',
        Email:'Email',
        emailName:'Enterprise User Email',
        placeholderName:'Name of your style',
        uploadName:'Upload a preferred image to train your design model',
        uploadNamePro:'Please upload 5-20 high-quality images that ensure the same style, with multiple angles, various room types, and diverse furniture preferred.',
        example:'Photo Example',
        colorPrompt:'Color Prompt',
        roomPrompt:'Room Prompt',
        excludePrompt:'Negative Prompt',
        colorPromptMessage:`if mode is pure, one color prompt is needed
if mode is mix, first color prompt for upper cabinet, second color prompt for lower cabinet`,
        optimizePrompt:'Optimize your prompt',
        join:'Join the Design Hackathon',
        train:'Train model',
        exampleTitle:'Good photo example',
        exampleList:[
            'High resolution',
            'Distinct style',
            'Careful selection of furniture',
            'Proper lighting',
            'Sufficient variety',
            'Consistent framing',
            'Image labeling',
        ],
        coverUpload:'Upload your cover image',
        coverGenerate:'Generate your cover image',
        coverEdit:'Edit cover image',
        Preview:'Preview',
        Date:'Date',
        Delete:'Delete',
        Publish:'Publish',
        tip:`Once a design model is published, editing it directly is not possible. Instead, you must copy a
        new draft and edit it if you need to make changes to the published design model.`,
    },
    introduce:{
        title:'Customized Design Model',
        subTitle:`Our AI can generate a personalized style for images using just one style image and a 50-word prompt. It combines the style of the reference image with the content from the prompt to create a new, unique image.`,
        styleImage:'style image',
        text:`bohemian style, minimalism armchair, eclectic sectional sofa, floor lamp, neutral colored, high-quality fabrics, patterned textiles, natural decorations, baskets, layered bohemia rugs, carved wood furniture, lampshades, colorful moroccan lanterns, contrasting colors pillows, moroccan poufs
`,
        Prompt:'Prompt'

    },
    strengthName:'Style strength',
    strengthText:'Style strength is the level of intensity with which a style is applied and adjusting it to the most realistic value is important.',

}
