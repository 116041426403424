export const floorPlan={
    searchTitle:'Search by address',
    searchMessage:'Please provide your address to select a floor plan tailored to your residence, or feel free to upload your own or utilize our template for customization.',
    searchUpload:'Upload by myself',
    placeholder:'Enter your address',
    placeholderSize:'Please enter your room size',
    sqft:'sqft',
    m2:'m²',
    ft:'ft',
    m:'m',
    Length:'Length',
    or:'or',
    try:'Try our template',
    builtTitle:'Built-up area & Scaling',
    builtText:`Please input the area you'd like to design for the room or use a scale to define the size of the space.`,
    builtTab:{
        area:'Built-up area',
        scale:`Architect's scale`,

    },
    tabs:{
        Rendering:'Rendering',
        Panorama:'Panorama',
    },
    '2D':'2D',
    '3D':'3D',
    'rendering':'rendering',
    upload:'Upload floorPlan',
    scale:'Update Scale',
    updateRoom:'Update Room',
    updateDesign:'Modify design plan',
    cameraFov:'Choose Perspective',

}