import { defineStore } from 'pinia'
import { useI18n } from 'vue-i18n'
import {
  requestGetEditRecord,
  requestGetRecord,
  requestSelectImage,
  requestGenerateImage,
  requestGenerateMaskUrls,
  requestListFurnitureForNewAi,
  requestGetMaskUrls,
  requestSaveMagicEraser,
  requestGetUpScaleUrl,
  requestOidGlGenerate
} from '@/apis/design'
import { ElMessage } from 'element-plus'
import { clientStore } from '@/stores/client'
import { Loading } from '@/libs/loading/index'
import { requestUploadBase64 } from '@/apis/common'
import { categories } from '@/dictionary/categories'
/*存初始数据*/
export default {
  setup() {
    const { t } = useI18n()
  }
}
const basicForm = {
  quality: 'HIGH',
  strength: 'STRONG',
  privacy: 'PUBLIC',
  mode: 'PRESERVED',
  speed: 'FAST',
  type: 'FREE',
  emptyRoom: 'NO',
  sourceId: '',
  maskTypes: '',
  use: 0
}
const defaultForm = {
  'designModel.id': '',
  requestId: '',
  roomType: '',
  styleImage: '',
  style: '',
  uploadUrl: '',
  maskUrl: '',
  color: '',
  material: '',
  prompt: '',
  productImage: '',
  description: '',
  background: '',
  productMessage: '',
  thumbPrompt: '',
  generateUrl: '',
  category: '',
  mode: '',
  use: 0
}
export const currentState = {
  recordForm: {
    ...basicForm,
    ...defaultForm
  }
}

import { canvasStatus, createGenerateForm, designTool, getModeTool } from '@/dictionary/designTool'
import { formatText, goDesign, retry, usePolling, Retry } from '@/hooks/util'
import { userRecord } from '@/hooks/record'
import { useDraw } from '@/hooks/draw'
import { listRooms } from '@/apis/designDashBoard'
import { styleNavs } from '@/dictionary/style'

const undoList = []
const redoList = []
export const magicObj: any = {
  result: [],
  categories: [],
  maskArea: '',
  posMask: '',
  url: ''
}
const _magicObj = JSON.parse(JSON.stringify(magicObj))
export const resetMagicObj = () => {
  Object.keys(_magicObj).forEach((key) => (magicObj[key] = _magicObj[key]))
}
export const designStore = defineStore<any, any>('design', {
  state: () => {
    return {
      form: {
        ...defaultForm,
        videoUrl: ''
      },
      realStyle: [],
      realFull: false,
      downloadLoading: '',
      mode: '',
      formType: '',
      generateMode: '',
      id: '',
      imagLoad: false,
      styleActive: '',
      showUrl: '',
      initUrl: '',
      disabled: false,
      /*设计类型*/
      type: '',
      loading: false,
      replaceLoading: false,
      enhancing: false,
      earseLoading: false,
      /*活动状态*/
      activeType: 'Design',
      refresh: false,
      redo: 0,
      undo: 0,
      canUndo: false,
      canRedo: false,
      drawType: 'cursor',
      /*设计步骤*/
      stepObj: {
        next: false,
        active: '',
        ask: false,
        left: 0,
        top: 0,
        x: 0,
        y: 0
      },
      loadingMaskUrl:'',
      historyList: [],
      /*选择区域模块*/
      selected: [],
      selected2: [],
      referenceId: -1,
      magicType: 'furnishing',
      architectural: '',
      itemDetail: {},
      /*商品*/
      similar: {
        loading: false,
        list: [],
        replaceList: []
      },
      categories: [],
      backTimes: 0,
      use: 0,
      base64Pic: '',
      pendingAll: {
        generatePending: false,
        pendingImages: [],
        beforeImage: '',
        resultImage: '',
        upScaleUrl: '',
        itemSelect: false,
        item: {},
        id: 0,
        index: 0,
        reGenerate: false,
        reGenerateLoading: false,
        actionTypeChangeFlag: false
      },
      homeRedesign: {
        remodelType: [
          { name: 'WholeRoomRedesign', selected: true },
          { name: 'PartialRemodel', selected: false }
        ],
        areaType: 'WholeRoomRedesign'
      },
      isBeforeAfter: false
    }
  },
  getters: {
    getTypeInfo(state) {
      // 自动完成! ✨
      const idx = designTool.findIndex((f) => f.type === state.type)
      return idx >= 0 ? designTool[idx] : {}
    },
    getTime(state) {
      return this.getTypeInfo.time || 35
    },
    getNeedCompleteList(state) {
      const { formType } = this.getTypeInfo
      if (!formType) {
        return []
      }
      const { step, formKey } = formType
      const requiredList = step.filter((f) => f.required)
      const needEditList = requiredList.filter(({ key, active }) => {
        const keys = formKey[key]
        const hasValue =
          keys &&
          keys.some(function (value) {
            if (!active) return !!state.form[value]
            return !!state.form[state[active]]
          })
        return !hasValue
      })
      return needEditList || []
    },
    getAlList(state) {
      const info = this.getTypeInfo
      const _formType = this.formType || 'formType'
      if (!info[_formType]) {
        return []
      }
      const infoDetail = state.type === 'fastPreserved' ? info[_formType] : info['formType']
      const step = infoDetail.step
      const formKey = infoDetail.formKeys || infoDetail.formKey
      const needEditList = step.filter(({ key, active, condition }) => {
        if (condition) {
          return !condition(state.form)
        }
        if (formKey instanceof Array) {
          return true
        }
        const keys = formKey[key]
        const hasValue =
          keys &&
          keys.some(function (value) {
            if (!active) return !!state.form[value]
            return !!state.form[state[active]]
          })
        return !hasValue
      })
      return needEditList || []
    },
    buttonType(state) {
      if (!state.type) {
        return false
      }
      if (state.type === 'assistant') {
        if (state.stepObj.ask) return 'done'
        return false
      }
      if (state.type === 'eraser') {
        return 'done'
      }
      if (state.type === 'fastPreserved' && state.formType === 'generateForm') {
        return 'done'
      }
      if (state.type === 'Upscale') {
        return 'upscale'
      }
      const len = this.getNeedCompleteList?.length
      return len < 1 ? 'generate' : false
    },
    /*总步骤*/
    allStep(state) {
      if (!state.type) {
        return 0
      }
      const { formType } = this.getTypeInfo
      if (!formType) {
        return 0
      }
      const { step } = formType

      return step.filter((f) => f.required).length
    },
    /*获取当前类型所有的步骤*/
    hasStep(state) {
      if (!state.type) {
        return []
      }
      const { formType } = this.getTypeInfo
      if (!formType) {
        return []
      }
      const { step } = formType
      return step.map((m) => m.key)
    },
    /*获取下一步*/
    getNextStep(state) {
      if (!state.type) {
        return ''
      }

      if (this.getAlList.length > 0) {
        return this.getAlList[0].key
      }
      if (state.type==='redesign'){
        const idx=this.getTypeInfo.formType.step.findIndex(f=>f.open)
        if (idx>=0){
          this.getTypeInfo.formType.step[idx].open=false
          return 'mode'
        }
      }

      return ''
    },
    /*剩余步骤*/
    getLeft(state) {
      if (!state.type) {
        return 0
      }
      return this.getNeedCompleteList.length
    },
    maskType(state) {
      if (state.magicType === 'furnishing') {
        return 'Furniture'
      }
      return 'Architectural'
    },
    getUseList(state) {
      return (name = 'result', locale) => {
        const arr = designTool
          .filter((f) => f.showList && f.showList.indexOf(name) > -1)
          .filter((f) => (f.includes && state.drawType ? f.includes.includes(state.drawType) : true))
        return arr.filter((f) => f.country?.includes(locale))
      }
    },
    getEditType(state) {
      return state.drawType ? (canvasStatus.includes(state.drawType) ? 'draw' : 'design') : 'design'
    },
    getCategories(state) {
      let arr = []
      const category = formatText(state.form.category)
      categories.forEach((f) => {
        const idx = f.subcategories.findIndex((s) => {
          const name = formatText(s.name)
          return name === category
        })
        if (idx >= 0) {
          arr = f.subcategories[idx].products
        }
      })
      return arr
    }
  },
  actions: {
    resetActionType() {
      this.pendingAll.actionTypeChangeFlag = !this.pendingAll.actionTypeChangeFlag
    },
    /*清空form*/
    clearForm() {
      for (const i in this.form) {
        this.form[i] = defaultForm[i]
      }
      undoList.splice(0, undoList.length)
      redoList.splice(0, redoList.length)
      this.undo = undoList.length
      this.redo = redoList.length
    },
    /*获取record数据*/
    setInitForm(res, notNeed, resetMagic = true) {
      // this.selected = []
      this.id = res.id
      this.form.mode = ''
      if (resetMagic) {
        magicObj.result = []
        magicObj.categories = []
        magicObj.maskArea = ''
      }
      this.generateMode = res.mode || ''
      this.form.privacy = res.privacy || 'PUBLIC'
      this.form.emptyRoom = res.emptyRoom || 'NO'
      this.mode = res.mode || ''
      this.initUrl = res.upScaleUrl || res.generateUrl || res.uploadUrl

      if (notNeed) {
        this.form.privacy = 'PUBLIC'
        this.form.emptyRoom = 'NO'
        this.form.uploadUrl = res.uploadUrl || res.generateUrl
        this.form.generateUrl = res.generateUrl
        this.showUrl = res.upScaleUrl || res.generateUrl
      } else {
        for (const i in this.form) {
          this.form[i] = res[i]
        }
        if (res.maskTypes) {
          const maskTypes = JSON.parse(res.maskTypes)
          if ('furnishing' in maskTypes) {
            this.selected = maskTypes['furnishing']
            this.magicType = 'furnishing'
          } else {
            this.architectural = maskTypes['architectural'][0]
            this.magicType = 'architectural'
          }
        }
      }
      if (this.type === 'redesign') {
        this.form.mode = res.mode || 'PRESERVED'
      }
      for (const i in currentState.recordForm) {
        currentState.recordForm[i] = res[i] || currentState.recordForm[i]
      }
      this.addRecord()
    },
    selectImage(id, index) {
      return requestSelectImage({ id, index })
    },
    async saveMagicEraser(router, route) {
      try {
        if (!this.id) {
          return ElMessage.warning('please select the area you want to remove')
        }
        this.disabled = true
        this.loading = true
        await requestSaveMagicEraser({
          id: this.id,
          url: this.form.generateUrl || this.form.uploadUrl
        })
        this.loading = false
        this.refresh = !this.refresh

        router.push('/result/' + this.id + (route.query.oid ? '&oid=' + route.query.oid : ''))
      } catch (e) {
        this.disabled = false
        this.loading = false
        ElMessage.error(e)
      }
    },
    getUnselectedMasks() {
      const selectedMask = this.architectural
      const excludes = ['floor', 'rug']
      return magicObj.result.filter((f: any) => {
        // return selectedMask.indexOf(f.name) === -1 && excludes.indexOf(f.name) === -1
        if (f.name === 'rug' && selectedMask.indexOf('rug') !== -1) {
          return false
        }
        return f.category.indexOf('furnishing') !== -1
      })
    },
    async saveOtherMaskUrl() {
      // console.log('magicObj', magicObj)
      if (magicObj.result.length) {
        return JSON.stringify(magicObj.result)
      }
      const result = await this.generateMaskUrls(this.form.uploadUrl)
      return JSON.stringify(result)
    },
    /*开始生成图片*/
    async generageImage(route, router, url, useVal) {
      if (!this.toVerify()) {
        return Promise.reject(false)
      }
      if (this.loading) {
        return
      }
      const idx = designTool.findIndex((f) => f.type === this.type)
      const { mode } = designTool[idx]
      if (this.type !== 'eraser') {
        this.loading = true
      }
      if (this.type === 'WALL_FLOOR_CEILING') {
        this.form.otherMaskUrl = await this.saveOtherMaskUrl()
      }
      if (this.form.mode !== 'PRESERVED') {
        this.form.maskUrl = this.form.maskUrl || url
        if (this.form.maskUrl && this.form.maskUrl.indexOf('data:image/png;') > -1) {
          this.form.maskUrl = await this.uploadBase64(this.form.maskUrl)
          this.saveMaskArea(route)
        } else if (this.form.maskUrl === 'maskArea') {
          this.form.maskUrl = await this.uploadBase64(magicObj.maskArea)
          this.saveMaskArea(route)
        }
      } else {
        this.form.maskUrl = ''
        this.form.selectionUrl = ''
      }
      const params = {
        ...basicForm,
        ...this.form,
        mode: this.form.mode || mode,
        productImage: this.form.productImage || this.form.productMessage || ''
      }
      const list = styleNavs.filter((f) => f.key !== this.styleActive)
      list.forEach((f) => {
        delete params[f.key]
      })
      if (params.generateUrl) {
        params.uploadUrl = params.generateUrl
      }
      const detail = getModeTool(this.type, 'type')
      if (!detail.redesign && route.name === 'result') {
        params.id = this.id
        if (this.form.generateUrl) {
          this.form.uploadUrl = this.form.generateUrl
        }
        delete params.generateUrl
      }
      if (useVal === 2) {
        params.id = ''
      }
      /* if (this.id && this.type !== 'redesign'&&this.type !== 'kitchenRemodeling'&&this.type !== 'fastPreserved'&&route.name==='result') {
                 params.id = this.id
                 if (this.form.generateUrl) {
                     this.form.uploadUrl = this.form.generateUrl
                 }
                 delete params.generateUrl
             }*/
      return this.saveGenerate(params, route, router)
    },
    async generagePendingImage(route, router, url) {
      if (!this.toVerify()) {
        return Promise.reject(false)
      }
      if (this.loading) {
        return
      }
      const idx = designTool.findIndex((f) => f.type === this.type)
      const detailTool = designTool[idx]
      const formKey = detailTool.formType?.formKey || {}
      const form = this.form
      /*for(let i in formKey){
                formKey[i].forEach(f=>{
                    form[f]=this.form[i]
                })
            }*/
      const { mode } = detailTool
      if (this.type !== 'eraser') {
        this.loading = true
      }
      if (this.type === 'WALL_FLOOR_CEILING') {
        this.form.otherMaskUrl = await this.saveOtherMaskUrl()
      }
      this.form.maskUrl = this.form.maskUrl || url
      if (this.form.maskUrl && this.form.maskUrl.indexOf('data:image/png;') > -1) {
        this.form.maskUrl = await this.uploadBase64(this.form.maskUrl)
        this.saveMaskArea(route)
      } else if (this.form.maskUrl === 'maskArea') {
        this.form.maskUrl = await this.uploadBase64(magicObj.maskArea)
        this.saveMaskArea(route)
      }
      const params = {
        ...form,
        mode: this.form.mode || mode,
        productImage: this.form.productImage || this.form.productMessage || ''
      }
      if (this.styleActive) {
        const list = styleNavs.filter((f) => f.key !== this.styleActive)
        list.forEach((f) => {
          delete params[f.key]
        })
      }
      delete params.videoUrl
      delete params.generateUrl
      return this.saveGenerate(params, route, router)
    },
    async generateForm({ params, route, router }) {
      const detail = getModeTool(this.type, 'type')
      if (!params) {
        params = createGenerateForm(detail.generateForm, this.form)
      }
      if (!this.toVerify()) {
        return Promise.reject(false)
      }
      if (this.loading) {
        return
      }
      this.loading = true
      return this.saveGenerate(params, route, router)
    },
    async generateImage(params) {
      if (!this.toVerify()) {
        return new Promise((resolve, reject) => reject(false))
      }
      this.loading = true
      if (params.maskUrl && params.maskUrl.indexOf('data:image/png;') > -1) {
        params.maskUrl = await this.uploadBase64(params.maskUrl)
      }
      return new Promise((resolve, reject) => {
        this.loading = true
        requestGenerateImage(params)
          .then((res) => {
            const client = clientStore()
            if (!res.success) {
              this.loading = false
              if (res.message.indexOf('no credits') > -1) {
                client.showUpgrade()
                this.loading = false
                return reject(false)
              }
              ElMessage.error(res.message)
              reject(false)
              return
            } else {
              client.getMember()
              resolve(res)
            }
          })
          .catch(() => {
            this.loading = false
          })
      })
    },
    async getRecordDetail(id, timer) {
      const res = await requestGetRecord(id)
    },
    saveGenerate(params, route, router, pageType) {
      delete params.generateUrl
      if (params.mode === 'GEN_BGIMAGE') {
        if (this.styleActive === 'background') {
          params.prompt = params.description || params.prompt
          delete params.description
        } else if (this.styleActive === 'style') {
          delete params.description
          delete params.prompt
        }
      }
      /*if((JSON.parse(params.maskTypes).furnishing
                && !JSON.parse(params.maskTypes).furnishing.length)
                || (JSON.parse(params.maskTypes).architectural && !JSON.parse(params.maskTypes).architectural.length)
                && this.type === 'ALL_IN_ONE' ){
              params.mode = 'PRESERVED'
            }*/
      if (this.type === 'ALL_IN_ONE' && (null === params.maskTypes || '' === params.maskTypes)) {
        params.mode = 'PRESERVED'
      }
      if (this.type === 'ALL_IN_ONE' && null != params.maskTypes && '' != params.maskTypes) {
        params.mode = 'MASK_GENERATE'
      }
      if (this.type === 'ALL_IN_ONE' && this.drawType === 'deselect') {
        params.mode = 'MAGIC_ERASER'
      }
      /*if((JSON.parse(params.maskTypes).furnishing && JSON.parse(params.maskTypes).furnishing.length) || (JSON.parse(params.maskTypes).architectural && JSON.parse(params.maskTypes).architectural.length) && this.type === 'ALL_IN_ONE' ){
              params.mode = 'MASK_GENERATE'
            }*/
      return new Promise((resolve, reject) => {
        requestGenerateImage(params)
          .then((res) => {
            const client = clientStore()
            if (!res.success) {
              this.loading = false
              if (res.message.indexOf('no credits') > -1) {
                client.showUpgrade()
                this.loading = false
                reject(false)
                return false
              }
              ElMessage.error(res.message)
              reject(false)
              return
            } else {
              client.getMember()
              if (route.query.oid) {
                requestOidGlGenerate({
                  'outerClient.id': route.query.oid,
                  'aiGenerateRecord.id': res.data.id
                })
              }
              if (params.mode === 'MAGIC_ERASER') {
                this.loading = false
                resolve(res)
              } else if (params.id) {
                this.getEditRecord(res.data.id)
                return
              } else if (pageType) {
                if (params.mode === 'FAST_PRESERVED') {
                  setTimeout(() => {
                    goDesign(
                      '/design/pending?t=' +
                        new Date().getTime() +
                        '&id=' +
                        res.data.id +
                        (route.query.oid ? '&oid=' + route.query.oid : '') +
                        (route.query.type ? '&type=' + route.query.type : '')
                    )
                  }, 1 * 1000)
                } else {
                  this.loading = false
                  goDesign(
                    '/design/pending?t=' + new Date().getTime() + '&id=' + res.data.id + (route.query.oid ? '&oid=' + route.query.oid : '')
                  ) + (route.query.type ? '&type=' + route.query.type : '')
                }
              } else if (route) {
                if (params.mode === 'FAST_PRESERVED') {
                  setTimeout(() => {
                    this.toPageNext(res, route, router)
                  }, 1 * 1000)
                } else {
                  this.loading = false
                  this.toPageNext(res, route, router)
                }
                return
              }
              return new Promise((resolve) => res)
            }
          })
          .catch(() => {
            this.loading = false
          })
      })
    },
    toVerify() {
      const client = clientStore()
      if (!client.hasLogin) {
        client.loginVisible = true
        client.loginType = 'login'
        return false
      }
      if (this.loading) {
        return false
      }
      return true
    },
    getEditRecord(id, call, successCall) {
      this.loading = true

      retry(requestGetEditRecord, { id }, 'editing', null)
        .then((res) => {
          if (res.status === 'FAILED') {
            const { t } = useI18n()
            return ElMessage.error(t('pending.failTip'))
          } else {
            this.form.uploadUrl = res.generateUrl
            this.pendingAll.generatePending = false;
            this.pendingAll.beforeImage = res.uploadUrl;
            this.pendingAll.resultImage = res.generateUrl;
            this.refresh = !this.refresh
            console.log('this.pendingAll==>', this.pendingAll)
            this.saveRecord()
          }
        })
        .catch(() => {
          call && call('err')
          this.loading = false
        })
    },
    saveLocalGenRecord(pendingId: string) {
      if (import.meta.env.VITE_CONFIG_TYPE !== 'integrate') {
        return
      }
      const key = 'gids'
      const ids = JSON.parse(localStorage.getItem(key) || '[]')
      if (!ids.includes(pendingId)) {
        ids.unshift(pendingId)
        localStorage.setItem(key, JSON.stringify(ids))
      }
    },
    toPageNext(res, route, router) {
      const query = route.query
      const params = {
        id: res.data.id,
        from: query.from
      }
      if (route.query.oid) {
        params.id = route.query.oid
      }
      router.push({
        path: '/pending',
        query: {
          ...params,
          t: new Date().getTime(),
          type: route.query.type
        }
      })
    },
    reGenerageImage(route, router, pageType, item) {
      if (!this.toVerify()) {
        return Promise.reject(false)
      }
      this.loading = true
      let params = {}
      if (pageType !== 'history') {
        params = {
          ...currentState.recordForm
        }
      } else {
        params = {
          ...item
        }
      }
      return this.saveGenerate(params, route, router, pageType)
    },
    toUpscale() {
      const params = {
        id: this.id,
        generateUrl: this.form.generateUrl,
        size: '4x'
      }
      requestGetUpScaleUrl(params)
    },
    clearMaskUrls() {
      this.stepObj.ask = false
      this.referenceId = -1
      this.itemDetail = ''
      this.selected = []
      this.form.maskUrl = ''
      this.form.category = ''
      this.form.productImage = ''
      this.form.productMessage = ''
      const draw = useDraw('designPage')
      if (draw) {
        draw.clear()
        draw.setCurrent()
        draw.saveRecord()
      }
    },
    async generateMaskUrls(url, text) {
      if(this.loadingMaskUrl===url){
        return
      }
      const uploadUrl = url
      if (!uploadUrl) {
        return
      }
      const _retry = new Retry()
      this.success = false
      const vm = Loading({
        text: text || t('designForm.title.analysing'),
        close: () => {
          _retry.close()
        }
      })
      this.loadingMaskUrl=uploadUrl
      const res = await requestGenerateMaskUrls({ uploadUrl })
      if (res.success) {
        return new Promise((resolve) => {
          _retry.func(requestGetMaskUrls, { uploadUrl: url }, '').then((res) => {
            const result = JSON.parse(res.result)
            vm.props.onClose()
            resolve(result)
            magicObj.result = result
          })
        })
      } else {
        ElMessage.error(res.message)
        vm.props.onClose()
      }
    },
    async uploadBase64(base64) {
      const data = {
        base64,
        referType: 'OTHER'
      }
      const res = await requestUploadBase64(data)
      return res.data.url
    },
    async listFurnitureForNewAi(base64) {
      const params = {
        base64: base64.replace('data:image/jpeg;base64,', ''),
        secondaryType: this.itemDetail.secondaryType,
        type: this.itemDetail.type,
        count: 1000,
        splitCount: 50,
        isNewAi: true
      }
      const res = await requestListFurnitureForNewAi(params)
      this.similar.loading = false
      this.similar.list = res.list || []
    },
    async listSimilarItem(base64, obj) {
      this.similar.list = []
      this.similar.loading = true
      const params = {
        base64: base64.replace('data:image/jpeg;base64,', ''),
        secondaryType: obj.secondaryType.rid || '',
        type: obj.type || obj.object,
        count: 1000,
        splitCount: 50,
        isNewAi: true
      }
      const res = await requestListFurnitureForNewAi(params)
      this.similar.loading = false
      this.similar.list = res.list || []
    },
    saveMaskArea(route) {
      const obj = {}
      if (this.magicType === 'furnishing') {
        obj['furnishing'] = this.selected
      } else {
        if (route.query.type === 'WALL_FLOOR_CEILING') {
          obj['architectural'] = this.architectural
        } else {
          obj['architectural'] = [this.architectural]
        }
      }
      if (route.query.type === 'WALL_FLOOR_CEILING' && this.architectural) {
        if (Array.isArray(this.architectural)) {
          this.form.category = this.architectural.join(',')
        } else {
          this.form.category = this.architectural
        }
      }
      this.form.maskTypes = JSON.stringify(obj)
    },
    saveRecord() {
      const record = userRecord()
      const { undo, redo } = record.save({ state: JSON.stringify(this.$state) })
      this.undo = undo
      this.redo = redo
    },
    addRecord() {
      const record = userRecord()
      record.addData({ state: JSON.stringify(this.$state) })
    },
    listRooms(recordId) {
      listRooms({ recordId }).then((res) => {
        this.historyList = res
      })
    }
  }
})
export const modelStore = defineStore<any, any>('model', {
  state: () => {
    return {
      step: 0,
      loading: false,
      result: {},
      form: {
        id: '',
        name: '',
        description: '',
        styleStrength: 1,
        styleImage: '',
        prompt: '',
        coverImage: ''
      }
    }
  },
  getters: {},
  actions: {}
})
