export const floorDesign =  {
  Introduce: {
    title:'地板真实填充',
    subtitle: '使用AI精度可视化空间中的真实材料',
    text: '改变您的空间设计方式，将真实的地板材料与人工智能准确度无缝集成，以惊人的真实感将您的愿景变为现实',
    JoinNow: '立即加入',
    TryDemo: '测试 Demo',
    Before: '之前',
    After: '之后'
  },
  Sales: {
    l: '桥接 <span>设计</span> 和 <span>销售</span>',
    r: '弥合设计和销售之间的差距，为客户提供探索各种地板的能力。这种全面的访问使客户能够尝试各种选择，确保做出符合其愿景的明智决策。'
  },
  Tools: {
    title: '增加5倍'
  },
  Transformation: {
    title: '创造与您的产品一样独特的设计和销售体验',
    subTitle: '在一个平台上展示和销售您的产品所需的所有工具。',
    RealWorldFlooringLibrary: '真实世界地板库',
    RealWorldFlooringLibraryContent:`使用Collov AI将您的SKU转换为动态数字资产。为客户提供广泛的选择
    直接来自您的真实材料，将家居设计与购物体验无缝连接。`
  },
  Precision: {
    title: 'AI PAI动力精密贴合',
    subTitle: '利用先进的人工智能检测和精确缩放地板，优化材料布局和设计和谐。'
  },
  Visuals: {
    title: '即时超逼真视觉效果',
    subTitle: '在几秒钟内实现超逼真的标题、壁纸和绘画效果图，尖端技术与设计相结合，让您的视觉瞬间栩栩如生。'
  },
  Compare: {
    Before: '之前',
    After: '之后',
    title: '比较和对比',
    subTitle: '轻松查看前后场景或并排比较各种设计选项，通过清晰的视觉转换做出明智的决策。'
  },
  More: {
    title: `最佳客房<br/>
    可供替代的`,
    li: `<li>扩展到地板之外，包括橱柜、家具等，所有这些都在Collov的人工智能设计平台内。</li>
    <li>通过智能匹配提供协调的房间设计体验。</li>
    <li>通过增强缩放比例和大小以实现逼真的可视化效果，确保精确贴合</li>`,
    AllinOnePlatform: '一体化平台',
    IntelligentDesignMatching: '智能设计匹配',
    MultiSurfaceSupported: '支持多表面',
  }
}
