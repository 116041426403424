import request from '@/utils/request'
const formatParam = function (params: any) {
  return {
    params: params
  }
}
import type { LoginParams, GetLoginDataParams } from '@/apis/client/type'
export const requestLogin = (params: LoginParams) => request.post('/client/login', params)
export const reqCusTools = (params: LoginParams) => request.post<any>('/customerTool/getCusTools', params)
export const reqSaveView = (params: LoginParams) => request.post<any>('/enterprise/user/saveView', params)
export const reqListModules = (code: string) => request.get<any>('/customerTool/listModules?code='+ code)
export const requestLoginByPhone = (params: LoginParams) => request.post('/client/loginByPhone', params)
export const requestGetValidCode = (params: LoginParams) => request.post('/client/getValidCode', params)
export const requestLoginByPhoneValid = (params: LoginParams) => request.post('/client/loginByPhoneValid', params)
export const requestRegisterByMobile = (params: LoginParams) => request.post<any>('/client/registerByMobile', params)
export const createOrderForAlipay = (params) => request.post('/clientMember/manage/createOrderForAlipay', params)
export const requestGetStatus = (parmas) => request.get('/clientMember/manage/getStatus', formatParam(parmas))
export const createOrderForWx = (params) => request.post('/clientMember/manage/createOrderForWx', params)
export const requestRegister = (params: LoginParams) => request.post('/client/register', params)
export const requestUserInfo = () => request.get('/client/manage/get')
export const hasLogin = () => request.get('/client/hasLogin')
export const requestWeChatLogin = (params) => request.post('/client/weChatLogin', params)
export const requestGetInfo = (params) => request.get('/client/getInfo', formatParam(params))
export const requestGetLoginData = (parmas: GetLoginDataParams) => request.get('/client/getLoginData', formatParam(parmas))
export const getMuseclipLoginData = (parmas: GetLoginDataParams) => request.get('/client/getMuseclipLoginData', formatParam(parmas))
export const requestCheckOauthLogin = (authId: string) => request.get('/client/checkOauthLogin', formatParam({ authId: authId }))
export const requestLogout = () => request.post('/client/logout')
export const requestListCards = () => request.get('/payment/manage/listCards')
export const requestGetPlan = () => request.get('/payment/manage/getPlan')
export const requestSaveCards = (params) => request.post('/payment/manage/save', params)
export const requestCreateOrder = (params) => request.post('/clientMember/createOrder', params)
export const requestConversions = (params) => request.postByJson('/impact/conversions', { params })
export const requestUpdateClient = (params) => request.post('/client/manage/update', params)
export const requestReduceOrder = (params) => request.post('/clientMember/reduceOrder', params)
export const requestCreateUpgradeOrder = (params) => request.post('/clientMember/createUpgradeOrder', params)
export const requestUpdateSubscriptionCard = (params) => request.post('/payment/manage/updateSubscriptionCard', params)
export const requestFeedBack = (params) => request.post('/companyCooperation/feedBack', params)
export const companyCooperation = (params) => request.post('/companyCooperation/save', params)

export const listSaveTrainStyles = (params) => request.get('/clientProfileStyle/listSaveTrainStyles', formatParam(params))
export const listIntegrateSaveTrainStyles = (params) => request.get('/common/manage/cabinetsStylesNew', formatParam(params))
