import '@/assets/styles/index.less'
import '@/assets/font/font.css'

import 'element-plus/es/components/message-box/style/css'
import 'element-plus/es/components/message/style/css'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import router from './router'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import RTB from '@/hooks/RTB'
import { clientStore } from '@/stores/client'
import 'swiper/css'
/*全局组件配置*/
import gloalComponent from '@/components/index'
import { lazyPlugin } from '@/hooks/lazyLoad'
/*国际化配置*/
import { Lang } from '@/I18N/en/studio/index'
import { zhLang } from '@/I18N/zh/studio/index'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
/*svg 插件需要配置代码*/
// import 'virtual:svg-icons-register'

const app = createApp(App)

const i18n = createI18n({
  locale: import.meta.env.VITE_LANG === 'zh' ? 'zh' : 'en',
  numberFormats: {
    en: {
      currency: {
        style: 'currency',
        currency: 'USD'
      }
    },
    zh: {
      currency: {
        style: 'currency',
        currency: 'JPY'
      }
    }
  },
  legacy: false,
  globalInjection: true,
  messages: {
    en: { ...Lang },
    zh: zhLang
  }
})

app.use(VueAwesomeSwiper)
app.use(pinia)
app.use(router)
app.use(gloalComponent)
app.use(lazyPlugin)
app.use(i18n as any)
app.mount('#app')
