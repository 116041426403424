import request from '@/utils/request'
const formatParam = function (params:any) {
    return {
        params: params
    }
};
export const requestList = () => (request as any).get('/designDashBoard/list')
export const requestFindLike = () => (request as any).get('/designDashBoard/findLike')
export const requestLike = (params) => (request as any).post('/designDashBoard/manage/like',params)
export const requestSearchComments = (params) => (request as any).post('/designDashBoard/searchComments',params)
export const requestComment = (params) => (request as any).post('/designDashBoard/manage/comment',params)

export const requestMyPublishPage = (params) => (request as any).get('/aiGenerateRecord/manage/publishPage', formatParam(params))
export const requestPublishPage = (params) => (request as any).get('/aiGenerateRecord/publishPage', formatParam(params))
export const getPublishDetail = (params) => (request as any).get('/aiGenerateRecord/getPublishDetail', formatParam(params))
export const getPublishInfo = (params) => (request as any).get('/aiGenerateRecord/getPublishDetail', formatParam(params))
export const viewCountReq = (params) => (request as any).post('/aiGenerateRecord/viewCount', params)
export const likeCountReq = (params) => (request as any).post('/aiGenerateRecord/collectOrCancel', params)
export const publish = (params) => (request as any).post('/aiGenerateRecord/manage/publish', params)
export const historyPage = (params) => (request as any).get('/aiGenerateRecord/manage/historyPage', formatParam(params))
export const commentPage = (params) => (request as any).get('/aiGenerateRecord/commentPage', formatParam(params))
export const addComment = (params) => (request as any).post('/aiGenerateRecord/manage/addComment', params)
export const chatpage = (params) => (request as any).get('/chat/record/page', formatParam(params))
export const sendByGpt4 = (params) => (request as any).post('/collovAi/sendAssisantMsg', params)
export const sendAssisantMsg = (params) => (request as any).post('/collovAi/sendAssisantMsg', params)
export const getChatRecord = (params) => (request as any).get('/collovAi/getChatRecord', formatParam(params))
export const getEnterChatRecord = (params) => (request as any).get('/enterpriseApi/chat/getChatRecord', formatParam(params))
export const searchMineAiGenerateRecord = (params) => (request as any).get('/aiGenerateRecord/searchMineAiGenerateRecord', formatParam(params))
export const deleteDesign = (params) => (request as any).post('/aiGenerateRecord/delete', params)
export const duplicateDesign = (params) => (request as any).post('/aiGenerateRecord/manage/duplicate',params)
export const deleteRecord = (params) => (request as any).post('/aiGenerateRecord/manage/deleteRecord',params)
export const listRooms = (params) => (request as any).get('/chatDesigner/listRooms', formatParam(params))
export const updateRoom = (params) => (request as any).post('/chatDesigner/updateRoom',params)
export const deleteRoom = (params) => (request as any).post('/chatDesigner/deleteRoom',params)
export const reqGenerate = (params) => (request as any).post('/collovAi/manage/genVideo', params)
export const getVideo = (params) => (request as any).get('/collovAi/manage/getVideo', formatParam(params))
export const addViewCountsReq = (params) => (request as any).post('/aiGenerateRecord/addViewCounts', params)

//history
export const requestGetPlan = () => (request as any).get('/payment/manage/getPlan')
export const searchCreditsRecords = (params) => (request as any).post('/clientMember/manage/searchCreditsRecords', params)
export const searchBillRecords = (params) => (request as any).post('/clientMember/manage/searchBillRecords', params)
export const cancelSubscription = (params) => (request as any).post('/payment/manage/cancelSubscription', params)
export const cancelAccount = (params) => (request as any).post('/client/manage/cancelAccount', params)

// assistant
export const reqAssistantTools = (params) => (request as any).get('/assistant/getTools', params)
export const reqAssistantSave = (params) => (request as any).post('/assistant/save', {...params})
export const reqAsstantDetail = (params) => (request as any).get('/assistant/getAsstantDetail', formatParam(params))
export const reqUpdateToolRate = (params) => (request as any).post('/assistant/updateToolRate', {...params})
export const reqAssistantPage = (params) => (request as any).get('/assistant/page', formatParam(params))
export const reqDelAssistant = (params) => (request as any).post('/assistant/delete',params)
export const reqAssistantPublish = (params) => (request as any).post('/assistant/assistantPublish', {...params})
export const sendCusAssisantMsg = (params) => (request as any).post('/assistant/sendCusAssisantMsg', params)
export const getAassistantChatRecord = (params) => (request as any).get('/assistant/getChatRecord', formatParam(params))
export const getMineGoodsList = (params) => (request as any).get('/enterpriseGoods/manage/listMineGoods', formatParam(params))
export const deleteMineGoods = (params) => (request as any).post('/enterpriseGoods/manage/deleteGoods', params)
export const copyMineGoods = (params) => (request as any).post('/enterpriseGoods/manage/copyGoods', params)
export const getMineGoodsDetail = (params) => (request as any).get('/enterpriseGoods/getDetail', formatParam(params))
export const editMineGoods = (params) => (request as any).post('/enterpriseGoods/manage/saveForStudio', params)

// clinet
export const reqCurrClientDesignList = (params) => (request as any).get('/outerClient/record/manage/page', formatParam(params))
export const reqCurrClientProList = (params) => (request as any).get('/enterpriseGoods/design/manage/page', formatParam(params))
export const reqCurrClientList = (params) => (request as any).get('/team/manage/clientList', formatParam(params))
export const reqCurrClientInfo = (params) => (request as any).get('/enterprise/crm/manage/getDetail?id=' + params, )

export const reqGetAllStyleList = () => (request as any).get('/aiGenerateRecord/getAllStyleList' )
export const reqListStyles = (params) => (request as any).get('/clientProfileStyle/listStyles', formatParam(params))
export const reqSearchMine = (params) => (request as any).get('/designModel/manage/searchMine', formatParam(params))
export const reqSaveStyles = (params) => (request as any).post('/clientProfileStyle/manage/saveStyles', params)
export const reqGetShareApiKey = (params) => (request as any).get('/aiGenerateRecord/getShareApiKey',  formatParam(params))
export const reqDesignModelget = (params) => (request as any).get('/designModel/get',  formatParam(params))
export const reqDesignModelSearch = (params) => (request as any).get('/designModel/search',  formatParam(params))
export const reqSaveComment = (params) => (request as any).post('/designModel/manage/saveComment', params)
export const reqSearchComment = (params) => (request as any).get('/designModel/searchComment', formatParam(params))
export const reqDesignModelId = (params) => (request as any).get('/designModel/get', formatParam(params))
export const reqSearchAiGenerateRecord = (params) => (request as any).get('/aiGenerateRecord/searchAiGenerateRecord', formatParam(params))
export const reqClickShare = () => (request as any).post('/clientProfileStyle/manage/clickShare')

