export const pricing={
    title:`Plans & Pricing`,
    subTitle:['All pricing is in USD. You can change plans or cancel anytime.','        Each subscription plan is given a monthly credit amount that automatically resets at the start of each billing cycle, regardless of usage. These do not roll over to the next month.'],
    ContactSales:'Contact Sales',
    Subscribe:'Subscribe',
    Free:'Free',
    usage:{
        title:`There are several AI features available,<br/> each with varying capabilities and price points.`,
        rows:[
            {title:'Feature',weight:'Usage'},
            {title:'AI Design',weight:'1 credits per generation'},
            {title:'Partial Modification',weight:'2 credits'},
            {title:'Your Design Model',weight:'1 credits per generation'},
            // {title:'Creatives Generator',weight:'2 credits per generation'},
            {title:'Chat Designer',weight:'25 credits / New Chat'},
            {title:'Image Up-scale',weight:'0.5 credits'},
        ]
    },
    tabs: {
      PackageComparison: 'Package Comparison',
      CreditsUsage: 'Credits Usage',
    },
    packageComparison: {
        rows:[
          {first:'', free: 'Free', pro: 'Pro', team: 'Team', enterprise: 'Enterprise'},
          {first:'User', free: '1', pro: '1', team: 'Unlimited*', enterprise: 'Unlimited'},
          {first:'Credits', free: '5', pro: 'Unlimited*', team: 'Unlimited', enterprise: 'Unlimited'},
          {first:'SKU Management', free: 'Collov Default Vendor', pro: 'Collov Default Vendor', team: 'Upload Your Own SKU', enterprise: 'Upload Your Own SKU'},
          {first:'Team Management', free: '-', pro: '-', team: 'check', enterprise: 'check'},
          {first:'Project Management', free: '-', pro: '-', team: 'check', enterprise: 'check'},
          {first:'Website Integration', free: 'Hosted on ', pro: 'Hosted on ', team: 'Hosted on ', enterprise: 'Integrate to your website'},
          {first:'CRM System', free: '-', pro: '-', team: 'check', enterprise: 'check'},
          {first:'Instant Home Redesign Style', free: '8 Styles', pro: '100+ Styles', team: '100+ Styles', enterprise: '100+ Styles'},
          {first:'Real Time Design', free: '1', pro: 'Unlimited', team: 'Unlimited', enterprise: 'Unlimited'},
          {first:'Personalized Onboarding', free: '-', pro: '-', team: '2 Sessions', enterprise: 'check'},
          {first:'Customer Support', free: 'Email Support', pro: 'Email Support', team: '24/7 Email & Chat Support', enterprise: '24/7 Dedicated Success Manager & Technical Supports'},
          {first:'White Label', free: '-', pro: '-', team: '-', enterprise: 'check'},
          {first:'Reporting & Analytics', free: '-', pro: '-', team: 'check', enterprise: 'check'},
          {first:'Premium Styles/Room Types Training Services', free: '-', pro: '-', team: 'Unlimited*', enterprise: 'Unlimited*'},
        ]
    },
    plans:[
        {
            title: 'Free',
            title2: "free",
            // headerCta: "Try It Free",
            moduleText: "Access to basic AI design tools and a standard library of styles and materials.",
            price: 'Free',
            per:'',
            perks: [
                'Limited features available for your use.',
                '5 collov.ai credits'
            ],
            tip:'Ideal for anyone looking to design their home',
        },
        {
            title: 'Enterprise',
            title2: "enterprise",
            headerCta: "See Details",
            moduleText: "Each project is unique. Let’s discuss your use case and craft a plan together.",
            perks: [
                'API Calls',
                'Customized AI Learning & Training (Design Styles & Furniture)',
                'Generation of numerous design images in one click',
                'Technical support',
                '1-on-1 Customer Success Manager'
            ],
            tip:'Ideal for corporations that want to showcase AI features on their website or backend.',
        },
        {
            title: 'Team',
            title2: "team",
            headerCta: "Purchase Now",
            moduleText: "Includes all Pro features plus team collaboration tools for efficient workflow.",
            perks: [
                'All Pro features',
                'Team Management',
                'CRM System'
            ],
            billedmonthly:'$59 billed monthly',
            price: '$49',
            mo:'per user / mo',
            tip:'Ideal for corporations that want to showcase AI features on their website or backend.',
        }
    ],
    upgradePlans:[
        {
            title: 'collov.ai Pro',
            title2: "AI_DESIGN",
            headerCta: "Subscribe",
            moduleText: "Upgrade to Pro for access to our AI interior design tool and advanced features. Adjust your credits plan as needed to bring your vision to life.",
            price: '19.99',
            salePrice: '19.99',
            per:'per month',
            perks: [
                {
                    title: 'All features available for your use.',
                    children: ['Up-scale to high resolution', 'Fast generation speed']
                },
                'No watermark',
                'Keep uploaded and generated images private'
            ],
            tip:'Ideal for interior designers, realtors, furniture vendors, and related professionals in the field of home industry.'
        }
    ],
    upgrade:{
        tip:'There are several AI features available, each with varying capabilities and price points.',
        ContactSales:'Contact Sales',
        Downgrade:'Downgrade',
        Subscribe:'Subscribe',
        permonth:'per month',
        credits:'credits',
    },

}
