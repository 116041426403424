import request from '@/utils/request'
import type { imagebase64Form } from './types'

enum API {
  INITDATA_URL = '/common/initData',
  GETIMAGEBASE64_URL = '/common/getImageBase64'
}
const formatParam = function (params:any) {
  return {
      params: params
  }
};
export const initData = () => request.get(API.INITDATA_URL)
export const getImageBase64 = (data: imagebase64Form) => request.get(API.GETIMAGEBASE64_URL, { params: data })
export const requestUploadBase64 = (params,api?:string) => request.post(api||'/file/uploadBase64', params)
export const requestUpload = (params,api?:string) => (request as any).upload(api||'/file/upload', params)
export const requestRemoveBg = (params,api?:string) => (request as any).post(api||'/removeBg/manage/create', params)
export const requestSvg2png = (svgText) => (request as any).post('/file/uploadImgSvg2Png', {svgText})
export const requestGetFloorplanJson = (parmas) => (request as any).post('/floorplan/getFloorplanJson', parmas)
export const requestGetDashBoardTool = () => (request as any).get('/common/getDashBoardTool')
export const requestFindRealtimeStyles = () => (request as any).get('/common/findRealtimeStyles')
export const getPhoneImg = (params) => (request as any).get('/common/getPhoneImg', formatParam(params))
export const savePhoneImg = (params) => (request as any).post('/common/savePhoneImg', params)
export const saveLandingPage = (params) => (request as any).post('/common/saveLandingPage', params)
