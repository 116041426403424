<template>
  <div class="component-login-wx" ref="code" style="">
    <img :src="codeUrl" alt="">
    <div class="tip">使用微信扫码登录</div>
  </div>
</template>
<script setup lang="ts">
import QRCode from "qrcode"; //引入生成二维码插件
import {clientStore} from "@/stores/client";
import {nextTick, onUnmounted, ref} from "vue";
import {hasLogin} from "@/apis/client";

const codeUrl = ref('')
const code = ref('')
const client = clientStore()
let timer = null
const getHasLogin = async () => {
  const res = await hasLogin()
  const status = res.data
  if (status) {
    clearTimeout(timer)
    await client.getInfo()
    await client.getMember()
    client.loginVisible = false
  } else {
    timer = setTimeout(() => {
      getHasLogin()
    }, 3000)
  }
}
onUnmounted(()=>{
  clearTimeout(timer)
})
const getQRcode = (url) => {
  const w =  300
  QRCode.toDataURL(url, {errorCorrectionLevel: 'L', margin: 2, width: w}, (err, url) => {
    if (err) throw err
    codeUrl.value = url
    getHasLogin()
  })
}
nextTick(() => {
  const routeUrl = location.origin + '/studio/wx/login?sessionId=' + client.token
  getQRcode(routeUrl)
})
</script>
<style scoped lang="less">
.component-login-wx {
  width: 456px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 768px){
    &{
      width: 100%;
    }
  }
  .tip {
    font-size: 12px;
    line-height: 18px;
    color: #aaaeb2;
    margin-top: 24px;
  }
}
</style>
