import * as designApi from '@/apis/design'
import * as clientApi from '@/apis/client'
import { clientStore, type ALLInOneMode } from '@/stores/client'
import { useEventBus } from '@vueuse/core'
import { Ref } from 'vue'
import { listIntegrateSaveTrainStyles } from '@/apis/client'
const designFormStyleBus = useEventBus('designFormStyleBus')
export const setAllInOneMode = () => {
  const client = clientStore()
  if (client.allInOneMode) {
    return
  }
  const url = window.location.href
  const params = new URLSearchParams(new URL(url).search)
  client.allInOneMode = (params.get('mode') || 'CLIENT_SIDE') as ALLInOneMode
  client.modelPreviwUsername = params.get('username') || ''
  console.log('client.allInOneMode', client.allInOneMode)
}

const getModelPreviewData = () => {
  const client = clientStore()
  return new Promise((resolve, reject) => {
    if (client.modelPreviewData) {
      resolve(client.modelPreviewData)
      return
    }
    clientApi
      .listSaveTrainStyles({
        username: client.modelPreviwUsername
      })
      .then((res: any) => {
        const data = res.data || { cabinet: [], wall: [], floor: [], home_redesign: [], partial_remodel: [] }
        client.modelPreviewData = data
        resolve(data)
      })
      .catch((err) => reject(err))
  })
}
const getIntegrateModelPreviewData = () => {
  const client = clientStore()
  return new Promise((resolve, reject) => {
    if (client.modelPreviewData) {
      resolve(client.modelPreviewData)
      return
    }
    clientApi
      .listIntegrateSaveTrainStyles({
        queryKey: client.modelPreviwUsername
      })
      .then((res: any) => {
        const data = res.data || { cabinet: [], wall: [], floor: [], home_redesign: [], partial_remodel: [] }
        client.modelPreviewData = data
        resolve(data)
      })
      .catch((err) => reject(err))
  })
}

export const resetDesignStyle = (formOptions: any) => {
  const client = clientStore()
  if ((formOptions.designType || formOptions.styleType) && client.allInOneMode === 'MODEL_PREVIEW') {
    getModelPreviewData().then((data: any) => {
      let styles = []
      if (formOptions.designType === 'WholeRoomRedesign') {
        styles = data.home_redesign
      }
      if (formOptions.designType === 'PartialRemodel') {
        styles = data.partial_remodel
      }
      designFormStyleBus.emit(styles)
    })
  }
}

const transformDataStruct = (data: any, type: string) => {
  console.log('data :>> ', data)
  console.log('type :>> ', type)

  const isArr = Array.isArray(data[type])
  if (type === 'cabinet') {
    if (!data[type] || !isArr) {
      return []
    }
    return data[type]?.map((item: any) => {
      return {
        ...item
      }
    })
  }
  if (type === 'floor' || type === 'wall') {
    if (!data[type] || !isArr) {
      return {
        rows: []
      }
    }
    return {
      rows: data[type]?.map((item: any) => {
        return {
          ...item
        }
      })
    }
  }
  if (type === 'partial_remodel' || type === 'home_redesign') {
    if (!data[type] || !isArr) {
      return {
        rows: []
      }
    }
    return data[type]?.map((item: any) => {
      return {
        ...item
      }
    })
  }
}

const getModelPreviewStyles = (type: string = '', integrate?: boolean) => {
  return new Promise((resolve) => {
    console.log('integrate: ', integrate)
    if (integrate) {
      getIntegrateModelPreviewData().then((data) => {
        resolve({
          success: true,
          data: transformDataStruct(data, type),
          message: '',
          statusCode: 0
        })
      })
    } else {
      getModelPreviewData().then((data) => {
        resolve({
          success: true,
          data: transformDataStruct(data, type),
          message: '',
          statusCode: 0
        })
      })
    }
  })
}

const resolveSearchResult = (list: any[], keyword = '', key = 'name') => {
  if (!keyword) {
    return list
  }
  const reg = new RegExp(keyword, 'gi')
  return list.filter((item: any) => {
    return reg.test(item[key] && item[key])
  })
}

const cloneRes = (res: any) => JSON.parse(JSON.stringify(res))

let cabinetRes: any = null
export const requestListKitchenStyles = async (params: any, integrate?: boolean) => {
  const client = clientStore()
  if (!cabinetRes) {
    if (client.allInOneMode === 'MODEL_PREVIEW') {
      cabinetRes = await getModelPreviewStyles('cabinet')
    } else if (integrate) {
      cabinetRes = await getModelPreviewStyles('cabinet', integrate)
    } else {
      cabinetRes = await designApi.requestListKitchenStyles(params)
    }
    cabinetRes.data.forEach((item: any) => {
      item.style = getCabinetName(client.lang, item.style)
      item.styleName = getCabinetName(client.lang, item.styleName)
    })
  }
  const tempRes = cloneRes(cabinetRes)
  tempRes.data = resolveSearchResult(tempRes.data, params.queryKey, 'style')
  return tempRes
}

const wallFloorResCache: any = {
  floor: null,
  wall: null
}
export const reqWallFloors = async (params: any) => {
  const type = params.category
  const client = clientStore()
  if (!wallFloorResCache[type]) {
    let res = null
    if (client.allInOneMode === 'MODEL_PREVIEW') {
      res = await getModelPreviewStyles(type)
    } else if (import.meta.env.VITE_CONFIG_TYPE === 'integrate') {
      res = await getModelPreviewStyles(type, true)
    } else {
      res = await designApi.reqWallFloors(params)
    }
    res.data.rows = res.data.rows.map((item: any) => {
      return {
        ...item,
        name: item.goods ? item.goods.name : ''
      }
    })
    wallFloorResCache[type] = res
  }
  const tempRes = cloneRes(wallFloorResCache[type])
  tempRes.data.rows = resolveSearchResult(tempRes.data.rows, params.queryKey)
  return tempRes
}

let furnitureRes: any = null
let productBase64 = ''
export const requestListFurnitureForNewAi = async (params: any) => {
  if (productBase64 !== params.base64 || !furnitureRes) {
    furnitureRes = await designApi.requestListFurnitureForNewAi(params)
    productBase64 = params.base64
  }
  const tempRes = cloneRes(furnitureRes)
  tempRes.list = resolveSearchResult(furnitureRes.list, params.queryKey)
  return tempRes
}

const roomTypeStyleResCache: any = {
  'living room': null,
  bedroom: null
}
export const getStyleListByGenerationType = async (params: any) => {
  const type = params.roomType
  if (!roomTypeStyleResCache[type]) {
    roomTypeStyleResCache[type] = await designApi.getStyleListByGenerationType(params)
  }
  const tempRes = cloneRes(roomTypeStyleResCache[type])
  tempRes.data = resolveSearchResult(tempRes.data, params.queryKey, 'styleName')
  return tempRes
}

const kitchRemodel: Record<string, string> = {
  '峰系350·JM-PE100·星曜银': 'Peak Series - Star Silver',
  '峰系365·JM-KQ102·(原亚丁3)·驼灰': 'Peak Series - Camel Gray',
  '峰系365·JM-KQ102·(原亚丁3)·亚灰': 'Peak Series - Ash Gray',
  '峰系365·JM-KQ300·(原阿尔卑斯2)·驼灰': 'Peak Series - Camel Gray',
  '峰系365·JM-KQ300·(原阿尔卑斯2)·亚灰': 'Peak Series - Ash Gray',
  '花系020·JM-MN100·纯白': 'Flower Series - Pure White',
  '峰系340·JM-MY100·丁灰橡': 'Peak Series - Ding Grey Oak',
  '花系020·JM-MN100·银丝': 'Flower Series - Silver Thread',
  '花系020·JM-MN100C·皂叶灰': 'Flower Series - Soap Leaf Gray',
  '峰系365·JM-KQ100C·雪白': 'Peak Series - Snow White',
  '峰系365·JM-KQ100·（原第五大道）·钛白': 'Peak Series - (Original Fifth Avenue)·Titanium White',
  '峰系365·JM-KQ100C·摩卡': 'Peak Series - Mocha',
  '峰系365·JM-KQ100C·雾灰': 'Peak Series - Mist Gray',
  '峰系365·JM-KQ100C·烟灰': 'Peak Series - Smoke Gray',
  '花系020·JM-MN100·清影橡': 'Flower Series - Clear Shadow Oak',
  '木系140·JM-MY300·（原苏黎世1S）·暗香樱桃': 'Wood Series - (Original Zurich 1S)·Dark Cherry',
  '峰系365·JM-KQ100C·丁香': 'Peak Series - Clove',
  '峰系340·JM-MY302·（原欧罗拉2）·古白': 'Peak Series - (Original Aurora 2)·Ancient White',
  '花系020-豆蔻绿': 'Flower Series - Cardamom Green',
  '木系120-荞麦灰': 'Wood Series - Buckwheat Gray',
  '木系140·JM-MY302-云白': 'Wood Series - Cloud White',
  '峰系350-金丝缕': 'Peak Series - Golden Thread',
  '云系530-云墨灰': 'Cloud Series - Cloud Ink Gray',
  '花系020-浅色胡桃': 'Flower Series - Light Walnut',
  '峰系340·JM-MY306-仿古红樱桃': 'Peak Series - Antique Red Cherry',
  '峰系360-纳米泥灰': 'Peak Series - Nano Mud Gray'
}
const zhRemodel: Record<string, string> = {
  'Traditional American': '传统美式',
  'Black & Walnut': '黑胡桃木',
  Black: '黑色',
  'Black & White': '黑白色',
  White: '白色',
  'White & Wood': '白木色'
}

export function getCabinetName(lang: string, name: string) {
  if (lang === 'en' && kitchRemodel[name]) {
    return kitchRemodel[name]
  } else if (lang === 'zh' && zhRemodel[name]) {
    return zhRemodel[name]
  }
  return name
}
