let key:string='default'
const recordObj={}
const maxLen=20
export const createRecord=(id?:string)=>{
    if(id){
        key=id
    }
    /*添加数据*/
    function addData(data) {
        for (let i in data){
            this.data[i] = data[i]
        }
    }
    /*保存记录*/
    function save(data,call) {
        const state = {
            data:JSON.parse(JSON.stringify(this.data)),
            call
        }
        this.addData(data)
        this.undoList.push(state)
        return {
            data:null,
            undo:this.undoList.length,
            redo:this.redoList.length
        }
    }
    /* undo*/
    function undo() {
        if(!this.undoList.length){
            return {
                data:null,
                undo:this.undoList.length,
                redo:this.redoList.length
            }
        }
        const pop = this.undoList.pop()
        const state = {
            data:JSON.parse(JSON.stringify(this.data)),
            call:pop.call
        }
        this.addData(pop.data)
        this.redoList.push(state)
        if(this.undoList.length>maxLen){
            this.undoList.shift()
        }
        const result={
            data:pop.data,
            undo:this.undoList.length,
            redo:this.redoList.length
        }
        pop.call&&pop.call(result)
        return result
    }
    /* redo*/
    function redo() {
        if(!this.redoList.length){
            return {
                data:null,
                undo:this.undoList.length,
                redo:this.redoList.length
            }
        }
        const pop = this.redoList.pop()
        const state = {
            data:JSON.parse(JSON.stringify(this.data)),
            call:pop.call
        }
        this.undoList.push(state)
        this.addData(pop.data)
        if(this.redoList.length>maxLen){
            this.redoList.shift()
        }
        const result={
            data:pop.data,
            undo:this.undoList.length,
            redo:this.redoList.length
        }
        pop.call&&pop.call(result)
        return result
    }

    recordObj[key]={
        data:{},
        redoList:[],
        undoList:[],
        addData,
        undo,
        save,
        redo,
    }
    return recordObj[key]
}
export const userRecord=(id?:string)=>{
    if(id){
        key=id
    }
    return recordObj[key]
}
