export default {
  Banner: {
    title: 'AI Home Redesign',
    subtitle: 'Ignite Home Design Inspiration with AI',
    info: 'Collov AI transforms your unique styles into digital assets, enabling dynamic and personalized interior design in real time.',
    btn1: 'Join Now',
    btn2: 'Try Demo'
  },

  Tools: [
    {
      title: 'Instant Creation',
      info: 'Transform rooms and spaces in seconds'
    },
    {
      title: 'Lifelike Precision',
      info: 'See your vision in vivid detail'
    },
    {
      title: 'Personalized Style',
      info: 'Designs that match your taste perfectly'
    }
  ],
  Unit1: {
    title: 'Create Your Digital Assets at Scale',
    info: `Collov AI's proprietary AI models understand unique design styles by learning your projects, transforming styles into unified digital assets.`,
    icons: ['Customizable for your brand', 'Unified assets across all devices', 'Centralized controls'],
    imgs: ['Bohemian', 'Coastal', 'Farmhouse', 'Eclectic', 'Scandinavian', 'Modern']
  },
  Unit2: {
    title: 'Diverse Spaces, Diverse Styles',
    info: `From empty canvases to furnished finishes, our extensive room and style library caters to every scenario, unleashing endless possibilities.`
  },
  Unit3: {
    title: 'Instant Ultra-Realistic Visuals',
    info: `Collov AI detects, adapts, and visualizes preferred styles and products in customers’ space in seconds, bringing design dreams to vivid life with stunning clarity and precision.`
  },
  Unit4: {
    title: 'Compare and Contrast',
    info: `Effortlessly view before-and-after scenes or compare various design options side-by-side, empowering informed decisions through clear, visual transformation.`
  },
  Unit5: {
    title: 'Iterate Your Designs with Powerful Tools',
    info: `Seamlessly integrates with Collov’s All-in-One AI design platform, select real cabinets, flooring, and walls from leading vendors, bridging the gap between virtual creativity and real-world applications.`
  },
  PickPlan: {
    title: 'Pick the best plan for you',
    item1: {
      title: 'Home Redesign',
      info: '10-minute setup with a code-free template.',
      mo: ' / mo ',
      currency: '$',
      price: '250',
      ps: '(Billed Annually)',
      btn: 'Get Started'
    },
    item2: {
      title: 'AI Desk',
      info: 'Unlock Full Power with Collov AI Software Plans Above.',
      mo: '',
      currency: '$',
      price: '2,500',
      ps: '',
      btn: 'Get Started'
    }
  }
}
