export const realtime={
    action:{
        fixedBg:'固定背景',
        clear:'清除',
        add:'添加',
        change:'修改',
        select:'选择',
        enhance:'增强效果',
        tools:'工具',
        control:'控制',
        freeStyle:'自由发挥',
        backgroundColor:'背景颜色',
        controlStrength:'控制强度',
        aiStrength:'ai强度',
        roomType:'房间类型',
        autoFit:'自动',
        outputToInput:'转为输入图',
        uploadBg:'上传背景图',
        SaveSetting:'保存设置',
        theme:"主题色",
        GenerateVideo:"生成视频",
        GenerateGIF:"生成GIF",
        beforeAfter:"前后对比",
        download:"下载生成图",
    },
    title:{
        wantMerge:'是否确实要将对象与背景合并？',
        merge:'合并',
        cancel:'取消',
        realtime:'实时',
        freezed:'冻结',
        draw:'绘画',
        inpaint:'部分修改',
        createByUser:'用户风格',
        liveUpdates:'最新',
        topOfDay:'每日榜单',
        topOfWeek:'每周榜单',
        allTimesLeadersShip:'总榜单',
        Official:'官方风格',

    },
    category:{
        Lighting:'光线',
        Light:'光线',
        Interior:'室内',
        Architect:'建筑',
        Floorplan:'平面',
        'Costume Design':'人物',
        'Footwear Design':'鞋履',
        'Wood Design':'木制',
        'Fashion Design':'时尚',
        'Package Design':'包装',
        'Character Design':'角色',
        'Manga':'漫画',
        'Webcam':'摄像',
    }


}
