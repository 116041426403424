interface Style {
  name: string
  value: string
  url: string
  locked: boolean
  category: string
}

interface ModeStyle {
  name: string
  value: string
  url: string
  locked: boolean
  category: string
  requestId: string
  color: string
  productType: string
  material: string
  finish: string
}
export const styleNavs = [
  { name: 'background', key: 'background', includes: ['GEN_BGIMAGE'] },
  { name: 'text', key: 'prompt', includes: ['GEN_BGIMAGE'] },
  {
    name: 'Official Style',
    key: 'style',
    includes: ['redesign', 'partial', 'fastPreserved', 'GEN_BGIMAGE', 'WALL_FLOOR_CEILING', 'ALL_IN_ONE']
  },
  { name: 'Upload Reference', key: 'styleImage', includes: ['redesign', 'ALL_IN_ONE'] },
  { name: 'Text Prompt', key: 'prompt', includes: ['redesign', 'partial', 'ALL_IN_ONE'] },
  { name: 'Style', key: 'style', includes: ['kitchenRemodeling'] }
]
export const styleModeList: ModeStyle[] = [
  {
    name: 'wood1',
    value: 'wood1',
    url: 'https://d17axom7zrjq3q.cloudfront.net/AI_GENERATE/20240115/e0df6574-deca-4105-b5eb-ec49069f2f0e.jpg',
    locked: false,
    requestId: 'fb628471-ffc0-405a-8cc0-88257be4dc40',
    category: 'Floor',
    color: 'light natural wood color',
    productType: 'Flooring',
    material: 'Wood (appears to be a light hardwood like oak or maple)',
    finish: 'Satin (some shine, but not glossy)'
  },
  {
    name: 'wood2',
    value: 'wood2',
    url: 'https://d17axom7zrjq3q.cloudfront.net/AI_GENERATE/20240115/abd15124-fdc2-4f95-8dff-71a3554c942d.jpg',
    locked: false,
    requestId: '5258f5f6-2db3-4d16-9aa9-81294cc196a1',
    category: 'Floor',
    color: 'gray wood color',
    productType: 'Flooring',
    material: 'Wood (could be a stained hardwood)',
    finish: 'Matte (no significant shine)'
  },
  {
    name: 'wood3',
    value: 'wood3',
    url: 'https://d17axom7zrjq3q.cloudfront.net/AI_GENERATE/20240115/0071b4ac-7f93-4ea4-80db-a34152878ab1.jpg',
    locked: false,
    requestId: 'f0a92f24-996e-4223-8916-8c6079a2136a',
    category: 'Floor',
    color: 'Natural beige wood color',
    productType: 'Flooring',
    material: 'Wood (likely oak or similar)',
    finish: 'Matte or satin'
  },
  {
    name: 'wood4',
    value: 'wood4',
    url: 'https://d17axom7zrjq3q.cloudfront.net/AI_GENERATE/20240115/72be76c6-e4a2-4e54-9aba-edca02e2569e.jpg',
    locked: false,
    requestId: 'b2047db5-b33e-4e9c-91fe-509c16f6d0e8',
    category: 'Floor',
    color: 'Light gray wood color',
    productType: 'Flooring',
    material: 'Wood (looks like a hardwood with a gray stain)',
    finish: 'Matte or satin'
  },
  {
    name: 'Brume',
    value: 'Brume',
    url: 'https://d17axom7zrjq3q.cloudfront.net/AI_GENERATE/20240115/e1b5620c-4e2c-4135-9a54-b34d111115ab.jpg',
    locked: false,
    requestId: 'a728966a-a112-4696-a298-b48c361324b0',
    category: 'Wall',
    color: 'Light taupe or beige',
    productType: 'Wall Paint',
    material: 'Paint',
    finish: 'Matte (no shine, smooth finish)'
  },
  {
    name: 'Indaco',
    value: 'Indaco',
    url: 'https://d17axom7zrjq3q.cloudfront.net/AI_GENERATE/20240115/8c40349d-f7fb-41d5-9262-489d564da82b.jpg',
    locked: false,
    requestId: '8f7eed75-c7f2-48fe-b7d0-3553826d3cf0',
    category: 'Wall',
    color: 'Dusty blue',
    productType: 'Wall Paint',
    material: 'Paint',
    finish: 'Matte (smooth surface, no reflection)'
  },
  {
    name: 'Rubino',
    value: 'Rubino',
    url: 'https://d17axom7zrjq3q.cloudfront.net/AI_GENERATE/20240115/aad441b6-fd2a-41c2-be44-75550b669c95.jpg',
    locked: false,
    requestId: '076d5f6c-701b-41ab-941f-a4370983cc79',
    category: 'Wall',
    color: 'Terracotta or reddish-brown',
    productType: 'Wall Paint',
    material: 'Paint',
    finish: 'Matte (flat appearance, no gloss)'
  }
]

export const styleList: Style[] = [
  {
    name: 'christmas',
    value: 'christmas',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/christmas.jpg',
    locked: false,
    category: 'Festival'
  },
  {
    name: 'halloween',
    value: 'halloween',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/halloween.jpg',
    locked: false,
    category: 'Festival'
  },
  {
    name: 'Bohemian',
    value: 'bohemian',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/bohemian.jpg',
    locked: false,
    category: 'Classics'
  },
  {
    name: 'Coastal',
    value: 'coastal',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/coastal.jpg',
    locked: false,
    category: 'Classics'
  },
  {
    name: 'Farmhouse',
    value: 'farmhouse',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/farmhouse.jpg',
    locked: false,
    category: 'Classics'
  },
  {
    name: 'Eclectic',
    value: 'eclectic',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/eclectic.jpg',
    locked: false,
    category: 'Classics'
  },
  {
    name: 'Scandinavian',
    value: 'scandinavian',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/scandinavian.jpg',
    locked: false,
    category: 'Classics'
  },
  {
    name: 'Modern',
    value: 'modern',
    url: 'https://d17axom7zrjq3q.cloudfront.net/20200325/8f117606f5d148708b28122acf7698e9.jpg',
    locked: true,
    category: 'Classics'
  },
  {
    name: 'Transitional',
    value: 'transitional',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/transitional.jpg',
    locked: true,
    category: 'Classics'
  },
  {
    name: 'Mid-century',
    value: 'mid-century',
    url: 'https://d17axom7zrjq3q.cloudfront.net/20200324/dd71697a0f464a8f819c9e80c53baff3.jpg',
    locked: true,
    category: 'Classics'
  },
  {
    name: 'new chinese',
    value: 'new chinese',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/new chinese.jpg',
    locked: false,
    category: 'International'
  },
  {
    name: 'french',
    value: 'french',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/french.jpg',
    locked: false,
    category: 'International'
  },
  {
    name: 'light luxury',
    value: 'light luxury',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/light luxury.jpg',
    locked: false,
    category: 'International'
  },
  {
    name: 'wabi-sabi',
    value: 'wabi-sabi',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/wabi-sabi.jpg',
    locked: false,
    category: 'International'
  },
  {
    name: 'minimalist',
    value: 'minimalist',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/minimalist.jpg',
    locked: false,
    category: 'Modern'
  },
  {
    name: 'natural wood',
    value: 'natural wood',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/natural wood.jpg',
    locked: false,
    category: 'Modern'
  },
  {
    name: 'creamy',
    value: 'creamy',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/creamy.jpg',
    locked: false,
    category: 'International'
  },
  {
    name: 'modern minimalist',
    value: 'modern minimalist',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/modern minimalist.jpg',
    locked: false,
    category: 'Modern'
  },
  {
    name: 'nordic',
    value: 'nordic',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/nordic.jpg',
    locked: false,
    category: 'Scandivian'
  },
  {
    name: 'contemporary',
    value: 'contemporary',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/contemporary.jpg',
    locked: false,
    category: 'Modern'
  },
  {
    name: 'industrial',
    value: 'industrial',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/industrial.jpg',
    locked: false,
    category: 'Industrial'
  },
  {
    name: 'country',
    value: 'country',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/country.jpg',
    locked: false,
    category: 'Farmhouse'
  },
  {
    name: 'rustic',
    value: 'rustic',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/rustic.jpg',
    locked: false,
    category: 'Farmhouse'
  },
  {
    name: 'minimalism',
    value: 'minimalism',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/minimalism.jpg',
    locked: false,
    category: 'Modern'
  },
  {
    name: 'chinese',
    value: 'chinese',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/chinese.jpg',
    locked: false,
    category: 'International'
  },
  {
    name: 'urban chinese',
    value: 'urban chinese',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/urban chinese.jpg',
    locked: false,
    category: 'International'
  },
  {
    name: 'retro',
    value: 'retro',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/retro.jpg',
    locked: false,
    category: 'Retro'
  },
  {
    name: 'vintage',
    value: 'vintage',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/vintage.jpg',
    locked: false,
    category: 'Retro'
  },
  {
    name: 'japanese',
    value: 'japanese',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/japanese.jpg',
    locked: false,
    category: 'International'
  },
  {
    name: 'neoclassical',
    value: 'neoclassical',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/neoclassical.jpg',
    locked: false,
    category: 'Historical'
  },
  {
    name: 'american country',
    value: 'american country',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/american country.jpg',
    locked: false,
    category: 'Farmhouse'
  },
  {
    name: 'soft',
    value: 'soft',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/soft.jpg',
    locked: false,
    category: 'Modern'
  },
  {
    name: 'art deco',
    value: 'art deco',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/art deco.jpg',
    locked: false,
    category: 'Glam'
  },
  {
    name: 'hi-tech',
    value: 'hi-tech',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/hi-tech.jpg',
    locked: false,
    category: 'Gaming'
  },
  {
    name: 'italian',
    value: 'italian',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style/italian.jpg',
    locked: false,
    category: 'International'
  },
  {
    name: 'mid-century modern',
    value: 'mid-century modern',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/mid-century modern.jpg',
    locked: false,
    category: 'Mid-Century'
  },
  {
    name: 'shabby chic',
    value: 'shabby chic',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/shabby chic.jpg',
    locked: false,
    category: 'Historical'
  },
  {
    name: 'japandi',
    value: 'japandi',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/japandi.jpg',
    locked: false,
    category: 'International'
  },
  {
    name: 'colonial',
    value: 'colonial',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/colonial.jpg',
    locked: false,
    category: 'Historical'
  },
  {
    name: 'hollywood regency',
    value: 'hollywood regency',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/hollywood regency.jpg',
    locked: false,
    category: 'Glam'
  },
  {
    name: 'regency moderne',
    value: 'regency moderne',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/regency moderne.jpg',
    locked: false,
    category: 'Glam'
  },
  {
    name: 'mediterranean revival',
    value: 'mediterranean revival',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/mediterranean revival.jpg',
    locked: false,
    category: 'International'
  },
  {
    name: 'rococo',
    value: 'rococo',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/rococo.jpg',
    locked: false,
    category: 'Historical'
  },
  {
    name: 'late baroque',
    value: 'late baroque',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/late baroque.jpg',
    locked: false,
    category: 'Historical'
  },
  {
    name: 'hollywood glam',
    value: 'hollywood glam',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/hollywood glam.jpg',
    locked: false,
    category: 'Glam'
  },
  {
    name: 'mediterranean',
    value: 'mediterranean',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/mediterranean.jpg',
    locked: false,
    category: 'International'
  },
  {
    name: 'southwestern',
    value: 'southwestern',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/southwestern.jpg',
    locked: false,
    category: 'Farmhouse'
  },
  {
    name: 'french country',
    value: 'french country',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/french country.jpg',
    locked: false,
    category: 'Farmhouse'
  },
  {
    name: 'asian zen',
    value: 'asian zen',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/asian zen.jpg',
    locked: false,
    category: 'International'
  },
  {
    name: 'modern farmhouse',
    value: 'modern farmhouse',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/modern farmhouse.jpg',
    locked: false,
    category: 'Farmhouse'
  },
  {
    name: 'african',
    value: 'african',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/african.jpg',
    locked: false,
    category: 'International'
  },
  {
    name: 'amish',
    value: 'amish',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/amish.jpg',
    locked: false,
    category: 'International'
  },
  {
    name: 'arabian',
    value: 'arabian',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/arabian.jpg',
    locked: false,
    category: 'International'
  },
  {
    name: 'art moderne',
    value: 'art moderne',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/art moderne.jpg',
    locked: false,
    category: 'Transitional'
  },
  {
    name: 'art nouveau',
    value: 'art nouveau',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/art nouveau.jpg',
    locked: false,
    category: 'Historical'
  },
  {
    name: 'artisan',
    value: 'artisan',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/artisan.jpg',
    locked: false,
    category: 'Farmhouse'
  },
  {
    name: 'arts & crafts',
    value: 'arts & crafts',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/arts & crafts.jpg',
    locked: false,
    category: 'Historical'
  },
  {
    name: 'baroque',
    value: 'baroque',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/baroque.jpg',
    locked: false,
    category: 'Historical'
  },
  {
    name: 'cuban',
    value: 'cuban',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/cuban.jpg',
    locked: false,
    category: 'International'
  },
  {
    name: 'gothic',
    value: 'gothic',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/gothic.jpg',
    locked: false,
    category: 'Historical'
  },
  {
    name: 'Moroccan',
    category: 'International',
    value: 'moroccan',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/moroccan.jpg',
    locked: false
  },
  {
    name: 'indian',
    category: 'International',
    value: 'indian',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/indian.jpg',
    locked: false
  },
  {
    name: 'tropical',
    value: 'tropical',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/tropical.jpg',
    locked: false,
    category: 'Tropical'
  },
  {
    name: 'biophilic',
    value: 'biophilic',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/biophilic.jpg',
    locked: false,
    category: 'Tropical'
  },
  {
    name: 'cottagecore',
    value: 'cottagecore',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/cottagecore.jpg',
    locked: false,
    category: 'Farmhouse'
  },
  {
    name: 'maximalist',
    value: 'maximalist',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/maximalist.jpg',
    locked: false,
    category: 'Eclectic'
  },
  {
    name: 'gaming',
    value: 'gaming',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/gaming.jpg',
    locked: false,
    category: 'Gaming'
  },
  {
    name: 'cyberpunk',
    value: 'cyberpunk',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/cyberpunk.jpg',
    locked: false,
    category: 'Gaming'
  },
  {
    name: 'vaporwave',
    value: 'vaporwave',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/vaporwave.jpg',
    locked: false,
    category: 'Gaming'
  },
  {
    name: 'ski chalet',
    value: 'ski chalet',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/ski chalet.jpg',
    locked: false,
    category: 'Farmhouse'
  },
  {
    name: 'tribal',
    category: 'International',
    value: 'tribal',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/tribal.jpg',
    locked: false
  },
  {
    name: 'medieval',
    value: 'medieval',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/medieval.jpg',
    locked: false,
    category: 'Historical'
  },
  {
    name: 'easter',
    value: 'easter',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/easter.jpg',
    locked: false,
    category: 'Festival'
  },
  {
    name: 'chinese new year',
    value: 'chinese new year',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/chinese new year.jpg',
    locked: false,
    category: 'Festival'
  },

  {
    name: 'hot pink',
    value: 'hot pink',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/hot pink.jpg',
    locked: false,
    category: 'Gaming'
  },
  {
    name: 'neoclassic',
    value: 'neoclassic',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/neoclassic.jpg',
    locked: false,
    category: 'Historical'
  },
  {
    name: 'victorian',
    value: 'victorian',
    url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/victorian.jpg',
    locked: false,
    category: 'Historical'
  }
]
