export const payment = {
  title: '选择你的设计方案',
  name: '让我们找一个最适合你的计划。',
  PackageDetail: '定价细节',
  FURNITURE_REPLACEMENT: {
    title: '家具替换',
    list: [
      '精准还原的家具模型:Collov AI从供应商获取一手数据，生成超逼真的数字复制品。',
      '简化设计到销售流程:将SKU数据转换为视觉资产，提高效率。',
      '易于集成:AI设计将真实家具毫不费力放置在您的方案中。'
    ]
  },
  WALL_FLOORING_REPLACEMENT: {
    title: '地板和墙壁',
    list: [
      '精美纹理:精准复制地板和墙壁真实材料质感。',
      '多用途设计:简化使用不同颜色材质的设计方案制作流程。',
      '易于定制:简化选择和可视化过程。'
    ]
  },
  AI_DESK: {
    title: '展厅里的AI智能设计设备',
    list: [
      '全面访问:允许访问使用Collov AI的全部功能。',
      '展厅创新:将AI融入展厅，实现实时随地可视化设计。',
      '互动体验:提供由AI驱动的自带流量密码的客户咨询互动。'
    ]
  },
  SubmitConsultationAppointment: '提交咨询预约',
  ContinueToCheckout: '去支付',
  imglist: [
    { title: '传统美式' },
    { title: '黑色和核桃' },
    { title: '黑色' },
    { title: '黑白配' },
    { title: '直板长椅' },
    { title: '六角边桌' },
    { title: '抽象极简' },
    { title: 'West elm x pbt' },
    { title: '木头1' },
    { title: '木头2' },
    { title: '木头3' },
    { title: '木头4' },
    { title: '红宝石' },
    { title: '蓝靛' },
    { title: '薄雾' },
    { title: '骆驼灰' },
    { title: '灰白' },
    { title: '骆驼灰' },
    { title: '星光银' },
  ]
}
