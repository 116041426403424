export const enterprise = {
  buttons: {
    learnMore: 'Learn More',
    successfulStory: 'Successful Story',
    contactNow: 'Contact Now'
  },
  landing: {
    cover: {
      // title: `Innovate. <br/>Illustrate. Deliver.`,
      title: `Innovate. Illustrate. Deliver.`,
      subTitle: 'Elevate Your Business For The Future of Design',
      name: `Leverage cutting-edge Generative AI technology to transform your unique design styles into digital assets, allowing for real-time, customizable design visualizations.`,
      contactNow: 'Contact Now',
      TryNow: 'Try Now',
      demoVideo: 'Demo Video',
      submit: 'Submit Business Interest',
      talk: 'Talk to Us'
    },
    boost: {
      title: 'Boost Your Business Today',
      list: ['Increase Revenue and Design Efficiency', 'Decrease In-house Design Cost', 'Improve Customer Engagement']
    },
    fastGen: {
      name: 'Lightning-Fast Generations',
      desc: 'Get instant design iterations with our rapid generation feature, accelerating your creative and collaborative process.',
      btn: 'Generate Now',
      SeeDetails: 'See Details'
    },
    realWorld: {
      name: 'Real-World Material Library',
      desc: 'An extensive selection of real materials directly sourced from vendors for flooring, walls, and painting, seamlessly linking home design with your shopping experience.',
      btn: 'Try Now',
      SeeDetails: 'See Details'
    },
    future: {
      title: 'The Future of Design'
    },
    digital: {
      title: 'Create Your Digital Assets at Scale',
      name: `Collov AI's proprietary AI models understand unique design styles by learning your projects, transforming styles into unified digital assets`,
      list: ['Customizable for your brand', 'Unified assets across all devices', 'Centralized controls'],
      products: [
        'Dreambooth wo..',
        'charcoal gray',
        'cloud white',
        'Smoke gray',
        'Mocha',
        'Meteor gray',
        'Interstellar silver',
        'Dawn gold',
        'Ash gray'
      ]
    },
    elevate: {
      title: 'Elevate Your Design in Seconds',
      name: `Leveraging your unique style library, Collov AI detects, adapts, and visualizes preferred styles and products in customers' space in seconds`,
      list: ['Structure preserved', 'High Quality 3D Rendering', 'Shop-able Furniture List']
    },
    mask: {
      title: 'Iterate Your Designs With A Few Clicks',
      name: `AI markup editor enables an interactive design experience and generates real-time design iterations with just a few clicks`,
      list: ['Partial Furniture Modification', 'Fexible Style Changes', 'Smart AI <br/>Markups']
    },
    leaders: {
      title: `Trusted by Industry Leaders`,
      // name:`Our AI-driven solutions are reshaping the design world, making us the top choice for premier real estate firms, interior designers, and furniture vendors globally. As pioneers in interior aesthetics, our innovations set the gold standard. Dive into a design experience that’s championed by the best in the business.`,
      name: 'Our AI-driven solutions are reshaping the design world, making us the top choice for premier design professionals, furniture vendors and real estate firms. Dive into a design experience that’s championed by the best in the business.'
    },
    clientSay: {
      title: `What Our Clients Say`,
      name: `Get Ready to Maximize Your Productivity With The Future of Design`,
      list: [
        {
          name: 'Isabella Turner',
          address: 'CEO, Furniture Industry',
          content:
            '“Collov AI has been a game-changer for our brand. By integrating their technology, we’ve positioned ourselves as innovators. Customers love the AI-driven design suggestions, which invariably lead them to our premium collections.”'
        },
        {
          name: 'Emily Peterson',
          address: 'Real Estate Developer, Real Estates Industry',
          content: `“Property showings have never been more efficient. With this AI soultion, our potential buyers get a glimpse of personalized interiors, leading to faster decision-making and more sales.”`
        },
        {
          name: 'Liam Howard',
          address: 'Sales Director, Kitchen Cabinet Industry',
          content: `“Our kitchen cabinet sales have shot up since we’ve started using Collov AI. Visualizing real-time designs tailored to clients’ preferences has certainly given us a competitive edge.”`
        },
        {
          name: 'Sophia Martinez',
          address: 'Digital Strategist, Online Furniture E-commerce',
          content: `“Collov AI’s feature of detecting and suggesting matches has reduced our cart abandonment rates. Our customers now feel confident in their choices, which has notably boosted our sales.”`
        },
        {
          name: 'Lucas Kim',
          address: 'Founder, Construction Industry',
          content: `“In the furniture world, staying trendy and tech-forward is a must. With Collov AI, we’ve managed to offer our customers instant inspirations that align with their tastes, making us their favorite design stop.”`
        },
        {
          name: 'Ava Chen',
          address: 'Property Staging Specialist, Real Estates Industry',
          content: `“We stage homes to sell, and Collov AI’s tool has minimized our effort. Personalized design generation has impressed our clients and buyers alike, resulting in rapid turnarounds.”`
        },
        {
          name: 'Ethan Ruiz',
          address: 'Marketing Manager, Commercial Design Industry',
          content: `“Our website traffic and engagement have seen a massive boost. By embedding Collov’s AI tool, not only do we offer unique designs, but we also effortlessly capture potential leads.”`
        },
        {
          name: 'Olivia Fischer',
          address: 'Chief Designer, Decor Company',
          content: `“As a designer, there are days when I need inspiration. Collov AI provides infinite ideas within seconds, making the process smoother and more efficient. It’s a designer’s dream!”`
        },
        {
          name: 'Noah Singh',
          address: 'COO, Tiles Industry',
          content: `“The precision selling feature of Collov AI is a revelation. Our sales team has more meaningful conversations with potential clients, leading to a significant increase in conversion rates.”`
        },
        {
          name: 'Michael Thompson, Creative Director',
          address: '',
          content: `“Collov AI Real Time has revolutionized our workflow. The real-time design feature has significantly reduced our project turnaround time, allowing for more efficient and creative outcomes. It's a must-have tool for any design professional.”`
        },
        {
          name: 'Sarah Kim, Freelance Graphic Designer',
          address: '',
          content: `“As a freelance graphic designer, Collov AI's real-time design capabilities have transformed the way I work. It's intuitive, fast, and incredibly reliable. This tool has become an indispensable part of my creative process.”`
        },
        {
          name: 'Alex Martinez, Senior Interior Designer',
          address: '',
          content: `“I've used various design tools in my career, but Collov AI stands out for its real-time design features. It offers unparalleled precision and speed, making complex design tasks simpler and more enjoyable.”`
        },
        {
          name: 'Emily Patel, Lead Product Designer',
          address: '',
          content: `“ Collov AI Real Time is a breakthrough in the design industry. Its real-time design interface is not only innovative but also extremely user-friendly, making it ideal for both beginners and experienced designers.”`
        },
        {
          name: 'David Liu, Project Manager',
          address: '',
          content: `“Our team has been using Collov AI for several projects, and the real-time design aspect has greatly enhanced our collaboration and creativity. It allows for immediate feedback and adjustments, which is invaluable in a fast-paced design environment.”`
        }
      ]
    }
  },
  dashboard: {
    title: 'AI Design Dashboard',
    cards: [
      { name: 'Number of photos generated', value: '', key: 'recordCount' },
      // {name: 'Unique User(s)', value: '3443'},
      // {name: 'New Unique User', value: '359'},
      { name: 'AvgTime(s)', value: '', key: 'avgTime', unit: 's' }
    ],
    tabs: ['Overall', 'Last Week', 'Last Month'],
    Product: 'Product',
    Add: 'Add',
    columns: [
      { title: 'RoomType', width: 100, dataIndex: 'roomType' },
      { title: 'Style', dataIndex: 'style', width: 150 },
      { title: 'Privacy', dataIndex: 'privacy' },
      { title: 'Status', dataIndex: 'status', scopedSlots: { customRender: 'status' } },
      { title: 'Original', dataIndex: 'uploadUrl', width: 100, scopedSlots: { customRender: 'original' } },
      { title: 'Generate', dataIndex: 'generateUrl', width: 100, scopedSlots: { customRender: 'generate' } },
      { title: 'Time', dataIndex: 'time', width: 150 },
      {
        title: 'Action',
        dataIndex: 'privacy',
        width: 80,
        scopedSlots: { customRender: 'action' }
      }
    ],
    success: {
      title: `Thank you for sending us the requested information.`,
      subtitle: `Our specialists will review it and be in touch with you shortly. We appreciate your
      cooperation and look forward to assisting you.`
    }
  },
  aiCabinetDesign: {
    buttons: {
      tryItNow: 'Try It Now',
      purchaseNow: 'Purchase Now',
      successStory: 'Success Story',
      tryNow: 'Try Now',
      contactUs: 'Contact Us',
      buyNow: 'Buy Now'
    },
    cabinetRealFill: {
      title: 'Cabinet design',
      text: 'The World‘s First and Only AI Design Platform for Real, Vendor-Sourced Cabinet Renderings'
    },
    sku: {
      title: 'SKU to Digital Assets',
      text: 'Proprietary AI Understands and Learns Your SKU, Elevating Cabinets into Digital Assets with Precision.'
    },
    realCabinet: {
      title: 'Real Cabinet in AI Design',
      text: 'Accurately Reproducing Styles, Materials, and All Details, Visualizing Real Cabinets in AI-powered Design.'
    },
    bridging: {
      title: 'Bridging Design and Sales',
      text: 'Streamlining Design-to-Sale with Real, Purchasable Cabinets in AI Designs for Increased Sales Productivity.'
    },
    buyNow: {
      billedAnnually: 'Billed Annually',
      billedMonthly: 'Billed Monthly'
    }
  },
  aiNativeHardware: {
    cover: {
      title1: 'Collov AI Desk A1',
      title2: 'AI desk A11111',
      subTitle: 'Revolutionizing Customer Acquisition for Interior Design',
      desc: 'Expericence the Fusion of Interior design and AI tech with the AI Desk Series. Ranging up to 55’’ in size, this is the perfect choice for boosting your booth and store elevating engagement at your store. Explore limitless possibilities with multifaceted interactive experiences and generative AI.',
      contact: 'Contact Us',
      pricing: 'Pricing',
      try: 'Try Now',
      demo: 'Try Demo'
    },
    attract: {
      title: 'Spotlight Your Booth',
      desc: 'Whether at your store or expo, your interior design or real estate business can boost foot traffic with AI-native large screens. Golden Home & Collov demonstrate significant increases in traffic at KBIS2024 with AI hardware. ',
      success: 'Success Story',
      increase: 'Increase',
      increase1: {
        percent: '10x',
        desc: 'in customer engagement'
      },
      increase2: {
        percent: '5x',
        desc: 'in Inquiry Rate'
      },
      increase3: {
        percent: '100%',
        desc: 'On-the-spot Deals'
      }
    },
    spot: {
      title: 'Enhance Your Business',
      desc: `Collov AI's revolutionary tool transforms design by learning and replicating brand-specific interior design styles, enabling rapid custom designs and expanding globally, redefining efficiency and customization. AI Desk A1 is the World’s First and Only AI Native hardware.  `,
      try: 'Try Now'
    },
    cards: {
      card1: {
        title: 'Exhibit',
        text: 'Inform and engage visitors in exhibition. The AI Desk, featuring a system that combines a large screen with generative AI for interior design, all integrated through a custom-built native Android application.'
      },
      card2: {
        title: 'Retail',
        text: 'Optimise any interior business store for a better shopping experience. Effortlessly and instantly created and personalized a demo instantly with Collov AI, and ensured the demo consistently showcased the top preference in interior design.'
      },
      card3: {
        title: 'Corporate',
        text: 'Seamlessly convert on-the-spot inquiries to on-the-spot deals. Smart screen and application provide access to more conetnt and devices.'
      }
    },
    SuccessStories: {
      title: 'Success Stories',
      card1: {
        title: 'Collov AI',
        text: 'With the AI-native Interior Desk at booth, Collov garnered 600 inquiries and secured over 30 contracts in just three days, including a notable on-the-spot agreement with John Clark for an AI Desk contract with Modern Shelving, an interior design studio based in San Clemente.'
      },
      card2: {
        title: 'GoldenHome',
        text: 'AI-native large screens can elevate visitor numbers at both stores and expos. Illustrating this, GoldenHome Living Co., Ltd, a publicly traded firm known for kitchen cabinets and personalized furnishings, has remarkably harnessed our AI solutions. Their strategic use led to securing over 3000 transactions at KBIS 2024.'
      },
      card3: {
        title: 'Modern shelving',
        text: 'Modern Shelving provides customizable, versatile shelving with adjustable, interlocking designs for unique, flexible storage that complements any home or office decor. They have formed a strategic partnership with Collov AI and will explore using generative AI for stylized design'
      }
    },
    Efficiency: {
      btn: 'Success Story',
      after: 'Increase',
      text1: 'in customer engagement',
      text2: 'in Inquiry Rate',
      text3: 'On-the-spot Deals'
    },
    TopExhibitions: {
      title: 'Top Exhibitions',
      info: `Collov AI's revolutionary tool transforms design by learning and replicating brand-specific interior design styles, enabling rapid custom designs and expanding globally, redefining efficiency and customization. AI Desk A1 is the World’s First and Only AI Native hardware.  `,
      type1: 'kitchen & bathroom',
      type2: 'Interior Design',
      type3: 'Real Estate'
    },
    PickPlan: {
      title: 'Pick the best plan for you',
      item1: {
        title: 'Cabinet Design',
        info: '10-minute setup with a code-free template.',
        mo: ' / mo ',
        currency: '$',
        price: '250',
        ps: '(Billed Annually)',
        btn: 'Get Started'
      },
      item2: {
        title: 'AI Desk',
        info: 'Unlock Full Power with Collov AI Software Plans Above.',
        mo: '',
        currency: '$',
        price: '2,500',
        ps: '',
        btn: 'Get Started'
      }
    },
    personalized: {
      title: 'Personalized Customization Services',
      desc: 'Boost Engagement with Large Screens: Available in Sizes Ranging from 32 to 55 Inches. Enjoy Multifaceted Interactive Experiences, Driven by Generative AI.',
      contact: 'Contact Us'
    },
    ready: {
      title: 'Ready to elevate engagement and boost sales with Collov AI?',
      contact: 'Contact Now'
    }
  },
  payment: {
    clientSay: {
      title: `What Our Clients Say`,
      list: [
        {
          name: 'Emily Thompson',
          address: 'Kitchen Designer, Elite Interiors',
          content: `As a designer, Collov AI has been a game changer. The ability to quickly visualize a range of cabinet designs in a client's actual kitchen space has streamlined the design process immensely. It's not only about saving time; it's about the ability to bring a client's vision to life in minutes. The positive feedback from clients has been overwhelming.`
        },
        {
          name: 'Michael Anderson',
          address: 'Chief Technology Officer, HomeCraft Cabinets',
          content: `Implementing Collov AI into our website was a breeze. It took less than 30 minutes, and the impact was immediate. The AI’s ability to customize designs based on color, material, and pattern preferences has not only increased our conversion rates but also enhanced our customer satisfaction. It's a technological leap that's putting us ahead in the digital retail space.`
        },
        {
          name: 'Sarah Jenkins',
          address: 'Customer Experience Manager,Modern Kitchen Solutions',
          content: `Collov AI has revolutionized the way we interact with our clients. The lowered communication barrier means our clients can express their preferences more freely, and see them come to life instantly. This has led to quicker decision-making, reduced lead times, and a more enjoyable experience for our customers. It's an invaluable tool for enhancing customer satisfaction.`
        }
      ]
    }
  },
  solutions: {
    AISolutions: `Collov AI Solutions`,
    applicationsText: `Ready-to-use AI applications for high-value use cases.`,
    AIDesignStudio: 'AI Design Studio',
    AIVideoCreator: 'AI Video Creator',
    AIVideoGenerator: 'AI Video Generator',
    AIRealtimeDesign: 'AI Real-time Design',
    wallFlooringRealFill: 'Wall & Flooring Real Fill',
    FlooringDesign: 'Flooring Design',
    HomeRedesign: 'Home Redesign',
    PartialRedesign: 'Partial Redesign',
    wallpaperDesign: 'Wall Design',
    CountertopDesign: 'Countertop Design',
    furnitureReplacement: 'Furniture Replacement',
    aiHomeRedesign: 'AI Home Redesign',
    characterRealTime: 'Character Real-Time',
    RealTimeWebcam: 'Real Time Webcam Wonders',
    RealTimePhoto: 'Real-time Photo Studio',
    AIStoryteller: 'AI Storyteller',
    ProductEngine: 'Product Engine',
    MarketingPersonalization: 'Marketing Personalization',
    SalesDigitalization: 'Sales Digitalization',
    AICabinetDesign: 'Cabinet design',
    AINativeHardware: 'AI Desk',
    Overview: 'Overview',
    FreePackage: 'Free Package',
    earlyTitle: 'Early Innovators Pilot Program',
    earlyText: `Embark on a visionary AI journey with our free pilot program and tap into the expansive
          community of Collov enthusiasts, propelling your brand into the future.`,
    Free: 'Free Integration',
    core: 'Core AI Experience',
    onboarding: 'Simplified Onboarding',
    CollovAIDeveloperTools: `Collov AI Developer Tools`,
    CollovAIDeveloperToolsText: `Collov AI equips you with a comprehensive suite of tools for swift development and seamless deployment of AI-powered design.`,
    CollovAIAPI: 'Collov AI API',
    CollovAITemplate: 'Collov AI SDK & Template',
    AIDatabase: 'Collov AI Database',
    AIDatabaseText: 'Collov AI transforms your styles and SKU into scalable digital assets for AI design',
    DigitalSKU: 'Digital SKU',
    DigitalStyles: 'Digital Design Styles',
    IgniteCover: 'Ignite Design Inspiration With AI',
    IgniteCoverDes: `Collov AI transforms your unique styles into digital assets, enabling dynamic and personalized
          interior design in real time.`,

    CreateAssets: 'Create Your Digital Assets at Scale',
    CreateAssetsDes: `CollovGPT's proprietary AI models understand unique design styles by learning your projects,
        transforming styles into unified digital assets`,
    brandAssets: `Customizable for your brand`,
    assetsDevices: `Unified assets across all devices`,
    CentralizedControls: `Centralized controls`,

    ElevateDesign: 'Elevate Your Design in Seconds',
    ElevateDesignDes: `Leveraging your unique style library, Collov AI detects, adapts, and visualizes preferred styles
        and products in customers' space in seconds`,
    structurePreserved: 'Structure preserved',
    HighQuality: 'High Quality 3D Rendering',
    FurnitureList: 'Shop-able Furniture List',

    Iterate: 'Iterate Your Designs With A Few Clicks',
    IterateDes: `A suite of AI-powered editing tools facilitates an interactive design experience and generates
        real-time design iterations with just a few clicks`,
    PartialFurnitureModification: 'Partial Furniture Modification',
    BackgroundRemover: 'Background Remover',
    SmartAIMarkups: 'Smart AI Markups',
    Ready: 'Ready to transform your design experience with Collov AI?',

    BetterStorytelling: 'In Setting For Better Storytelling',
    BetterStorytellingDes:
      'Seamlessly integrate your products and concepts into dynamic settings for enhanced, engaging storytelling, creating memorable, immersive design experiences.',
    EffortlessTransformation: 'Effortless Style Transformation',
    EffortlessTransformationDes:
      'Redefine your space with the Furniture Background Swap. Easily switch between different lifestyles to find the perfect fit for your home, visualizing real furniture in your own setting.',
    VisualizeSpace: 'Visualize Real Decor in Your Space',
    VisualizeSpaceDes: `Instantly see how various real flooring, wall textures, and paint colors will look in your actual environment, making it easier to choose the perfect materials.`,
    BroughtLife: `Your Design Story, Brought to Life`,
    BroughtLifeDes: `Craft compelling narratives for your design projects. Collov AI storyboard allows you to develop your design concepts into engaging storylines, complete with visuals, for a more immersive design experience.`,

    videoContact: `Ready to transform your video experience with Collov AI?`,
    VideoContent: 'Video Content Reimagined with AI',
    VideoDes:
      'Transform your approach to video with AI that not only animates renderings but also brings avatars to life and condenses your story.',
    VideoInterpreter: 'Video Interpreter',
    VideoTransform: `Transform text into natural-sounding speech with our AI-powered voices and bring design presentations to life with a personalized virtual avatar for a smoother, more customized video experience`,
    VideoSummarizer: 'Video Summarizer',
    VideoSummarizerdes: `Enhance design communication with concise video summaries, capturing the essence of your project
        and drawing key design insights from masterful inspiration videos.`,
    VideoTransformer: 'Video Transformer',
    VideoTransformerText:
      'Elevate your design presentations by creating videos directly from renderings, enriching your marketing and social media with compelling storytelling content.',
    productshopping: `Ready to transform your product shopping experience with Collov AI?`,
    productElevate: 'Elevate Shopping Experience With AI',
    productElevateDes:
      'Collov AI transforms product discovery with context-aware design renderings and intelligent product recommendation.',
    DigitalProduct: 'Digital Product Assets at Scale',
    DigitalProductDes: 'Convert products into customizable catalogs, enhancing the product discovery journey both pre and post design.',
    PointPurchase: 'Point-of-Purchase',
    PointPurchaseDes:
      'A gateway to instantly purchasable products, ensuring an optimized sales fit tailored to individual needs. Collov AI seizes the right moments to attract attention with contextualized offerings',
    VisualDiscovery: 'Visual Search & Discovery',
    VisualDiscoveryDes:
      'AI-powered recommendation engine helps your shoppers effortlessly discover and search curated products within design visuals, seamlessly linking design inspiration to purchase',

    marketingEnhance: 'Enhance marketing with visual storytelling and AI',
    marketingEnhanceDes:
      "Collov AI generates and transforms design visuals into powerful marketing assets, driving engagement and strengthening your brand's story.",
    VisualContentGenerator: 'Visual Content Generator',
    VisualContentGeneratorDes:
      'Produce rich visuals tailored for social media, influencer campaigns, display ads, and email outreach. Forge a consistent and compelling visual language across all marketing platforms with Collov AI',
    InteractiveLanding: 'Interactive Landing Page',
    InteractiveLandingDes:
      'Integrate the prowess of AI design into your website with just a few lines of code, transforming static pages into interactive experiences. Elevate your brand, setting a new industry benchmark with cutting-edge AI distinction',
    Showroom: '24-hour Digital Showroom',
    ShowroomDes:
      'Launch a 24-hour showroom swiftly with Collov AI, consistently showcasing your latest products and designs. Beyond just viewing, foster an engaging community where customers contribute and resonate with dynamic content.',
    marketingReady: 'Ready to transform your branding and marketing experience with Collov AI?',

    saleReady: 'Get Ready to Boost Your Sales With The Future of Design',
    saleDes: 'Enhance customer interactions with rapid AI-rendered visuals, propelling your sales into the digital era.',
    ChatDesigner: 'Chat Designer',
    ChatDesignerDes:
      'Experience a seamless sales journey with our fluid conversational AI interface. By understanding natural language, it revolutionizes the design process, fostering a more interactive and engaging experience for customers.',
    collovCRM: 'Collov CRM',
    collovCRMDes:
      'Dive into a streamlined dashboard that offers keen insights into user web interactions. Combined with a sophisticated backend, it enhances lead management and engagement, setting the gold standard in sales digitalization',
    scan: 'Scan it. See it. Buy it',
    scandes:
      "Turn your customers' mobile devices into powerful design portals via QR scanning in the showroom. Within moments, watch their design inspirations materialize, spotlighting your product in their personal space.",
    freeCover: `Early Innovators Pilot,<br/><span class="free">For Free.</span>`,
    freeCoverDes: `Be among the first to harness the transformative power of our AI-driven interior design tool, designed to streamline creative process and propelling your brand.`,
    AIJourney: 'Embark on the AI Journey',
    AIJourneyDes: `Transition from a promising pilot to realizing the complete benefits of AI design as you scale the
      business`,
    Pilot: 'Pilot',
    Scale: 'Scale',
    Beyond: 'Beyond',
    Integration: 'Integration',
    DesignStyles: 'Design Styles',
    RoomType: 'Room Type',
    CatalogUpload: 'Catalog Upload',
    PartialMagic: 'Partial & Magic Editor',
    AIDesignAnalyzer: 'AI Design Analyzer',
    Template: 'Template',
    free: 'FREE',

    CustomDevelopment: 'Custom Development',
    styles: 'styles',
    types: 'types',
    SKU: 'SKU',

    powerDiscover: 'Discover the power of AI-driven interior design',
    powerDiscoverDes: `Easily integrate with your website and experience a powerful AI journey—upload a photo, select
          style and room type, and receive completed design effortlessly.`,
    LaunchPilot: 'Launch Your AI Pilot',
    LaunchPilotDes: `We guide you from the initial 'how-to' to a fully functioning pilot, paving the way for
      innovation.`,
    ModuleIntegration: `Step 1: Module Integration`,
    websiteEmbed: `Embed Collov's AI design module into your website with just three lines of code. Quick. Simple. Efficient.`,
    InterfaceConfiguration: `Step 2: Interface Configuration`,
    InterfaceConfigurationPersonalize:
      'Personalize the user interface with your brand’s logos to maintain a consistent and professional look.',
    EnjoyDesign: 'Step 3: Enjoy The AI Design',
    LeverageDesign: 'Leverage the power of AI design to elevate your offerings and drive business growth.',
    creditHistory: 'Credit History',
    billingInformation: 'Billing Information',
    Pricing: 'Pricing',
    Upgrade: 'Upgrade'
  },
  AITools: {
    videoGenerator: {
      title: 'Visual Stories Made Simple',
      subtitle: 'From Words to Worlds - Effortless AI Video Generator',
      text: 'Where your imagination takes flight. Collov AI cutting-edge AI video generator turns texts and images into mesmerizing videos, effortlessly weaving your ideas into captivating visual stories.',
      try: 'Try Now',
      turnTitle: 'Turn any idea or image to video, instantly',
      turnText: `Discover how the video generator transforms text prompts and images into captivating videos`,
      turnName: 'From Text to Video',
      turnInfo: 'Create any video you envision with just a text prompt. If you can think it, you can visually realize it.',
      turnLabel: 'Surreal night scene with pink sky, golden river, Great Wall in Telon style, and luminous road.',
      turnBtn: 'Generate Now',
      magicName: 'From Static to Magic',
      magicText: 'Bring static images to life by adding dynamic motion, transforming a single picture into an engaging video narrative.',
      possName: 'Synergy, Unlimited Possibilities',
      possText:
        'Interoperated with Collov’s redesign toolkits, transform designs into videos, or create anything for your creative, marketing, and sales ventures.',
      stepTitle: 'Create a video in 3 easy steps',
      ready: 'A New Era for Video Creating',
      readyText: "Start crafting your future masterpieces today and redefine what's possible in video storytelling.",
      tools: [
        { name: 'Vivid Storytelling', text: 'Transforms simple texts and images into dynamic video narratives' },
        { name: 'High-Quality Output', text: 'Produce professional-grade videos with superior clarity and detail' },
        { name: 'Efficiency and Simplicity', text: 'User-friendly platform for quick, high-quality video production' }
      ],
      steps: [
        { name: 'Enter your information', text: 'Follow the steps to enter your script or reference image' },
        { name: 'AI creates your video', text: 'Collov Video Generator analyzes your input and creates the stunning video' },
        { name: 'Download and enjoy', text: 'Export, enjoy and share your video with the world' }
      ]
    },
    realtimeDesign: {
      title: 'Real-Time AI Creativity',
      subtitle: 'Instant Creation, Precision Control',
      text: 'Experience unparalleled creativity with Collov AI Real Time, the world‘s first controllable real-time design tool, ensuring consistent and precise layout control. As you tweak and transform, your ideas instantly come to life. ',
      try: 'Try Now',
      turnTitle: 'Design at the Speed of Thought',
      turnText: `Real-time design matches your creative pace, ensuring instant realization of your imaginative concepts.`,
      turnName: 'Lightning-Fast Generations',
      turnInfo:
        'Get instant design iterations with our real-time design feature, accelerating your creative and team collaborative process.',
      turnLabel: 'Surreal night scene with pink sky, golden river, Great Wall in Telon style, and luminous road.',
      turnBtn: 'Generate Now',
      magicName: 'Diverse Input Compatibility',
      magicText:
        'Start real-time design with varied inputs like sketch-drawings, 3D base models, images, screen sharing, or even live camera, catering to all your creative needs.',
      possName: 'Smart Software Detection',
      possText:
        'Say goodbye to plug-ins. Fine-tuned algorithm  designed to detect professional design software and offer real-time design as you edit your 3D models.',
      powerName: 'Power Tools to Flourish',
      powerText:
        'Real-time design provides precise tools like brushes, pencils, color palette, and a furniture library for seamless, accurate enhancements.',
      stepTitle: 'Precision and Harmony',
      stepText: 'Unique Edge in Real-Time Design',
      ready: 'Activate Your Real-time Design Experience',
      readyText:
        'Tap into the unmatched real-time design of Collov AI. Initiate your path to creative mastery and professional precision now',
      tools: [
        { name: 'Instant Visualization', text: 'Realistic real-time design renderings from sketch and 3D base models' },
        { name: 'Fine-Tuned Control', text: 'Match your precise vision with unparalleled control over your design layouts' },
        { name: 'Time-Saving Workflow', text: 'Reduces rendering times, speeding up project completion and productivity.' }
      ],
      steps: [
        { name: 'Enter your information', text: 'Follow the steps to enter your script or reference image' },
        { name: 'AI creates your video', text: 'Collov Video Generator analyzes your input and creates the stunning video' },
        { name: 'Download and enjoy', text: 'Export, enjoy and share your video with the world' }
      ],
      moreTitle: 'Discover More:',
      smartName: 'Smart Layer Preservation',
      smartText:
        'Real-time design offers advanced control, retaining original backgrounds or added layers for efficient, dynamic partial edits.'
    },
    wallFlooringRealFill: {
      title: 'Wall & Flooring Real Fill',
      subTitle: 'Visualize Real Materials in Your Space with AI Precision',
      describe:
        'Transforms how you design spaces, seamlessly integrating real materials for floors, walls, and paintings with AI accuracy, bringing your visions to life with stunning realism.',
      tryNowBtn: 'Try Now',
      before: 'Before',
      after: 'After',
      integrateToYourBusiness: 'Integrate to your business',
      btnGroup: [
        {
          title: 'Realistic Visualizations',
          subTitle: 'Accurately visualize real materials for true-to-life space planning'
        },
        {
          title: 'Creative Flexibility',
          subTitle: 'Experiment with a vast array of materials and colors'
        },
        {
          title: 'Informed Decision-Making',
          subTitle: 'See materials in your space for informed design and purchase decisions'
        }
      ],
      content: {
        title: 'Space Transform with Authentic Material Integration',
        subTitle: 'Real Materials, Realistic Designs, Realized Dreams',
        panel: [
          {
            title: 'Real-World Material Library',
            describe:
              'An extensive selection of real materials directly sourced from vendors for flooring, walls, and painting, seamlessly linking home design with your shopping experience.'
          },
          {
            title: 'Realistic Visualization',
            describe:
              'Our next-level visualization automatically adjusts lighting, shadows, and patterns, ensuring ultra-realistic results in your photos.'
          },
          {
            title: 'Intelligent Room Recognition',
            describe:
              'Fine-tuned AI model accurately understands room structures and layouts, optimizing material placement and design harmony.'
          }
        ]
      },
      userPanel: {
        title: 'What our client say',
        list: [
          {
            name: 'Aria Whitmore',
            describe: `Collov AI Material Real Fill completely changed how I approach home design. The ability to see real flooring in my own space helped me make decisions with confidence. It's like having a professional designer and showroom at myfingertips!`
          },
          {
            name: 'Leon Blackburn',
            describe: `As an interior designer, Collov AI has been a game-changer. The realistic visualizations and extensive material library have saved me countless hours. My clients are consistently impressed with how accurately we can envision their spaces before making any commitments.`
          },
          {
            name: 'Sienna Patel',
            describe: `I was skeptical about using an AI design tool at first, but Collov AI won me over. The ease of visualizing different materials and patterns in my actual room made my renovation project so much simpler and more enjoyable. It's amazing to see my ideas come to life so accurately and quickly!`
          }
        ]
      },
      discoverMore: {
        title: 'Discover More',
        groupBtn: [
          { name: 'Home Redesign' },
          { name: 'Instant Home Redesign' },
          { name: 'Partial Remodel' },
          { name: 'Cabinet design' },
          { name: 'Real-time Design' },
          { name: 'Furniture Editing' },
          { name: 'Chat Designer' },
          { name: 'Chat Designer' },
          { name: 'Chat Designer' },
          { name: 'Chat Designer' },
          { name: 'Chat Designer' },
          { name: 'Chat Designer' }
        ]
      },
      discoverNow: {
        title: 'Discover Now',
        describe: `Dive into a world of authentic materials and advanced visualization with Collov AI. Start your journey to transform and enhance your spaces today – your ideal design awaits.`
      }
    },
    characterRealTime: {
      title: 'Character Real Time',
      subTitle: 'Instant Character Magic',
      desc: 'Transform 3D base models into realistic characters in real-time. Unleash creativity with elements drag-and-drop, magic brush and smart text-prompt editing. Your imagination, now instant.',
      try: 'Try Now',
      iconGroup: {
        rapid: {
          name: 'Rapid Creation',
          desc: 'Streamline character design from hours to seconds'
        },
        unlimited: {
          name: 'Unlimited Customization',
          desc: 'Easily add unique traits to your character with a versatile toolset'
        },
        smart: {
          name: 'Smart Simplicity',
          desc: 'Achieve complex designs effortlessly with text-guided editing'
        }
      },
      future: {
        title: 'The Future of Character Design at Your Fingertips',
        desc1: 'Create, Customize, Captivate',
        subtitle: 'Real-Time Character Rendering',
        desc2:
          'Instantly bring characters to life with Collov’s cutting-edge technology that transforms 3D base models into realistic figures in moments.'
      },
      brush: {
        subtitle: 'Magic Brush Customization',
        desc: 'Elevate your characters with a touch. The magic brush and drag-and-drop functionality make adding intricate details and unique elements effortless and fun.'
      },
      prompt: {
        subtitle: 'Text-Prompt Smart Editing',
        desc: 'Simplify complex edits with smart text prompts. Tailor every detail of your character through intuitive, user-friendly commands for precise, professional results.'
      },
      precision: {
        title: 'Precision and Harmony',
        subtitle: 'Unique Edge in Character Design',
        control: {
          title: 'Precision Control',
          desc: 'Retain originality with precise adjustments in angles and expressions of your base models'
        },
        gaming: {
          title: 'Gaming & Anime Ready',
          desc: 'Optimized for vibrant, industry-specific character creation'
        }
      },
      discoverMore: {
        title: 'Discover More:'
      },
      discoverNow: {
        title: 'Start Creating Your Character Now',
        describe:
          'Unleash your creativity today with our tool. Dive into a world where your imagination meets reality. Click to begin your journey into next-level character design'
      }
    },
    RealTimeWebcam: {
      title: 'Real Time Webcam Wonders',
      subTitle: 'See Your World Transform Through Your Webcam',
      desc: 'Webcam Wonders by Collov AI revolutionizes your webcam experience. This tool instantly converts your video feed into stunning artistic renderings, blending technology with art. Ideal for headshots, streaming, or just for fun, it adds a creative twist to your digital interactions. With Webcam Wonders, every moment is a potential masterpiece.',
      try: 'Try Now',
      generate: 'Try Now',
      items1: {
        name: 'Playful Video',
        desc: 'Turn every stream or personal recording into an entertaining visual spectacle'
      },
      items2: {
        name: 'Expressive Creativity',
        desc: 'Add a personal and playful touch to your digital presence'
      },
      items3: {
        name: 'Engaging Interactions',
        desc: 'Elevate your online engagements with dynamic, visually stimulating content'
      },
      content: {
        title: 'The Art of Webcam Transformation',
        subTitle: 'Transform, Tailor, and Transcend Your Webcam Experience',
        info1: {
          name: 'Instant Artistic Conversion',
          desc: 'Transform your webcam feed into art instantly. Webcam Wonders brings real-time creativity, turning everyday video calls into visually stunning experiences.'
        },
        info2: {
          name: 'Versatile Style Palette',
          desc: 'Choose from a variety of styles to match your mood and setting. From professional headshots to lively scene interpretations, Webcam Wonders caters to all your creative needs.'
        },
        info3: {
          name: 'Creative Enhancement Toolkit',
          desc: 'Add a unique flair to your webcam feed with powerful, easy-to-use tools. Perfect for professional polish or playful engagement, these features make every interaction memorable.'
        }
      },
      precision: {
        title: 'Precision and Harmony',
        subTitle: 'Unique Edge in Character Design',
        item1: {
          name: 'AI-Driven Adaptation',
          desc: 'Intelligently alter effects based on the scene, the lighting, and movements of your video feed.'
        },
        item2: {
          name: 'Smart Prompts',
          desc: 'Smart Prompts allows you to experiment with limitless visual possibilities'
        }
      },
      creative: {
        title: 'Unleash Your Creative Vision with Webcam Wonders',
        subTitle:
          'Try Webcam Wonders now and turn your everyday webcam use into an extraordinary display of creativity. Your journey to dazzling video interactions starts here!'
      }
    },
    RealTimePhoto: {
      title: 'Real-time Photo Studio',
      subTitle: 'Photo Transformation, Professional Flair, Instantly',
      desc: 'Unlock the power of professional photo styling with Museclip AI’s Photo Studio. Transform your photos or live shots into stunning images, from sleek headshots to fun anime styles, effortlessly. Dive into a world where quality meets convenience, and elevate your photos with a touch of magic.',
      try: 'Try Now',
      generate: 'Try Now',
      items1: {
        name: 'Instant Professionalism',
        desc: 'Upgrade any photo to professional quality in moments, perfect for profiles and presentations.'
      },
      items2: {
        name: 'Creative Expression',
        desc: 'Explore diverse styles, from realistic to animated, to match your personality and project.'
      },
      items3: {
        name: 'User-Friendly',
        desc: 'Achieve high-end photo results without complex software or skills, making creativity accessible to all.'
      },
      content: {
        title: 'Instant Studio: Elevate Your Photos',
        subTitle: 'Capture, Style, Impress – Effortlessly',
        info1: {
          name: 'Photorealistic Enhancements',
          desc: 'Transforming ordinary photos into photorealistic masterpieces, ensuring every image not only meets but exceeds professional standards as if taken in a high-end studio setting.'
        },
        info2: {
          name: 'Easy Text Prompts Settings',
          desc: 'Easily customize photos by inputting text prompts to select different backgrounds, times of day, and even specific attire, making it simple to match the photo to the desired mood or context.'
        },
        info3: {
          name: 'Versatile Use Cases',
          desc: 'Cater to a wide array of use cases, from creating the perfect professional headshot to designing a unique game avatar. whether for business, social media, or personal projects, create images that perfectly suit their needs.'
        }
      },
      precision: {
        title: 'Precision and Harmony',
        // subTitle: 'Unique Edge in Character Design',
        item1: {
          name: 'Advanced Editing Suite',
          desc: 'Elevate your images with precision editing tools, enabling detailed adjustments and enhancements.'
        },
        item2: {
          name: 'AI Photo-to-Video',
          desc: 'Transform AI-generated photos into dynamic videos, adding motion to still images.'
        }
      },
      creative: {
        title: 'Transform Your Photos with Photo Studio by Collov',
        subTitle: `Whether you're refining your professional image or crafting the perfect avatar, Studio Magic makes it simple and stunning. Start creating now and see where your imagination takes you!`
      }
    }
  },
  scheduleMeeting: {
    title: 'Enterprise: Build with Collov AI',
    desc: 'Embark on a visionary journey with our pilot partnership program and tap into the expansive community of Collov enthusiasts, propelling your brand into the future.',
    form: {
      name: 'Your Name',
      mail: 'E-mail',
      org: 'Organization Name',
      businessType: 'Please select you business type.',
      message: 'Write your message...',
      submit: 'Schedule a meeting',
      inputTips: 'Please input ',
      showDialog: 'Open CollovAI Enterprise Meeting' // Select a Date & Time
    }
  }
}
