export default {
  Introduce: {
    title: '首页重新设计使用可控AI进行部分重新设计',
    subtitle: '部分重塑，完全狂欢',
    info: 'Collov AI具有自动屏蔽和修改房间特定区域的功能，从家具到地板再到墙壁，同时保留了原始设计的精髓，只需几秒钟即可进行量身定制的翻新。',
    btn1: '现在加入',
    btn2: '测试demo'
  },
  tools: {
    SalesEfficiencyRate: '精密定制',
    SalesEfficiency: '以严格的细节定制特定的房间区域',
    DesignCostRate: '效率和速度',
    DesignCost: '提供快速、人工智能驱动的转换',
    CustomerEngagementRate: '字符保存',
    CustomerEngagement: `在更新的同时保持房间原有的魅力`
  },
  h2: '空间重新设计的选择性方法',
  p: '每一次改造的精确性和保存性',
  Transformation: {
    title: 'AI定向转型',
    info: '利用人工智能自动检测并精确遮盖改造区域，从家具到墙壁再到地板，确保每个细节都能被捕捉到，实现完美的改造。',
    cards: {
      key: {
        size: '尺寸：',
        color: '颜色：',
        type: '产品类型：'
      },
      list: [
        {
          name: 'Rubino',
          size: '30 * 60cm',
          color: '赤土色或红色',
          type: '墙'
        },
        {
          name: 'Indaco',
          size: '30 * 60cm',
          color: '灰蓝色',
          type: '墙'
        },
        {
          name: 'Brume',
          size: '30 * 60cm',
          color: '浅灰褐色或米色',
          type: '墙'
        }
      ]
    }
  },
  Precision: {
    title: '多样化的设计灵感',
    info: '探索一个庞大的房间类型和风格库，为每一个可以想象的用例量身定制，增强你的创造力和视野。'
  },
  Visuals: {
    title: '即时逼真的视觉效果',
    info: '在几秒钟内体验超逼真的渲染效果，将重新设计的空间与未受影响的环境无缝融合，打造出富有凝聚力的外观。'
  },
  Compare: {
    title: '保存的准确性',
    info: '高级控制让您在更新特定元素的同时，保持空间的本质不变，平衡创新与传统。'
  },
  More: {
    title: '不仅仅是墙',
    info: '与 Collov 的全能 AI 设计平台无缝集成，提供扩展到橱柜、地板和家具，以及智能设计匹配，轻松补充您的墙面选择。',
    icons: ['全能平台', '智能设计匹配', '支持多表面']
  },
  PickPlan: {
    title: '选择最适合你的计划',
    item1: {
      title: '墙面设计',
      info: '使用无代码模板进行10分钟设置。',
      mo: ' / 月 ',
      currency: '¥',
      price: '600',
      ps: '(按年收费)',
      btn: '立即开始'
    },
    item2: {
      title: 'AI桌面版',
      info: '购买软件后使用上面的Collov AI软件计划解锁全部功能。',
      mo: '',
      currency: '¥',
      price: '6,000',
      ps: '',
      btn: '立即开始'
    }
  }
}
