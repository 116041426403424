export const title = {
    teamMeetingTips: 'Meet Collov team at KIBS 2024 (SL#7160), discuss the future of Cabinet Design',
    teamMeetingLearnMore: 'Learn More',
    home: 'Discover the Future of Home Design',
    uploadTitle: `Start from scratch`,
    upload: `Upload your room photo`,
    example: 'Or use example images',
    Reviews: 'Discussion',
    exampleName: 'Example',
    mode: 'Mode',
    tools: 'Tools',
    similar_items: 'Similar items',
    materials: 'Materials',
    colorPalette: 'Color palette',
    style: 'Describe the style you like',
    furniture: 'Choose your preferred furniture',
    stepsLeft: 'steps left',
    register: 'Already have a Collov account?',
    welcome: 'Welcome Back',
    cardNumber: 'Card Number*',
    paymentInformation: 'Payment Information',
    selectCard: 'Select Card',
    newDesign: 'New design',
    redesign: 'Home Redesign',
    partial: 'Partial Remodel',
    WALL_FLOOR_CEILING: 'Wall & Flooring inpainting ',
    ALL_IN_ONE: 'All In One',
    kitchenRemodeling: 'Cabinet design',
    editing: 'Furniture Editing',
    addItem: 'Add Item',
    eraser: 'Magic Eraser',
    lighting: 'Change Lighting',
    detection: 'Furniture Detection',
    realTimeDesign: 'Real-time Design',
    DIY: 'Mood Board DIY',
    tool: 'Tool',
    mask: 'Please select the section you would like AI to help you.',
    area: 'Area of adding item',
    assistant: 'Visual Assistant',
    roomType: 'Tell us about your space details',
    selection: 'Selection',
    refine: 'Real time editing? (optional)',
    category: 'Select the furniture you want to edit',
    productImage: 'Color and material',
    TETX_2_IMG: 'InteriorGen',
    // MIDJOURNEY_V6: 'Midjourney V6',
    FLOORPLAN_GPT: 'Floorplan GPT',
    DRAFT_IMAGE: 'Draft & Imagine AI design',
    fastPreserved: 'Instant Home Redesign',
    GEN_BGIMAGE: 'Item Perfection',
    color: 'Color and material (optional)',
    train: 'Train your style',
    rendering: 'Rendering',
    myAssets: 'My Assets',
    design: 'Design',
    myDesign: 'My Design',
    publish: 'Publish',
    chat: 'Chat',
    history: 'History',
    before: 'Before',
    after: 'After',
    preview: 'Preview',
    ChatDesigner: 'Chat Designer',
    INTELIGENT_CUSTOMER_SERVICER: 'Chat Designer-new',
    GenVideo: 'Short Video Generator',
    ProductVideoStoryboard: 'Product Video Storyboard',
    video: 'Video',
    character: 'Assistant',
    talk: 'Integrate Al into Your Business',
    talkBusiness: 'Integrate to your business',
    AICommunity: 'AI Community',
    bedroomDesign: 'Bedroom design',
    bohemianStyle: 'Bohemian style',
    scandinavianLivingRoom: 'Scandinavian living room',
    topDay: 'Top of the day',
    topWeek: 'Top of the week',
    product: 'product',
    REALTIME: 'Real-time',
    type: 'Type',
    'client-pro': 'Product',
    MyRendering: 'My Rendering',
    enterpriseBuild: 'Enterprise: Build with Collov AI',
    mobileUpload: 'See products in your room',
    homeNew: {
        nav: {
            Interior: 'Interior',
            Cabinet: 'Cabinet',
            WallFlooring: 'Wall & Flooring',
            Architectural: 'Architectural',
            FloorPlan3D: 'Floor Plan & 3D',
            Text: 'Text2Img',
            Product: 'Product',
            realTimeDesign: 'Real-time Design',
            redesign: 'Home Redesign',
            partial: 'Partial Remodel',
            kitchenRemodeling: 'Cabinet Design',
            WALL_FLOOR_CEILING: 'Wall & Flooring',
            ChatDesigner: 'Chat Designer',
            more: 'More',
        },
        tool: {
            realTimeDesign: 'Unchanged',
            partial: 'Partial Remodel',
            redesign: 'Home Redesign',
            // MIDJOURNEY_V6: 'Midjourney V6',
            TETX_2_IMG: 'InteriorGen',
        },
        title: {
            FastStyles: 'Fast Styles',
            roomType: 'Room Type',
            ColorMaterial: 'Color & Material',
            AreaRetouch: 'Area of retouch',
            AreaRetouchItem: { Wall: 'Wall', Flooring: 'Flooring', Ceiling: 'Ceiling' },
        }
    }
}
