export default {
  Introduce: {
    Exclusive: '独家',
    title: '探索、设计、转变',
    subtitle: '利用 AI 即时可视化您的墙壁设计',
    info: '立即可视化您房间中的各种瓷砖、壁纸和绘画，让您比以往更轻松地探索、设计和改造您的家，以体现您的独特风格。',
    btn1: '现在加入',
    btn2: '测试demo'
  },
  Sales: {
    l: ['设计', '和', '销售', '结合'],
    r: '弥合设计和销售之间的差距，让客户能够探索丰富多样的瓷砖、壁纸和绘画。这种全面的访问使客户能够进行实验，提供多样化的选择，确保做出符合他们愿景的明智决策。'
  },
  h2: '创造与您的产品一样卓越的独特设计和销售体验',
  p: '在一个平台上展示和销售产品所需的所有工具。',
  Transformation: {
    title: '数字展示柜转型',
    info: '使用 Collov AI 将您的 SKU 转换为动态数字资产，提供丰富多样的瓷砖、壁纸和绘画，使每个供应商的收藏品即时可访问并且互动。',
    cards: {
      key: {
        size: '尺寸：',
        color: '颜色：',
        type: '产品类型：'
      },
      list: [
        {
          name: 'Rubino',
          size: '30 * 60cm',
          color: '赤土色或红色',
          type: '墙'
        },
        {
          name: 'Indaco',
          size: '30 * 60cm',
          color: '灰蓝色',
          type: '墙'
        },
        {
          name: 'Brume',
          size: '30 * 60cm',
          color: '浅灰褐色或米色',
          type: '墙'
        }
      ]
    }
  },
  Precision: {
    title: 'AI 助力的精准贴合',
    info: '利用先进的 AI 检测并精确缩放瓷砖和壁纸，确保客户获得准确的产品增强空间的表示。'
  },
  Visuals: {
    title: '即时超真实视觉效果',
    info: '在几秒钟内实现超真实的瓷砖、壁纸和绘画渲染，先进技术与设计相结合，即时而美丽地将您的愿景变为现实。'
  },
  Compare: {
    Before: '转换前',
    After: '转换后',
    title: '对比和对照',
    info: '轻松查看前后场景或并排比较各种设计选项，通过清晰的视觉变换，赋予明智的决策力。'
  },
  More: {
    title: '不仅仅是墙',
    info: '与 Collov 的全能 AI 设计平台无缝集成，提供扩展到橱柜、地板和家具，以及智能设计匹配，轻松补充您的墙面选择。',
    icons: ['全能平台', '智能设计匹配', '支持多表面']
  },
  PickPlan: {
    title: '选择最适合你的计划',
    item1: {
      title: '墙面设计',
      info: '使用无代码模板进行10分钟设置。',
      mo: ' / 月 ',
      currency: '¥',
      price: '250',
      ps: '(按年收费)',
      btn: '立即开始'
    },
    item2: {
      title: 'AI桌面版',
      info: '购买软件后使用上面的Collov AI软件计划解锁全部功能。',
      mo: '',
      currency: '¥',
      price: '6,000',
      ps: '',
      btn: '立即开始'
    }
  }
}
