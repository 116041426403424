export default {
  Banner: {
    title: 'AI 主页重新设计',
    subtitle: '用 AI 激发家庭设计灵感',
    info: 'Collov AI 将您独特的风格转化为数字资产，实现实时动态和个性化的室内设计。',
    btn1: '现在加入',
    btn2: '尝试演示'
  },

  Tools: [
    {
      title: '即时创建',
      info: '在几秒钟内转换房间和空间'
    },

    {
      title: '逼真的精确度',
      info: '清晰看到您的愿景细节'
    },

    {
      title: '个性化风格',
      info: '完美匹配您品味的设计'
    }
  ],

  Unit1: {
    title: '大规模创建您的数字资产',
    info: 'Collov AI 的专有 AI 模型通过学习您的项目来理解独特的设计风格，将风格转化为统一的数字资产。',
    icons: ['可为您的品牌定制', '所有设备的统一资产', '集中控制'],
    imgs: ['波西米亚', '沿海', '农舍', '折衷', '斯堪的纳维亚', '现代']
  },

  Unit2: {
    title: '多样的空间，多样的风格',
    info: '从空白的画布到布置好的饰面，我们广泛的房间和风格库满足每一种场景，释放无尽的可能性。'
  },

  Unit3: {
    title: '即时超逼真的视觉效果',
    info: 'Collov AI 在几秒钟内检测、适应并将首选风格和产品可视化到客户的空间中，以惊人的清晰度和精确度将设计梦想带入生动的生活。'
  },

  Unit4: {
    title: '比较和对比',
    info: '轻松查看前后场景或并排比较各种设计选项，通过清晰的视觉转换做出明智的决策。'
  },

  Unit5: {
    title: '使用强大的工具迭代您的设计',
    info: '与 Collov 的一体化 AI 设计平台无缝集成，从领先的供应商中选择真实的橱柜、地板和墙壁，弥合虚拟创造力和现实世界应用之间的差距。'
  },

  PickPlan: {
    title: '选择最适合您的计划',
    item1: {
      title: '家庭重新设计',
      info: '10 分钟设置，无代码模板。',
      mo: ' / 月',
      currency: '¥',
      price: '600',
      ps: '(按年计费)',
      btn: '开始'
    },

    item2: {
      title: 'AI 桌面',
      info: '使用 Collov AI 软件计划以上解锁全部功能。',
      mo: '',
      currency: '¥',
      price: '6,000',
      ps: '',
      btn: '开始'
    }
  }
}
