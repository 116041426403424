import {createVNode, render} from "vue";
import Component from './index.vue'
import type {OptionLoading} from './type'
export const Loading = (obj:object)=>{
    const container = document.createElement('div')
    const opts:OptionLoading={
        ...obj
    }
    const vm = createVNode(Component, {...opts})
   /* opts.close=()=>{
        render(null as any,container)
    }*/
    vm.props.onClose=()=>{
      if(obj.close && typeof obj.close === 'function'){
        obj.close()
      }
      render(null,container)
    }
    render(vm,container)
    document.body.appendChild(container)
    return vm
}
