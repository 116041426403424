export const plan={
    currentPlan:'Current Plan',
    mo:'mo',
    editPlan:'Edit plan',
    planUsage:'Plan Usage',
    timeUsage:'Usage reset on',
    Remaining:'Remaining',
    Monthly:'Monthly',
    billingCycle:'Billing cycle',
    billingPeriod:'Billing period',
    PaymentMethod:'Payment method',
    ChangePaymentMethod:'Change payment method',
    PaymentInformation:'Payment Information',
    addCardTitle:'Add a credit or debit card',
    addYourCard:'Add your card',
    CardNumber:'Card Number*',
    SelectCard:'Select Card',
    Submit:'Submit',
    NeedHelp:'Need help?',
    helpText:[
        'Visit the',
        'term and condition',
        'page or',
        'contact us',
        'for additional support.',
    ],
    Subscribe:'Subscribe',
    Unsubscribe:'Unsubscribe',
    unsubscribeText:'Please be aware that unsubscribe will cause you to lose all of your saved content.',
    cards: [
        {label: 'Next Payment',pre:'$', value: '', date: ''},
        {label: 'Current Plan', action: 'Edit Plan', link: '/plan/edit', value: '', credit: ''},
        {label: 'Payment Method', action: 'Edit', type: 'card',showCard:true},
        {label: 'Credits available', value: '', text: 'Credits are valid till ', till: ''},
    ],
    tabs: [{label: 'Billing History', value: 'BILLING'}, {label: 'Credit history', value: 'CREDIT'}],
    columns: [
        {title: 'Service', dataIndex: 'type', scopedSlots: {customRender: 'service'}},
        {title: 'Date', dataIndex: 'time'},
        {
            title: 'Cost', dataIndex: 'usedCredits', customRender: function (text,record) {
                const pre=record.type==='RECHARGE'?'+ ':'- '
                return pre +text + ' Credits'
            }
        },
        {
            title: 'Remaining', dataIndex: 'remainCredits', customRender: function (text) {
                return text + ' Credits'
            }
        }

    ],
    columnsBilling:[
        {
            title: 'Amount', dataIndex: 'totalCents', customRender: function (text) {
                return text ? '$' + ((text / 100).toFixed(0)).toLocaleString() : ''
            }
        },
        {title: 'Date', dataIndex: 'time'},
        {title: 'Status', dataIndex: 'status', scopedSlots: {customRender: 'paid'}},
        {
            title: 'Payment Method', dataIndex: 'cardInfo' ,customRender: function (text) {
                const cardInfo =text?JSON.parse(text):null
                return cardInfo ? cardInfo.brand+' ' +cardInfo.number.replaceAll('*',''): ''
            }
        },
    ],
    perks:[
        'All features available for your use.',
        'No watermark',
        'Keep uploaded images private',
    ],
    payDetail:{
        title:'Review Changes',
        row:[
            {label:'collov.ai Pro',price:8,per:'mo'},
            {value:0,label:' credits/mo',price:0,className:'credits'},
        ]
    },
    go:'Go to Next Page',
    PromotionCodeDiscount:'Promotion Code Discount',
    ReferralDiscount:'Referral Discount',
    OrderTotal:'Order Total',
    Apply:'Apply',

}
