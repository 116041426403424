export const btn = {
    share: '分享',
    detail: '详情',
    back: '返回',
    comingSoon:'即将推出',
    publish: '发布',
    use: '使用',
    cancel: '取消',
    credit:'积分',
    credits: '积分',
    done: '完成',
    Post: '发布',
    next: '下一步',
    edit: '编辑',
    duplicate:'复制',
    generate: '生成',
    regenerate: '重新生成',
    upscale: '提升',
    more: '更多',
    comment: '评论',
    sure: '确定',
    activity: '活动',
    latestDesign: '最新设计',
    upgrade: '升级',
    upPro: '升级会员',
    upload: '拖放或<span>浏览</span>上传',
    uploadBtn: '上传新照片',
    help: '帮助',
    signOut: '登出',
    signUp: '注册',
    signIn: '登录',
    continue: '继续',
    subscribe: '购买',
    contactSale:'联系销售',
    submit: '提交',
    furniture: '家具',
    architectural: '建筑',
    selectAll: '全选',
    selected: '已选择',
    deselectAll: '取消全选',
    create: '创建',
    general: '常规',
    interiorDesign: '室内设计',
    useAsReference: '用作参考',
    Delete: '删除',
    update: '更新',
    close: '关闭',
    createYourOwnAICabinetStyle: '打造您自己的人工智能橱柜风格',
    createYourOwnWallModel:'创建自己的墙/地板AI模型',
    talk: '让我们谈谈创新',
    SubmitBusiness:`提交商业兴趣`,
    All: '全部',
    Rendering: '渲染',
    Video: '视频',
    Assistant: '助手',
    showMore:'显示更多',
    viewLess: '查看更少',
    viewAll: '查看全部',
    ALL: '实时更新',
    ALL_VIEW: '历史排行榜',
    try:'尝试',
    tryBetter:'以获得更高质量的设计体验',
    Rename:'重命名',
    Erase:'擦除',
    clear:'清除',
    brushSize:'画笔大小',
    uploadUrl:'上传图片',
    setBg:'设置背景',
    prompt:'输入提示',
    editing:'编辑',
    add:'添加',
    enterStudio:'进入设计工作室',
    Freestyle:'自由发挥',
    Floorplan:'平面',
    Architect:'建筑',
    Interior:'室内',
    save: '保存',
    undo: '回退',
    redo: '重做',
    enterFullScreen: '进入全屏',
    exitFullScreen: '退出全屏',
    EditFurniture:'编辑和替换家具',
    replaceFurniture:'替换家具',
    selectInputPicture:'设为输入图',
    StartfromScratch: '重零开始',
    AIDetection: 'AI检测',
    BuildWithCollovAI: '企业：使用 Collov AI 进行构建',
};
