export const corsImgSrc = (src: string) => {
  if (!src.startsWith('http')) {
    return src
  }
  if (src.includes('/cors/')) {
    return src
  }
  const url = new URL(src)
  const localList = ['localhost', '127.0.0.1', '192.168']
  const lang = import.meta.env.VITE_LANG
  const lineHost = lang === 'en' ? 'collov.ai' : 'collov.cn'
  const hostName = localList.includes(location.hostname) ? lineHost : location.hostname
  if (
    url.hostname.includes('cloudfront') ||
    url.hostname.includes('flair-moodboard-test.s3') ||
    url.hostname.includes('ai-train-data2.s3') ||
    url.hostname.includes('cdn.reimaginehome.ai') ||
    url.hostname.includes('s3.amazonaws.com') ||
    url.hostname.includes('s3-us-west-2.amazonaws.com') ||
    url.hostname.includes('flair-moodboard.s3.us-west-1.amazonaws.com')
  ) {
    if (hostName) {
      return 'https://' + hostName + '/cors/' + encodeURIComponent(url.hostname + url.pathname)
    }
  } else {
    return src
  }
}
