
export const allInOneForm = {
  tabs: {
    ItemReplace: '实物替换',
    HomeRedesign: '室内设计',
  },
  navs: {
    Furnitures: '家具',
    Cabinets: '柜体',
    Floors: '地板',
    Walls: '墙壁',
    FurnitureSet: '家具集',
    Countertops: '台面',
  },
  contenNavs: {
    Product: '产品',
    History: '历史',
    Favorites: '收藏夹',
  },
  btn: {
    Filter: '筛选',
    Replace: '替换',
    Add: '添加',
    Remove: '消除',
    WholeRoomRedesign: '全屋设计',
    PartialRemodel: '局部改造',
    visitProductPage: '访问产品页面',
    Clearall: '清空全部'
  },
  filter: {
    Price: '价格',
    Vendor: '供应商',
    Color: '颜色',
    Filter: '过滤',
    ColorBlack: '黑色',
    ColorBlue: '蓝色',
    ColorRed: '红色',
    ColorYellow: '黄色',
    ColorGray: '灰色',
    ColorGreen: '绿色',
    ColorOrange: '橙色',
    ColorWhite: '白色',
    title: '筛选',
    clear: '清除',
    done: '确定',
  },
  replaceImage: '换张图片',
  Rotate: '旋转',
  Regenerate: '重新生成',
  DesignComparison: '前/后对比',
  PoweredBy: '技术支持',
}
