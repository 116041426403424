import SvgType from './common/SvgType.vue'
import CButton from './common/CButton.vue'
import CMainFrame from './common/mainFrame.vue'
import type {App,Component} from 'vue'
const allGloablComponent:{ [key: string]: Component } = { SvgType,CButton,CMainFrame }
export default {
  install(app:App) {
    Object.keys(allGloablComponent).forEach((key) => {
      app.component(key, allGloablComponent[key])
    })
  }
}
