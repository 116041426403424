export const title = {
    teamMeetingTips: '在KIBS 2024 (SL#7160)与Collov团队会面，一起讨论橱柜设计的未来',
    teamMeetingLearnMore: '学习更多',
    home: '每个人都可以成为室内设计师',
    uploadTitle: '从零开始',
    upload: '上传您的房间照片',
    example: '或使用示例图片',
    Reviews: '讨论',
    exampleName: '示例',
    mode: '模式',
    tools: '工具',
    similar_items: '相似物品',
    materials: '材料',
    colorPalette: '颜色调色板',
    style: '描述您喜欢的风格',
    furniture: '选择您喜欢的家具',
    stepsLeft: '剩余步骤',
    register: '已经有Collov账号了？',
    welcome: '首次注册免费体验9张效果图',
    cardNumber: '卡号*',
    paymentInformation: '付款信息',
    selectCard: '选择卡片',
    newDesign: '新设计',
    redesign: '整家设计',
    partial: '局部改造',
    enterpriseBuild: '企业合作',
    WALL_FLOOR_CEILING: '局部改造-墙纸天花板',
    ALL_IN_ONE: '多功能AI组件',
    kitchenRemodeling: '厨房改造',
    editing: '家具编辑',
    addItem: '添加物品',
    eraser: '魔法橡皮擦',
    lighting: '更改照明',
    detection: '家具检测',
    realTimeDesign: '实时设计',
    DIY: '心情板DIY',
    tool: '工具',
    mask: '请选择您希望AI帮助您的部分。',
    area: '添加物品的区域',
    assistant: '视觉助手',
    roomType: '告诉我们有关您房间的详细信息',
    selection: '选择',
    refine: '实时编辑？（可选）',
    category: '选择要编辑的家具',
    productImage: '颜色和材料',
    TETX_2_IMG: '室内设计生成',
    MIDJOURNEY_V6: 'Midjourney V6',
    FLOORPLAN_GPT: '平面图生成',
    DRAFT_IMAGE: '草图和想象的AI设计',
    INTELIGENT_CUSTOMER_SERVICER: '智能聊天设计师',
    fastPreserved: '即时家居重新设计',
    GEN_BGIMAGE: '完美物品',
    color: '颜色和材料（可选）',
    train: '培训您的风格',
    rendering: '渲染',
    myAssets: '我的项目',
    design: '设计',
    myDesign: '我的设计',
    publish: '发布',
    chat: '聊天',
    history: '历史',
    before: '之前',
    after: '之后',
    preview: '预览',
    ChatDesigner: '聊天设计师',
    GenVideo: '短视频生成器',
    ProductVideoStoryboard: '产品视频故事板',
    video: '视频',
    character: '助手',
    talk: '将AI整合到您的业务中',
    talkBusiness: '与您的业务集成',
    AICommunity: '人工智能社区',
    bedroomDesign: '卧室设计',
    bohemianStyle: '波西米亚风格',
    scandinavianLivingRoom: '北欧客厅',
    topDay: '今日热门',
    topWeek: '本周热门',
    product: '产品',
    REALTIME: '实时设计',
    mobileUpload: '查看您房间中的产品',
    type: '类型',
    homeNew: {
        nav: {
            Interior: '室内',
            Cabinet: '柜体',
            WallFlooring: '墙壁 & 地板',
            Architectural: '建筑',
            FloorPlan3D: '平面设计 & 3D',
            Text: '文生图',
            Product: '产品',
            realTimeDesign: '实时设计',
            redesign: '家居重新设计',
            partial: '部分改造',
            kitchenRemodeling: '橱柜设计',
            WALL_FLOOR_CEILING: '墙壁 & 地板',
            ChatDesigner: '聊天设计师',
            more: '更多',
            MyRendering: '我的渲染',
            enterpriseBuild: '企业：使用 Collov AI 进行构建',
        },
        tool: {
            realTimeDesign: '未改变的',
            partial: '局部改造',
            redesign: '家居重新设计',
            TETX_2_IMG: '室内设计生成',
            MIDJOURNEY_V6: 'Midjourney V6',
        },
        title: {
            FastStyles: '快速风格列表',
            ColorMaterial: '颜色和材质',
            AreaRetouch: '设计区域',
            AreaRetouchItem: { Wall: '墙壁', Flooring: '地板', Ceiling: '天花板' },
        }
    },
};
