
<template>
 <div class="confirm-created">
   <div class="sucsess-title">{{ $t('confirm.success') }}!</div>
   <div class="sucsess-img">
     <img src="@/assets/images/sucsess.png" />
   </div>
   <div class="sucsess-tip">
     {{ $t('confirm.successTip') }}
   </div>
   <c-button type="primary" block :loading="store.loading" color="white" @click="toLogin">
     {{$t('btn.signIn')}}
   </c-button>
 </div>
</template>
<script setup lang="ts">
import {clientStore} from "@/stores/client";
const store = clientStore()
import { useI18n } from 'vue-i18n'
const { locale}  = useI18n()
const toLogin=()=>{
  store.Login(locale.value)
}
</script>
<style scoped lang="less">
.confirm-created{
  max-width: 560px;
  flex-direction: column;
}
.sucsess-title{
  font-size: 30px;
  line-height: 42px;
  margin-top: 24px;
  height: 74px;
  color: #282828;
  .flex-center();
}
.sucsess-img{
  .flex-center();
  img{
    width: 122px;
  }
}
.sucsess-tip{
  line-height: 1.5;
  margin-top: 24px;
  font-size: 14px;
  text-align: center;
  color: #282828;
  margin-bottom: 48px;
}
</style>
