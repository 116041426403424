import FingerprintJS from '@fingerprintjs/fingerprintjs' // 引入
const fpPromise = FingerprintJS.load()
const baseRTBUrl = 'https://rtb.collov.com/track/api'
const adServerUrl = baseRTBUrl + '/tracking/collect'


export default {
  async trackEvent(eventType, val) {
    console.log('rtb:', eventType)
    // let hostname = location.hostname
    // if (hostname.indexOf('collov.ai') < 0) {
    //   return
    // }
    let RTBUTM = await this.getRTBFromQuery()
    RTBUTM.eventType = eventType
    RTBUTM.userEmail = RTBUTM.userEmail
    if (eventType === 'collov_ai_paid') {
      RTBUTM.promoCode = val
    } else if (eventType === 'collov_ai_registered') {
      RTBUTM.userEmail = val
    }
    const rawUserUtm = localStorage.getItem('user_utm')
    const userUtm = rawUserUtm ? JSON.parse(rawUserUtm) : {}
    let source = userUtm.utm_source || userUtm.adsource || userUtm.ad_source
    if (source && source !== 'undefined') {
      RTBUTM.utm_source = source
    }
    RTBUTM.type = userUtm.utm_src_type
    let paramStr = ''
    let index = 0
    for(let key in RTBUTM) {
      let keyStr = key.toLowerCase()
      if (index === 0) {
        paramStr = `?${keyStr}=${RTBUTM[key]}`
      } else {
        paramStr = paramStr + `&${keyStr}=${RTBUTM[key]}`
      }
      index++
    }
    // console.log('调用rtb接口：',  `${adServerUrl}${paramStr}`)
    fetch(
      `${adServerUrl}${paramStr}`
    ).then((_) => _)
  },
  getRtbUtm() {
    const userUtm = localStorage.getItem("user_utm");
    if (!userUtm) return null

    const utmJSON = JSON.parse(userUtm)
    if (!utmJSON.ad_id) return null
    let utm_source = utmJSON.utm_source || utmJSON.adsource || utmJSON.ad_source
    return {
      utm_source: utm_source,
      ad_id: utmJSON.ad_id,
    }
  },
  async getUserID(type) {
    const localUserID = localStorage.getItem('collov_rtb_id')
    if (localUserID) {
      return localUserID
    }
    const fp = await fpPromise
    const result = await fp.get()
    const userID = result.visitorId
    localStorage.setItem('collov_rtb_id', userID)
    if (!type) {
      this.createImgSetCookie(userID)
    }
    return userID
  },
  async saveUserID(to) {
    const localUserID = localStorage.getItem('collov_rtb_id')
    if (!localUserID) {
      const fp = await fpPromise
      const result = await fp.get()
      const userID = result.visitorId
      localStorage.setItem('collov_rtb_id', userID)
      this.createImgSetCookie(userID, to)
    } else {
      this.createImgSetCookie(localUserID, to)
    } 
  },
  // Cookie mapping 设置
  createImgSetCookie (userID, to) {
    // let imgEle = document.createElement('img')
    // imgEle.setAttribute('src', 'https://collov.com/api/rtb/cookie/set?userId=' + userID)
    // imgEle.setAttribute('style', 'display:none')
    // document.getElementsByTagName('body')[0].appendChild(imgEle);
    // setTimeout(() => {
    //   this.setRTBCookie(to)
    // }, 5000)
  },
  setRTBCookie (to) {
    let imgEleCookie = document.createElement('img')
    let page = ''
    imgEleCookie.setAttribute('src', `https://collov.com/api/rtb/cookie${page ? '?page=' + page : ''}`)
    imgEleCookie.setAttribute('style', 'display:none')
    document.getElementsByTagName('body')[0].appendChild(imgEleCookie);
  },
  async getRTBFromQuery() {
    const urlSearchParams = new URLSearchParams(window.location.search)
    let RTBUTM = {}
    for(let pair of urlSearchParams.entries()) {
      RTBUTM[pair[0]] = pair[1]
   }
   const userID = await this.getUserID()
   RTBUTM.userid = userID
  //  RTBUTM.clicked = false
  //  RTBUTM.update_time = Date()
   RTBUTM.userEmail = this.getUserEmail()
   return RTBUTM
  },
  getUserEmail() {
    try {
      const user = JSON.parse(localStorage.getItem('flair_front_userInfo'))
      return user.email
    } catch (error) {
      return ''
    }
  },
  async recordRTBClicked() {
    // const RTBUTM = await this.getRTBFromQuery()
    this.trackEvent('collov_ai_click')
  },
  async recordRTBRegistered(userEmail) {
    if (!userEmail) {
      userEmail = this.getUserEmail()
    }
    this.trackEvent('collov_ai_registered', userEmail)
  },
  async recordRTBToolsUse(toolsName) {
    this.trackEvent('collov_ai_use_'+ toolsName)
  },
  async recordRTBCollovAiPaid(promoCode) {
    this.trackEvent('collov_ai_paid', promoCode)
  }
}
