export const modal = {
    delete: {
        title: '删除',
        content: '确定要删除吗？',
        generation:'设计生成失败。您需要重新生成吗',
        okText: '删除',
        cancelText: '取消',
    },
    unsubscribe: {
        title: '取消订阅计划',
        content: '确定要取消订阅计划吗？',
        okText: '确定',
        cancelText: '取消',
    },
    deleteAccount: {
        title: '删除账户',
        content: '确定要删除此账户吗？',
        okText: '确定',
        cancelText: '取消',
        name: '删除我的账户',
        text: '确定要删除您的账户吗？这将永久删除您的账户。',
    },
    logout: {
        title: '登出',
        content: '确定要登出吗？',
        okText: '确定',
        cancelText: '取消',
    },
    quit:{
        content:'您确定要退出吗？',
        saved:'您的设计已保存',
        okText: '确定',
        saveText: '保存 & 退出',
        cancelText: '取消',
    },
    duplicate:{
        content:'您确定要复制吗？',
        okText: '复制',
        cancelText: '取消',
    },
    action:{
        content:'您确定要返回上一步吗？',
        okText: '确定',
        cancelText: '取消',
    },
    realTime:{
        content:'免费用户只能有一个实时设计项目，请升级为专业用户或删除现有项目',
        okText: '升级',
        cancelText: '转到我的项目',
    },
    create:{
        title: '你想要创建什么？',
        Assistant: '助手',
        AssistantText: 'AI角色是由人工智能驱动的数字人物，能够模拟对话、从互动中学习，并根据其编程和积累的知识提供响应。它代表了技术和拟人化的融合。',
        videoGeneration: '短视频生成',
        videoGenerationText: '这个工具是一款创新的视频生成软件，可以根据您的描述创建一个2秒的视频片段。只需输入您的想法、概念或场景，工具就会使用先进的AI算法解释并将您的文字转化为简短而动态的视频。',
        Interiorgen: '室内设计生成',
        InteriorgenText: 'AI 室内设计生成 是一项尖端技术，允许用户通过对话界面从文本描述中生成图像。通过向AI提供详细的提示，用户可以看到他们的文字立即转化为视觉艺术。这种自然语言处理和生成艺术的综合为普通用户和专业人士提供了创造性的可能性。',
    },
    contact:{
        title: '提供您的反馈：<br/>报告错误并请求功能以帮助改进我们的系统',
        feedbackThank: '感谢您抽出时间提供反馈！',
        SubmitFeedback: '提交反馈',
        customerFeedback: '客户反馈',
        featureRequest: '功能请求',
        message: '写下您的留言...',
        messageInput: '请输入您的留言。',
        email: '电子邮件',
        emailMessage: '请输入您的电子邮件。',
        name: '您的姓名',
        nameMessage: '请输入您的姓名。',
        selectMessage: '请选择',
    }
};
