export const client={
    title: 'Public Profile',
    tip:'People on Collov will be able to see the information below.',
    Profile:'Profile Information',
    Facebook:'Facebook',
    Instagram:'Instagram',
    Pinterest:'Pinterest',
    Youtube:'Youtube',
    Blog:'Blog',
    Views:'Views',
    AIDESIGN:'AI DESIGN',
    DESIGNMODEL:'DESIGN MODEL',
    navs:[
        {name:'AI design',value:'0',key:'AI',fixed:'aiGenerateRecordCount'},
        {name:'Design model',value:'0',key:'MODEL',fixed:'designModelCount'},
        {name:'My Favorite',value:'0',key:'FAVORITE',fixed:'collectCount'},
    ],
    buttons:{
        save: 'Save'
    }
}
