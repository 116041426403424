export const plan = {
    currentPlan: '当前计划',
    mo: '月',
    editPlan: '编辑计划',
    planUsage: '计划使用情况',
    timeUsage: '使用情况重置于',
    Remaining: '剩余',
    Monthly: '每月',
    billingCycle: '计费周期',
    billingPeriod: '计费期间',
    PaymentMethod: '支付方式',
    ChangePaymentMethod: '更改支付方式',
    PaymentInformation: '支付信息',
    addCardTitle: '添加信用卡或借记卡',
    addYourCard: '添加您的卡',
    CardNumber: '卡号*',
    SelectCard: '选择卡',
    Submit: '提交',
    NeedHelp: '需要帮助？',
    helpText: [
        '访问',
        '条款和条件',
        '页面或',
        '联系我们',
        '以获取额外支持。',
    ],
    Subscribe: '订阅',
    Unsubscribe: '取消订阅',
    unsubscribeText: '请注意，取消订阅将导致您失去所有保存的内容。',
    cards: [
        { label: '下次付款', pre: '$', value: '', date: '' },
        { label: '当前计划', action: '编辑计划', link: '/plan/edit', value: '', credit: '' },
        { label: '支付方式', action: '编辑', type: 'card', showCard: true },
        { label: '可用积分', value: '', text: '积分有效至 ', till: '' },
    ],
    tabs: [
        { label: '计费历史', value: 'BILLING' },
        { label: '积分历史', value: 'CREDIT' },
    ],
    columns: [
        { title: '服务', dataIndex: 'type', scopedSlots: { customRender: 'service' } },
        { title: '日期', dataIndex: 'time' },
        {
            title: '费用', dataIndex: 'usedCredits', customRender: function (text, record) {
                const pre = record.type === 'RECHARGE' ? '+ ' : '- ';
                return pre + text + ' 积分';
            }
        },
        {
            title: '剩余', dataIndex: 'remainCredits', customRender: function (text) {
                return text + ' 积分';
            }
        }

    ],
    columnsBilling: [
        {
            title: '金额', dataIndex: 'totalCents', customRender: function (text) {
                return text ? '$' + ((text / 100).toFixed(0)).toLocaleString() : '';
            }
        },
        { title: '日期', dataIndex: 'time' },
        { title: '状态', dataIndex: 'status', scopedSlots: { customRender: 'paid' } },
        {
            title: '支付方式', dataIndex: 'cardInfo', customRender: function (text) {
                const cardInfo = text ? JSON.parse(text) : null;
                return cardInfo ? cardInfo.brand + ' ' + cardInfo.number.replaceAll('*', '') : '';
            }
        },
    ],
    perks: [
        '所有功能均可供使用。',
        '无水印',
        '保留上传的图像的隐私',
    ],
    payDetail: {
        title: '查看更改',
        row: [
            { label: 'collov.ai 专业版', price: 8, per: '月' },
            { value: 0, label: ' 积分/月', price: 0, className: 'credits' },
        ]
    },
    go: '前往下一页',
    PromotionCodeDiscount: '促销代码折扣',
    ReferralDiscount: '推荐折扣',
    OrderTotal: '订单总额',
    Apply: '应用',
};
