export default {
  tabs: {
    list: ['图片', '文字'],
    text: {
      title: '与我们的聊天设计师交谈',
      placeholder: '发送消息 ...',
      btn1: '重新设计我的房间',
      btn2: '设计我的客厅'
    }
  },
  ChatSide: {
    NewChat: '新对话',
    deleteChat: '确定删除该对话 ?'
  },
  ChatContent: {
    history: {
      back: '返回聊天',
      history: '历史记录'
    },
    ChatCurrent: {
      back: '聊天设计室',
      placeholder: '发送消息 ...'
    },
    EmptyCurrent: {
      title: '解锁AI的力量',
      subTitle: '与 AI 聊天-与我们一起体验AI的力量',
      list: ['帮我设计一个简单的房间', '设计一个有桌子和椅子的房间', '设计一个具有科技风格的3M*6M展位']
    }
  }
}
