export const message = {
    home: `Collov AI利用尖端人工智能将您的家居设计愿望融入生动、富有叙事性的视觉效果中，将简约与美学融合在一起。`,
    upload: `通过collov.ai体验更智能的设计方法。要开始，请简单上传您空间的照片，并选择房间类型和风格。collov.ai将生成您房间的逼真渲染。`,
    redesign: `通过collov.ai体验更智能的设计方法。要开始，请简单上传您空间的照片，并选择房间类型和风格。collov.ai将生成您房间的逼真渲染。`,
    eraser: `魔法橡皮擦可以帮助您移除一切；您只需使用画笔工具选择要移除的区域。`,
    editing: `通过collov.ai体验更智能的设计方法。要开始，请简单上传您空间的照片，并选择房间类型和风格。collov.ai将生成您房间的逼真渲染。`,
    furniture: `此家具图像仅供参考；不能保证AI生成的版本100%相似。`,
    partial: `选择要更改的房间部分，我们的工具会进行魔法般的重新设计，仅重新设计该区域。这是在不重新做一切的情况下刷新您空间的简单方式。`,
    WALL_FLOOR_CEILING: `厌倦了房间的颜色，想改变整体风格？改变墙壁和地板的颜色可能是个好主意。选择要替换的建筑部分，然后让我们的工具将墙壁和地板涂成新的颜色。`,
    ALL_IN_ONE: `整合家具、橱柜、壁纸、地板，利用人工智能将这些元素无缝融入到实际环境中。`,
    kitchenRemodeling: `上传您的厨房照片，选择您喜欢的橱柜风格，我们的AI将创建一个定制的厨房渲染，具有现代化、技术增强的橱柜设计，展示未来感的美学和适合您空间的智能功能。`,
    train: `快速修改图像中的家具，您可以定制要替换的家具风格。`,
    addItem: '添加物品',
    lighting: '更改照明',
    detection: '家具检测',
    DIY: '心情板DIY',
    Upscale: '放大',
    'TETX_2_IMG': `AI 室内设计是一种先进的技术，允许用户通过对话界面从文本描述中生成图像。通过向AI提供详细的提示，用户可以看到他们的文字立即转化为视觉艺术。这种自然语言处理和生成艺术的结合为普通用户和专业人士提供了创造性的可能性。`,
    'MIDJOURNEY_V6': 'AI interiorGen是一种尖端技术，它允许用户通过对话界面从文本描述中生成图像。通过向人工智能提供详细的提示，用户可以即时看到他们的文字被转化为视觉艺术。这种自然语言处理和生成艺术的结合为休闲用户和专业人士提供了创造性的可能性。',
    'FLOORPLAN_GPT':'平面图上传：用户可以将他们的平面图上传到我们的网站。这一独特功能允许根据提供的平面图可视化房间。它将基本的建筑图纸转变为更易理解和互动的视觉表达，使用户更容易想象他们的空间。',
    'DRAFT_IMAGE':'草图和想象AI设计定制：在房间可视化之后，用户可以灵活进行对可视化空间的任何修改。我们的平台支持实时更新，确保用户所做的任何更改立即反映在视觉表示中。这一功能使用户可以尝试不同的布局、装饰和风格，为房间规划和设计提供了一个动态且用户友好的界面。',
    'INTELIGENT_CUSTOMER_SERVICER':'设计小白，秒变设计大师；客服小白，秒变百万销冠🏆',

    ChatDesigner: `AI 聊天生成图片是一种先进的技术，允许用户通过对话界面从文本描述中生成图像。通过向AI提供详细的提示，用户可以看到他们的文字立即转化为视觉艺术。这种自然语言处理和生成艺术的结合为普通用户和专业人士提供了创造性的可能性。`,
    GenVideo: `这个工具是一种创新的视频生成软件，可以根据您的描述创建一个2秒的视频片段。只需输入您的想法、概念或场景，该工具使用先进的AI算法解释和将您的文字转化为简短、动态的视频。`,
    ProductVideoStoryboard: `产品故事板提供家居设计和产品的视频故事线开发和关键帧可视化，确保以专业而简洁的方式呈现引人入胜的叙述和有影响力的视觉效果。`,
    fastPreserved: `通过collov.ai体验更智能的设计方法。要开始，请简单上传您空间的照片，并选择房间类型和风格。collov.ai将生成您房间的逼真渲染。`,
    realTimeDesign: `AI动力室内设计工具：在左侧调整设置，在右侧以并排比较的方式立即看到变化。`,
    generateTips:'如果涉及到图像生成，每张图大约需要45秒',
    Today:'今天',
    Yesterday:'昨天',
    Previous:'前7天',
    comingSoonText:'我们目前正在努力创造一些了不起的东西。这将很快到来。',
    GEN_BGIMAGE: 'AI 可在 30 秒内轻松打造迷人的产品背景，增强视觉吸引力并精确讲述故事。'
};
