export const floorPlan={
    searchTitle:'按地址搜索',
    searchMessage:'您可通过输入地址或上传适合您住所的平面图进行定制，也可使用我们的模板进行定制。',
    searchUpload:'自己上传',
    placeholder:'输入您的地址',
    placeholderSize:'请输入您的房间尺寸',
    sqft:'平方英尺',
    m2:'平方米',
    ft:'英尺',
    Length:'长度',
    m:'米',
    or:'or',
    try:'尝试我们的模板',
    builtTitle:'建筑面积与比例',
    builtText:`请输入您想设计的房间面积，或使用比例来定义空间的大小。`,
    builtTab:{
        area:'空间面积',
        scale:`比例尺`,

    },
    tabs:{
        Rendering:'渲染图',
        Panorama:'全景',
    },
    '2D':'2D',
    '3D':'3D',
    'rendering':'渲染图',
    upload:'重新上傳房型图',
    scale:'更改比例尺',
    updateRoom:'变更房型',
    updateDesign:'修改设计规划',
    cameraFov:'选择摄像机视角',

}