<template>
  <div class="layout-main-frame">
    <slot></slot>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  setup() {
    return {}
  }
})
</script>
<style lang="less" scoped>
.layout-main-frame {
  width: 100%;
  padding-left: 44px;
  padding-right: 44px;
  box-sizing: border-box;
}
@media screen and(max-width: 480px) {
.layout-main-frame {
  width: 100%;
  padding-left: 24px!important;
  padding-right: 24px!important;
  box-sizing: border-box;
}
}
</style>
