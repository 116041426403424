export const pending = {
    maskTip: '提示：遮罩区域',
    maskTipText: '使用刷子或手指在图像上绘制一个遮罩，以重新设计图像的区域。',
    pickTitle: '请选择最令人印象深刻的 AI 生成图像之一。',
    status: {
        ready: '您的任务正在等待处理',
        wait: [
            '您当前是队列中的第',
            '位用户，等待处理中。（预计时间：',
            '分钟'
        ],
        // start: '正在生成渲染图等待{time}秒'
        start: '正在生成渲染图等待15秒'
    },
    failTip: '生成繁忙，请稍后再试',
    upgrade: [
        '升级到专业版，享受',
        '更快的生成速度',
        '和',
        '跳过队列',
        '立即升级',
    ]
};
