export default {
  Banner: {
    title: 'World’s First Real Furniture Replacement',
    subtitle: 'Instant Makeover, Infinite Possibilities, With AI',
    info: 'Experience the magic of instant furniture makeover, seamlessly replacing old pieces with stylish options from real vendors, all in just seconds. ',
    btn1: 'Join Now',
    btn2: 'Try Demo'
  },
  Bridging: {
    label: ['Bridging', 'Design', 'and', 'E-commerce'],
    info: 'Experience the ease of selecting the perfect furniture for your interiors, accompanied by direct e-commerce links, to bring your design vision to life while effortlessly integrating shopping convenience. Unlock a world where interior design meets instant access to purchase.'
  },
  Tools: [
    {
      title: '60% Increase',
      info: 'in sales efficiency and revenue'
    },
    {
      title: '50% Reduce',
      info: 'In-house design cost'
    },
    {
      title: '5x Increase',
      info: 'in customer engagement'
    }
  ],
  h2: {
    title: 'Create unique design and sales experiences as exceptional as your products',
    info: 'Revolutionize Your Space: AI-Enhanced Furniture Transformation'
  },
  TextImage: [
    {
      title: 'Smart AI Furniture Detection',
      info: 'Begin your design journey with ease: simply upload a photo or rendering. Our AI smartly identifies and precisely selects furniture for replacement, setting the stage for a flawless transformation.'
    },
    {
      title: 'Turn SKUs into <br> AI-Designed Spaces',
      info: 'Dive into a vast selection of furniture categories from trusted vendors. We turn each SKU into a purchasable digital asset, ready for AI to craft your ideal space with unparalleled variety.'
    },
    {
      title: 'Controlled Furniture Customization',
      info: `Master the details with advanced structure control, focusing on specific furniture pieces for replacement. Easily compare before and after views, enjoying a seamless transition to your newly envisioned space.`
    },
    {
      title: 'Beyond Simple <br> Furniture Replacement',
      info: `Our intelligent AI doesn’t just swap out furniture; it reimagines your space for a perfect design match and setting fit, bringing your interior visions to life effortlessly.`
    },
    {
      title: 'More Than Just Furniture',
      info: 'Seamlessly integrates with Collov’s All-in-One AI design platform, offering expansion into cabinets, flooring, and walls, alongside intelligent design matching to effortlessly complement your furniture selections.'
    }
  ],
  Furniture: [
    {
      tag: 'Four Hands',
      title: 'DOM SOFA',
      price: 'Price: $2,190',
      size: 'Size:85.00"w x 39.50"d'
    },
    {
      tag: 'Article.',
      title: 'Otio Mist Gray',
      price: 'Price: $699',
      size: 'Size:33"H x 26"W x 36"D'
    }
  ],
  share: 'Visit product page',
  More: {
    title: 'More Than Just Walls',
    info: `Seamlessly integrates with Collov’s All-in-One AI design platform, offering expansion into cabinets, flooring, and furniture, alongside intelligent design matching to effortlessly complement
    your wall selections.`,
    icons: ['All-in-One Platform', 'Intelligent Design Matching', 'Multi-surface Supported']
  },
  PickPlan: {
    title: 'Pick the best plan for you',
    item1: {
      title: 'Furniture Replacement',
      info: '10-minute setup with a code-free template.',
      mo: ' / mo ',
      currency: '$',
      price: '250',
      ps: '(Billed Annually)',
      btn: 'Get Started'
    },
    item2: {
      title: 'AI Desk',
      info: 'Unlock Full Power with Collov AI Software Plans Above.',
      mo: '',
      currency: '$',
      price: '2,500',
      ps: '',
      btn: 'Get Started'
    }
  }
}
