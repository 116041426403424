export default {
  Introduce: {
    title: 'Partial Redesign with Controllable AI',
    subtitle: 'Remodel Partially, Revel Completely',
    info: `With the power to auto-mask and modify specific areas of a room—from furniture to flooring to walls—while preserving the essence of the original design, Collov AI delivers tailored renovations in mere seconds. `,
    btn1: 'Join Now',
    btn2: 'Try Demo'
  },
  tools: {
    SalesEfficiencyRate: 'Precision Customization',
    SalesEfficiency: 'Tailors specific room areas with exacting detail',
    DesignCostRate: 'Efficiency and Speed',
    DesignCost: 'Delivers fast, AI-powered transformations',
    CustomerEngagementRate: 'Character Preservation',
    CustomerEngagement: `Keeps the room's original charm intact while updating`
  },
  h2: 'The Selective Approach to Space Redesign',
  p: 'Precision and Preservation in Every Remodel',
  Transformation: {
    title: 'AI Targeted Transformation',
    info: `Utilize AI to auto-detect and precisely mask areas for remodeling, from furniture to walls to floorings, ensuring every detail is captured for a flawless transformation.`,

    cards: {
      key: {
        size: 'Size: ',
        color: 'Color: ',
        type: 'Product type: '
      },
      list: [
        {
          name: 'Rubino',
          size: '30 * 60cm',
          color: 'Terracotta or red',
          type: 'wall'
        },
        {
          name: 'Indaco',
          size: '30 * 60cm',
          color: 'Dusty blue',
          type: 'wall'
        },
        {
          name: 'Brume',
          size: '30 * 60cm',
          color: 'Light taupe or beige',
          type: 'wall'
        }
      ]
    }
  },
  Precision: {
    title: 'Diverse Design Inspirations',
    info: `Explore a vast library of room types and styles, tailored for every imaginable use case, empowering your creativity and vision.`
  },
  Visuals: {
    title: 'Instant Lifelike Visuals',
    info: `Experience ultra-realistic renderings in seconds, seamlessly integrating your redesigned space with the untouched surroundings for a cohesive look.`
  },
  Compare: {
    title: 'Precision in Preservation',
    info: `Advanced controls let you keep the essence of your space untouched while updating specific elements, balancing innovation with tradition.`
  },
  More: {
    title: 'More Than Just Walls',
    info: `Seamlessly integrates with Collov’s All-in-One AI design platform, offering expansion into cabinets, flooring, and furniture, alongside intelligent design matching to effortlessly complement
    your wall selections.`,
    icons: ['All-in-One Platform', 'Intelligent Design Matching', 'Multi-surface Supported']
  },
  PickPlan: {
    title: 'Pick the best plan for you',
    item1: {
      title: 'Wall Design',
      info: '10-minute setup with a code-free template.',
      mo: ' / mo ',
      currency: '$',
      price: '250',
      ps: '(Billed Annually)',
      btn: 'Get Started'
    },
    item2: {
      title: 'AI Desk',
      info: 'Unlock Full Power with Collov AI Software Plans Above.',
      mo: '',
      currency: '$',
      price: '2,500',
      ps: '',
      btn: 'Get Started'
    }
  }
}
