export const form = {
    nonMessage: '请输入完整的电子邮件地址和密码',
    emailMessage: '电子邮件格式：name@example.com',
    passwordMessage: '密码至少8个字符，包含1个字母和1个数字',
    first: '第一次来？',
    commentHolder: '留下评论',
    uploadTitle: '上传您自己的房间照片',
    maskTitle: '设计区域',
    mask: '选择需要重新设计的区域',
    uploaded: '已上传',
    uploadUrl: '已上传',
    email: '电话号码',
    password: '密码',
    furnishing:'家具',
    architectural:'建筑',
    passwordTip: '至少8个字符，包含1个字母和1个数字',
    style: '选择室内设计风格',
    roomType: '选择房间类型',
    emptyRoom: '是否为空房间？',
    teamStyleTitle:'你的团队风格',
    area: '选择添加物品的区域',
    selected: '已选择',
    productImage: '指定您喜欢的家具风格',
    colorMaterial: '指定您喜欢的颜色和材料',
    mode: '选择模式',
    PRESERVED: '结构保留',
    WELL: '精心设计',
    YES: '是',
    NO: '否',
    PUBLIC: '公开',
    PRIVATE: '私人',
    'living room': '客厅',
    'dining room': '餐厅',
    bedroom: '卧室',
    bathroom: '浴室',
    Bathroom: '浴室',
    balcony: '阳台',
    'open living / dining room':'开放式客厅/餐厅',
    'hallway / entry':'走廊/入口',
    'kids bedroom': '儿童卧室',
    'Bedroom': '卧室',
    'Living room': '客厅',
    'home office': '书房',
    hallway: '走廊',
    kitchen: '厨房',
    outdoor: '户外',
    patio: '庭院',
    "walk-in closets": '步入式衣橱',
    "entrance hall": '门厅',
    "family room": '家庭房',
    "others": '其他',
    "study room": '书房',
    "sun room": '阳光房',
    "game room": '游戏房',
    "porch": '门廊',
    "laundry room": '洗衣房',
    "storage room": '储藏室',
    'garage': '车库',
    'recreation room': '娱乐室',
    "childrenRoom": '儿童房',
    "guest room": '客房',
    "cloakroom": '衣帽间',
    "gym": '健身房',
    "home theater": '家庭影院',
    "garden": '花园',
    "attic": '阁楼',
    "basement": '地下室',
    "open office area": '开放式办公区',
    "meeting room": '会议室',
    "lecture hall": '讲堂',
    "lounge": '休息室',
    "toilet": '卫生间',
    "reception area": '接待区',
    "reference room": '参考室',
    "engine room": '引擎室',
    "equipment room": '设备室',
    "sales area": '销售区',
    "dining area": '用餐区',
    "warehouse": '仓库',
    "locker room": '更衣室',
    "entertainment area": '娱乐区',
    "checkout counter": '结账柜台',
    "rest area": '休息区',
    "waiting area": '等候区',
    "display showroom": '展示展厅',
    "restaurant": '餐厅',
    "clothing store": '服装店',
    "shoe store": '鞋店',
    "jewelry store": '珠宝店',
    "home improvement store": '家居装修店',
    "beauty shop": '美容店',
    "personal care shop": '个人护理店',
    "electronics store": '电子产品店',
    "bookstore": '书店',
    bohemian: '波西米亚',
    coastal: '海岸',
    farmhouse: '农舍',
    eclectic: '折中',
    scandinavian: '北欧',
    modern: '现代',
    transitional: '过渡',
    'mid-century': '中世纪',
    'halloween': '万圣节',
    'new chinese': '新中式',
    'french': '法式',
    "light luxury": '轻奢',
    "wabi-sabi": '侘寂',
    minimalist: '极简主义',
    "natural wood": '天然木',
    creamy: '奶油风',
    "modern minimalist": '现代极简主义',
    "nordic": '北欧',
    contemporary: '当代',
    industrial: '工业风',
    country: '乡村',
    rustic: '乡村风',
    minimalism: '极简主义',
    chinese: '中式',
    'urban chinese': '都市中式',
    retro: '复古',
    vintage: '复古',
    'japanese': '日式',
    "neoclassical": '新古典',
    'american country': '美式乡村',
    'soft': '柔和',
    'art deco': '装饰艺术',
    "hi-tech": '高科技',
    'italian': '意大利',
    "mid-century modern": '中世纪现代',
    "shabby chic": '宜家风',
    'japandi': '和风现代',
    'colonial': '殖民地风',
    'hollywood regency': '好莱坞风',
    "regency moderne": '摩登风',
    "mediterranean revival": '地中海复兴',
    rococo: '洛可可',
    "late baroque": '后巴洛克',
    "hollywood glam": '好莱坞魅力',
    "mediterranean": '地中海',
    "southwestern": '西南风',
    "french country": '法式乡村',
    "asian zen": '亚洲禅',
    "modern farmhouse": '现代农舍',
    african: '非洲',
    amish: '阿米什',
    arabian: '阿拉伯',
    "art moderne": '现代艺术',
    "art nouveau": '新艺术',
    "artisan": '手艺人',
    "arts & crafts": '工艺美术',
    "baroque": '巴洛克',
    "cuban": '古巴',
    "gothic": '哥特式',
    "moroccan": '摩洛哥',
    "indian": '印度',
    tropical: '热带风',
    'biophilic': '生态',
    'cottagecore': '田园风',
    'maximalist': '极致主义',
    gaming: '游戏风',
    cyberpunk: '赛博朋克',
    vaporwave: '蒸汽波',
    'ski chalet': '滑雪小屋',
    'tribal': '部落风',
    'medieval': '中世纪',
    'easter': '复活节',
    'christmas': '圣诞节',
    'chinese new year': '中国新年',
    'hot pink': '艳粉色',
    'neoclassic': '新古典',
    'victorian': '维多利亚风',
    other:'其他',

    /*房间类型分类*/
    "Room Type": "房屋类型",
    "Kitchen Styles": "颜色和材质",
    "Primary Living Spaces": "主要居住空间",
    "Common Areas": "公共区域",
    "Outdoor": "户外",
    "Special Purpose Rooms": "特殊用途房间",
    "Additional Spaces": "额外空间",
    "Office Spaces": "办公空间",
    "Public area": "公共区域",
    "Commercial and Retail Spaces": "商业和零售空间",

    /*风格类型分类*/
    Festival: "节日风",
    Classics: "经典风",
    International: "官方的",
    Modern: "现代风",
    Scandivian: "北欧风",
    Industrial: "工业风",
    Farmhouse: "农舍风",
    Retro: "复古风",
    Historical: "历史风",
    Glam: "奢华风",
    Gaming: "游戏风",
    "Mid-Century": "中世纪风",
    Transitional: "过渡风",
    Tropical: "热带风",
    Eclectic: "折衷主义风",
    "name": "名称",
    "greeting": "问候",
    instruction: '指南',
    conversationStarters: '开场白',
    greetingMessage: '您的角色的第一条消息。',
    "nameMessage": "您角色的第一条消息",
    DescriptionDes: '在这里描述角色的个性',
    "Capabilities": "能力",
    "describeMessage": "描述您的创作",
    LastName: '姓氏',
    LastNameInput: '请输入您的姓氏。',
    FirstName: '名字',
    FirstNameInput: '请输入您的名字',
    Username: '用户名',
    UsernameMessage: '用户名只能包含字母（a-z）、数字（0-9）、下划线（_）、破折号（-）和句号（.）',
    Description: '描述',
    DescriptionMessage: '描述您的风格。',
    SocialConnection: '社交连接',
    emailInput: '请输入您的电子邮件。',
    search: '搜索',
    messageusername:'用户名只能包含字母（a-z）、数字（0-9）、下划线（_）、短划线（-）和句点（.）',
    messagefirstName:'请输入您的名字',
    messagelastName:'请填写您的姓氏',
    coverImageRules:'请选择封面图片。',
    nameRules:'请输入名称。',
    descriptionRules:'请输入描述。',
    greetingRules:'请输入问候语。',
    instructionRules:'请输入说明。',
    ProductName: '产品名称',
    Brand: '品牌',
    Wall: "墙壁",

    viewAllProducts: '查看所有产品',
    filter: '过滤',
    done: '完成',
    viewAll: '全部查看',
    backToTop: '返回顶部',
    clearFilter: '清空过滤',
    // 产品属性
    Color: '颜色',
    ProductType:'产品类型',
    Material: '材料',
    Finish: '表面材质',
    Size: '尺寸',
    WholeRoomRedesign: '全屋设计',
    PartialRemodel: '局部改造',
    "kid’s room": '儿童房',
    'Open living / dining room':'开放式客厅/餐厅',
    'Hallway / Entry':'走廊/入口',
    "Balcony":"阳台",
    "Kitchen":"厨房"
};
