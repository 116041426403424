export const pending={
    maskTip: 'Tips: Mask area',
    maskTipText: 'Use the brush or your finger to draw a mask on the area of the image you wish to redesign.',
    pickTitle: 'Please pick one of the most impressive AI-generated images.',
    status: {
        ready: 'Your task is waiting to be processed',
        wait: [
            'You are currently the ',
            'user in the queue, waiting to be processed. (Estimated time:',
            'minutes'
        ],
        start: 'Creating magic, hang tight! ~{time}s to go.',
    },
    failTip: 'Generate Busy, please try again later',
    upgrade: [
        'Upgrade to Pro for',
        'faster generation speed',
        'and',
        'skip the queue',
        'Upgrade now',
    ]
}
