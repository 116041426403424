interface baseModel {
    id:string,
    name:string,
    products?: { image:string,prompt:string }[]
}
interface Categories extends baseModel{
    subcategories:baseModel[],
}
interface Material{
    name:string
    url:string
}
import {arr} from "@/dictionary/prompt";
export const categories:Categories[] =arr
export const materialList:Material[] = [
    {name: 'Wood Trims', url: 'https://d17axom7zrjq3q.cloudfront.net/20200325/8a306dc66bf049fe8380fbe75456431c.jpg'},
    {name: 'Tiles', url: 'https://d17axom7zrjq3q.cloudfront.net/20200324/7aafa2b8182f436b9f92f7190b2f8412.jpg'},
    {name: 'Steel', url: 'https://d17axom7zrjq3q.cloudfront.net/20200324/f160a571d9984422a10ca4906aa9ab6a.jpg'},
    {name: 'Bricks', url: 'https://d17axom7zrjq3q.cloudfront.net/20200324/be78fb4affaa40a7a314fc9163112272.jpg'},
    {name: 'Marble', url: 'https://d17axom7zrjq3q.cloudfront.net/20200324/7f586a2fefd9438d9d6493e7c15173fc.jpg'},
    {name: 'Woods', url: 'https://d17axom7zrjq3q.cloudfront.net/20200324/a571134ac7ef4fec818b389590855bcb.png'},
    {name: 'Metal', url: 'https://d17axom7zrjq3q.cloudfront.net/20200324/5d54f0cd42a0495fae6fbcb35adf0c3d.jpg'},
    {name: 'Concrete', url: 'https://d17axom7zrjq3q.cloudfront.net/20200325/9cb8d74df0494c9a9983b41a26813e3c.jpg'},
    {name: 'Leather', url: 'https://d17axom7zrjq3q.cloudfront.net/20200324/a015da1723ef4b529df58a782f0e5693.jpg'},
    {name: 'Stone', url: 'https://d17axom7zrjq3q.cloudfront.net/20200501/1510ee9d7b8f44bbbd3b457923e147e5.jpg'},
    {name: 'Fabric', url: 'https://d17axom7zrjq3q.cloudfront.net/20200501/7683b98f251548ff99fb0d0e543632b1.jpg'},
]
