export const constantRoute = [
  {
    path: '/',
    name: 'dashboard_layout',
    component: () => import('../layout/studio/index.vue'),
    redirect: '/',
    children: [
      {
        path: '/',
        component: () => import('../views/detail/index.vue'),
        name: 'dashboard_home',
        meta: { preload: true }
      },
      {
        path: '/design',
        component: () => import('../layout/design/index.vue'),
        name: 'design_layout',
        meta: { preload: true },
        redirect: '/design/index',
        children: [
          {
            path: '/design/index',
            component: () => import('pages/design/views/design/index.vue'),
            name: 'design_index',
            meta: { preload: true }
          },
          {
            path: '/design/allInOne', // 调整到自己的页面。c端不动
            component: () => import('../views/allInOne/index.vue'),
            name: 'allInOne',
            meta: { preload: true }
          },
          {
            path: '/design/all-in-one',
            component: () => import('pages/allInOne/views/home/index.vue'),
            name: 'all-in-one',
            meta: { preload: true }
          },
          {
            path: '/realTime',
            component: () => import('pages/design/views/realTime/index.vue'),
            name: 'realTime',
            meta: { preload: true }
          },
          {
            path: '/pending',
            component: () => import('pages/design/views/pending/index.vue'),
            name: 'pending',
            meta: { preload: true }
          },
          {
            path: '/result/:id',
            component: () => import('pages/design/views/result/index.vue'),
            name: 'result',
            meta: { preload: true }
          },
          {
            path: '/mine',
            component: () => import('../views/mine/index.vue'),
            name: 'mine',
            meta: { preload: true }
          },
          {
            path: '/3D',
            name: 'dashboard_3d_layout',
            component: () => import('pages/floorPlan/layout/floorPlan/index.vue'),
            redirect: '/3D/index',
            children:[
              {
                path: '/3D/index',
                component: () => import('pages/floorPlan/views/search/index.vue'),
                name: 'layout_search'
              },
              {
                path: '/3D/space',
                component: () => import('pages/floorPlan/views/space/index.vue'),
                name: 'space'
              },
              {
                path: '/3D/pending',
                component: () => import('pages/floorPlan/views/pending/index.vue'),
                name: 'pending'
              },
              {
                path: '/3D/plan',
                component: () => import('pages/floorPlan/views/plan/index.vue'),
                name: 'plan'
              },
              {
                path: '/3D/fov',
                component: () => import('pages/floorPlan/views/camerafov/index.vue'),
                name: 'fov'
              },
              {
                path: '/3D/rendering',
                component: () => import('pages/floorPlan/views/rendering/index.vue'),
                name: '3D_rendering',
                meta:{hideNav:true}
              },
              {
                path: '/3D/preview',
                component: () => import('pages/floorPlan/views/preview/index.vue'),
                name: '3D_preview',
                meta:{hideNav:true}
              },
            ]
          },
          {
            path: '/3D/test',
            component: () => import('pages/floorPlan/views/test/index.vue'),
            name: '3D_test',
            meta:{hideNav:true}
          }
          
        ]
      }
    ]
  },
  {
    path: '/chat',
    component: () => import('../views/chat/index.vue'),
    name: 'chat',
    meta: { preload: true }
  },
  {
    path: '/upload',
    component: () => import('../views/upload/index.vue'),
    name: 'upload',
    meta: { preload: true }
  }, {
    path: '/experience',
    component: () => import('../views/experience/index.vue'),
    name: 'experience',
    meta: { preload: true }
  },
]
