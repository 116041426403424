export const realtime={
    action:{
        fixedBg:'Fixed bg',
        clear:'clear',
        add:'Add',
        change:'Change',
        select:'Select',
        enhance:'Enhance',
        tools:'Tools',
        control:'Control',
        freeStyle:'Free Style',
        backgroundColor:'Background Color',
        controlStrength:'Control Strength',
        aiStrength:'Ai Strength',
        roomType:'Room Type',
        autoFit:'AutoFit',
        outputToInput:'Output to Input',
        uploadBg:'Upload Background',
        SaveSetting:'Save Setting',
        theme:"Theme",
        GenerateVideo:"Generate Video",
        GenerateGIF:"Generate GIF",
        beforeAfter:"Before/After",
        download:"Download",
    },
    title:{
        wantMerge:'Are you sure you want to merge the object with the background？',
        merge:'Merge',
        cancel:'Cancel',
        realtime:'Real-time',
        freezed:'Freezed',
        createByUser:'Created by user',
        draw:'Draw',
        inpaint:'Inpaint',
        liveUpdates:'Live Updates',
        topOfDay:'Top of the Day',
        topOfWeek:'Top of the Week',
        allTimesLeadersShip:'All Time Leadership',
        Official:'Official',

    },
    category:{
        Lighting:'Lighting',
        Light:'Light',
        Interior:'Interior',
        Architect:'Architect',
        Floorplan:'Floorplan & 3D',
        'Costume Design':'Costume Design',
        'Footwear Design':'Footwear Design',
        'Wood Design':'Wood Design',
        'Fashion Design':'Fashion Design',
        'Package Design':'Package Design',
        'Character Design':'Character Design',
        'Manga':'Manga',
        'Webcam':'Webcam',
    }


}
