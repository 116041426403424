<template>
  <login-input :name="$t('form.email')" type="text" v-model:value="client.form.email">
    <svg
      viewBox="64 64 896 896"
      data-icon="mobile"
      width="1em"
      height="1em"
      fill="currentColor"
      aria-hidden="true"
      focusable="false"
      class=""
    >
      <path
        d="M744 62H280c-35.3 0-64 28.7-64 64v768c0 35.3 28.7 64 64 64h464c35.3 0 64-28.7 64-64V126c0-35.3-28.7-64-64-64zm-8 824H288V134h448v752zM472 784a40 40 0 1 0 80 0 40 40 0 1 0-80 0z"
      ></path>
    </svg>
  </login-input>
  <login-input
    name="图形验证码"
    type="text"
    v-model:value="client.form.code"
    style="margin-top: 24px"
  >
    <div class="login-form-input-icon-yzm cursor" @click="codeBtn()">
      <img :src="codeUrl" alt="" v-if="codeUrl" />
    </div>
  </login-input>
  <login-input
    class="code-wrap"
    name="手机验证码"
    type="text"
    v-model:value="client.form.mobileCode"
    style="margin-top: 24px"
  >
    <template #right>
      <div>
        <c-button
          :loading="sendLoading"
          type="text"
          v-if="canSendCode"
          style="color: #1890ff; top: 0; padding: 0"
          @click="sendMobileCode()"
          >获取手机验证码
        </c-button>
        <span v-if="!canSendCode">等待 {{ countdown }} 秒后可再次发送</span>
      </div>
    </template>
  </login-input>
  <login-input
    :name="$t('form.password')"
    v-if="loginType === 'register'"
    :type="type"
    v-model:value="client.form.password"
    style="margin-top: 24px"
  >
    <div style="opacity: 0.6; transform: scale(0.9)" class="cursor" @click="changeType">
      <img src="@/assets/icons/eye.svg" alt="" class="cursor" v-if="type === 'password'" />
      <img src="@/assets/icons/eye-close.svg" class="cursor" alt="" v-else />
    </div>
  </login-input>
</template>
<script setup lang="ts">
import { clientStore } from '@/stores/client'
import LoginInput from '../loginInput.vue'
import { defineProps, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { requestGetValidCode } from '@/apis/client'
const props = defineProps(['loginType'])
const codeUrl = ref('')
const client = clientStore()
const sendLoading = ref(false)
const canSendCode = ref(true)
const countdown = ref(60)
const type = ref<'text' | 'password'>('password')
const changeType = () => {
  const val = type.value === 'password' ? 'text' : 'password'
  type.value = val
}
const codeBtn = async () => {
  let baseURL = import.meta.env.VITE_BASEURL
  if (import.meta.env.VITE_LANG === 'zh') {
    baseURL = 'https://collov.cn/flair/web/'
  }
  codeUrl.value =
    baseURL +
    'kaptcha/getCode?type=' +
    props.loginType +
    '&_t=' +
    new Date().getTime() +
    '&key=' +
    client.token
}
const sendVerificationCode = async () => {
  canSendCode.value = false
  const timer = setInterval(() => {
    if (countdown.value > 0) {
      countdown.value--
    } else {
      clearInterval(timer)
      canSendCode.value = true
      countdown.value = 60 // 重置倒计时
    }
  }, 1000)
}
const sendMobileCode = () => {
  const mobile = client.form.email
  const code = client.form.code
  if (mobile && code) {
    const params = {
      mobile,
      code,
      type: props.loginType
    }
    sendLoading.value = true
    requestGetValidCode(params).then((res) => {
      sendLoading.value = false
      codeBtn()
      if (!res.success) {
        ElMessage.error(res.message)
      } else {
        sendVerificationCode()
        return ElMessage.success(res.message || '发送成功')
      }
    })
  } else if (!mobile) {
    ElMessage.error('请输入手机号')
  } else if (!code) {
    ElMessage.error('请输入验证码')
  }
}
codeBtn()
</script>
<style scoped lang="less">
.login-form-input-icon-yzm {
  position: absolute;
  right: 0;
  top: -3px;
  width: 72px;
  height: 25px;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
