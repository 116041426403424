import {defineStore} from 'pinia'
import {
    reqCusTools,
    requestLogin,
    requestUserInfo,
    requestGetLoginData,
    getMuseclipLoginData,
    requestCheckOauthLogin,
    requestLogout,
    requestRegister,
    requestLoginByPhone,
    requestLoginByPhoneValid,
    requestRegisterByMobile, reqListModules, reqSaveView,
    listIntegrateSaveTrainStyles
} from '@/apis/client'
import type {UserInfoRes} from '@/apis/client/type'
import {requestGetMember} from '@/apis/design'
import {designStore} from '@/stores/design'
import RTB from '@/hooks/RTB'
import {goGptHome} from '@/hooks/util'
import {isBusinessEndUser} from '@/utils/index.ts'
import {reqGetCurrentUserTeam, reqGetMemberlist, reqGetTeam} from '@/apis/team'
import {useEventBus} from '@vueuse/core'
import {useI18n} from 'vue-i18n'

const bus = useEventBus<string>('getTeam')
export type ALLInOneMode = 'CLIENT_SIDE' | 'ENTERPRISE' | 'MODEL_PREVIEW' | ''

interface State {
    userInfo: Auth.UserInfo | null
    cusTools: null
    appsList: null
    teamId: string
    theme: 'light' | 'dark'
    teamInfo: {}
    teamMembers: []
    token: string | null
    loginVisible: boolean
    loginType: string
    realStyle: []
    realStyleFinish: boolean
    realStyleLoading: boolean
    upgrade: {
        visible: boolean
        payVisible: boolean
        paymentId: string
        payType: string
        index: number
        initIndex: number
    }
    loading: boolean
    form: {
        email: string
        password: string
        code: string
        mobileCode: string
    }
    upgradeTip: boolean
    memberInfo: Partial<{
        hasOverDue: boolean
        aiRemainCount: number
        subscriptionRemainCount: number
    }>
    country: string
    errMessage: string
    timestamp: number
    isCloseIframe: boolean,
    allInOneMode: ALLInOneMode
    modelPreviwUsername: string
    modelPreviewData: object | null,
    lang: string,
    domain?:string,
    inited: boolean
    loginCallbacks: Function[]
}

export const clientStore = defineStore('client', {
    persist: {
        key: `${import.meta.env.VITE_CONFIG_TYPE}_ai_client`,
        storage: localStorage,
        paths: ['token', 'userInfo', 'memberInfo', 'teamInfo', 'teamId', 'realStyle']
    },
    state: (): State => {
        return {
            userInfo: null,
            cusTools: null,
            appsList: null,
            teamId: '',
            theme: 'light',
            teamInfo: {},
            teamMembers: [],
            token: '',
            loginVisible: false,
            loginType: 'login',
            realStyle: [],
            realStyleFinish: false,
            realStyleLoading: false,
            upgrade: {
                visible: false,
                payVisible: false,
                paymentId: '',
                payType: '',
                index: 1,
                initIndex: 0
            },
            loading: false,
            form: {
                email: '',
                password: '',
                code: '',
                mobileCode: ''
            },
            upgradeTip: false,
            memberInfo: {},
            country: '',
            errMessage: '',
            timestamp: new Date().getTime(),
            isCloseIframe: false,
            allInOneMode: '',
            modelPreviwUsername: '',
            modelPreviewData: null,
            lang: 'en',
            domain:'',
            inited: false,
            loginCallbacks: []
        }
    },

    getters: {
        avatar: (state) => {
            return state.userInfo?.visitUrl
        },
        isDarkTheme: (state) => {
            return state.theme === 'dark'
        },
        hasLogin: (state) => {
            return state.userInfo?.email ? true : false
        },
        member: (state) => {
            const keys = Object.keys(state.memberInfo)
            const isMember = !!(keys.indexOf('hasOverDue') > -1 && !state.memberInfo?.hasOverDue)
            return state.userInfo?.email ? isMember : false
        },
        credits: (state) => {
            const aiRemainCount = state.memberInfo.aiRemainCount || 0 //非会员积分
            const count = state.memberInfo.subscriptionRemainCount || 0
            return count > 2000 ? 'Unlimited' : count + aiRemainCount
        },
        creditNum: (state) => {
            return state.memberInfo.subscriptionRemainCount || 0
        }
    },
    actions: {
        async getMember() {
            const res = await requestGetMember()
            this.memberInfo = res
            this.upgradeTip = false
            return new Promise((resolve) => resolve(true))
        },
        async changeTheme(val: Custom.Theme) {
            this.theme = val || 'light'
            document.documentElement.setAttribute('class', this.theme)
        },
        async getUserTeam() {
            const res = await reqGetCurrentUserTeam()
            this.teamInfo = res.data || {}
            this.teamId = res.data ? res.data.id : ''
            bus.emit('getTeam', this.teamId)
            if (res.data) {
                const res2 = await reqGetMemberlist({teamId: res.data.id})
                if (res2.data) {
                    this.teamMembers = res2.data
                }
            }
            return new Promise((resolve) => resolve(this.teamInfo))
        },
        setToken(token: string | null) {
            this.token = token
        },
        vaildateForm(locale: Custom.Lang, loginType: string) {
            this.errMessage = ''
            const emailPattern = locale === 'zh' ? new RegExp('^1[3456789]\\d{9}$') : /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
            const passwordPattern = new RegExp('^(?=.*[A-Za-z])[A-Za-z\\d$@$!%*#?&_=+-/]{8,}$')
            // || loginType !== 'register'
            if (loginType === 'code') {
                if (!this.form.email || !this.form.mobileCode) {
                    this.errMessage = '请填写完整的手机号码和验证码'
                    return false
                } else if (!emailPattern.test(this.form.email)) {
                    this.errMessage = locale === 'zh' ? '请输入正确的手机号码' : 'Email enter in the format: name@example.com.'
                    return false
                }
            } else {
                if (!this.form.email || !this.form.password) {
                    this.errMessage = locale === 'zh' ? '请填写完整的手机号码址和密码' : 'Please enter your full email address and password'
                    return false
                } else if (loginType === 'register' && locale === 'zh') {
                    if (!this.form.mobileCode) {
                        this.errMessage = '请输入正确的手机验证码'
                        return false
                    }
                } else if (!emailPattern.test(this.form.email)) {
                    this.errMessage = locale === 'zh' ? '请输入正确的手机号码' : 'Email enter in the format: name@example.com.'
                    return false
                } else if (!passwordPattern.test(this.form.password)) {
                    this.errMessage =
                        locale === 'zh'
                            ? '密码必须至少包含8个字符，并且至少包含1个字母和1个数字。'
                            : 'password enter 8+ characters with 1 letter and 1 number'
                    return false
                }
            }

            return true
        },
        async register(locale: Custom.Lang) {
            const status = this.vaildateForm(locale, 'register')
            if (!status || this.loading) {
                return new Promise((resolve, reject) => reject(false))
            }
            try {
                this.loading = true
                let res: any = null
                if (locale === 'zh') {
                    res = await requestRegisterByMobile({
                        phoneNumberWx: this.form.email,
                        password: this.form.password,
                        code: this.form.mobileCode
                    })
                } else {
                    res = await requestRegister({...this.form})
                }
                this.loading = false
                if (res.success) {
                    this.loginType = 'created'
                    RTB.recordRTBRegistered(this.form.email)
                } else {
                    this.loading = false
                    this.errMessage = res.message || 'error'
                }
            } catch (e) {
                this.loading = false
            }
        },
        generateRandomKey(length: number) {
            const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
            let key = ''
            for (let i = 0; i < length; i++) {
                const randomIndex = Math.floor(Math.random() * charset.length)
                key += charset.charAt(randomIndex)
            }
            return key
        },
        getCode(routestr: string) {
            this.domain=routestr
            const randomPrefix = this.generateRandomKey(10)

            const timestamp = new Date().getTime() // 获取当前时间戳
            const combinedValue = timestamp + '|' + encodeURIComponent(routestr) + '|' + randomPrefix
            // 将组合值进行Base64编码
            return btoa(combinedValue)
            // const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            // let key = "";
            // for (let i = 0; i < length; i++) {
            //     const randomIndex = Math.floor(Math.random() * charset.length);
            //     key += charset.charAt(randomIndex);
            // }

            // const timestamp = new Date().getTime(); // 获取当前时间戳
            // return btoa(timestamp + '|' + encodeURIComponent(routestr) + '|' + key);
        },
        listModules(code) {
            reqListModules(code).then(res => {
                this.appsList = res
            })
        },
        async IntegrateLogin() {
            if(window.location.pathname.includes('experience')) return
            if (this.loading) {
                return new Promise((resolve, reject) => reject(false))
            }
            const url = window.location.href
            const params = new URLSearchParams(new URL(url).search)
            const {locale} = useI18n()
            const search = location.search.includes('route=') ? location.search : localStorage.getItem('home_query_search') || ''
            if (search) {
                localStorage.setItem('home_query_search', search)
            }
            const urlParams = new URLSearchParams(search)
            const routestr = urlParams.get('route')
            const configId = urlParams.get('configId')||''
            let route_code = ''
             if (routestr) {
                route_code = this.getCode(routestr)
            } else {
                let domain = location.hostname
                if (!domain || ['127.0.0.1', 'localhost'].includes(domain)) {
                    domain = 'collov.ai'
                }
                route_code = this.getCode(domain)
            }
            try {
                this.loading = true
                const res: any = await reqCusTools({
                    code: route_code,
                    configId:configId,
                    username: params.get('username') || '',
                    deviceId: params.get('deviceId') || '',
                })
                // this.listModules(route_code)
                if (res.success) { 
                    this.cusTools = res;
                    localStorage.setItem('cusTools', JSON.stringify(res.data))
                    // this.setToken(res.data.token)
                    this.userInfo={
                        ...this.userInfo,
                        ...res.data
                    }
                    this.loginVisible = false
                    this.loading = false
                    if (res.data.config.language) {
                        if (res.data.config.language === 'zh-CN') {
                            locale.value = 'zh'
                        } else {
                            locale.value = 'en'
                        }
                    } else {
                        const lang = params.get('lang')
                        if (lang) {
                            locale.value = lang
                        }
                    }
                    locale.value = 'zh'
                    if(!this.hasLogin){
                      this.loginVisible = true
                      this.loginType = 'login'
                      this.loginCallbacks.push(() => {
                        res.data.email = 'integrate@collov.ai'
                        this.userInfo = res.data
                        this.inited = true
                      })
                    }else{
                        const overduce = await this.isTokenOverdue()
                        if (overduce) {
                            this.loginVisible = true
                            this.loginType = 'login'
                            this.loginCallbacks.push(() => {
                              res.data.email = 'integrate@collov.ai'
                              this.userInfo = res.data
                              this.inited = true
                            })
                        } else {
                            this.inited = true
                        }               
                    }
                    this.lang = locale.value
                } else {
                    // this.loading = false
                    this.errMessage = res.message || 'error'
                    ElMessage.error(this.errMessage)
                    this.IntegrateLogOut()
                }
            } catch (e) {
                // this.loading = false
            }
        },
        // 校验token 是否过期, false为不过期，true 为过期
        isTokenOverdue() {
            return new Promise((resolve, reject) => {
                listIntegrateSaveTrainStyles({queryKey: this.modelPreviwUsername}).then(res => {
                    resolve(false)
                }).catch(e => {
                    resolve(true)

                })
            })
        },
        async saveView(type) {
            const search = location.search.includes('route=') ? location.search : localStorage.getItem('home_query_search') || ''
            if (search) {
                localStorage.setItem('home_query_search', search)
            }
            const urlParams = new URLSearchParams(search)
            const routestr = urlParams.get('route')
            let route_code = ''
            if (routestr) {
                route_code = this.getCode(routestr)
            } else {
                let domain = location.hostname
                if (!domain || ['127.0.0.1', 'localhost'].includes(domain)) {
                    domain = 'collov.ai'
                }
                route_code = this.getCode(domain)
            }
            await reqSaveView({
                code: route_code,
                type,
            })
        },
        async IntegrateLogOut() {
            this.token = ''
            localStorage.setItem('gids', '')
            localStorage.setItem('integrate_ai_client', '')
            this.userInfo = {}
            this.memberInfo = {}
            this.setToken(null)
            console.log('integrate logout')
            // const design = designStore()
            // design.refresh = !design.refresh
            await requestLogout()
        },
        async Login(locale: Custom.Lang, loginType: string) {
            const status = this.vaildateForm(locale, loginType)
            if (!status || this.loading) {
                return new Promise((resolve, reject) => reject(false))
            }
            try {
                this.loading = true
                let res: any = null
                if (locale === 'zh') {
                    if (loginType === 'code') {
                        res = await requestLoginByPhoneValid({
                            mobile: this.form.email,
                            code: this.form.mobileCode
                        })
                    } else {
                        res = await requestLoginByPhone({
                            mobile: this.form.email,
                            password: this.form.password
                        })
                    }
                } else {
                    res = await requestLogin({...this.form})
                }

                if (res.success) {
                    this.userInfo = res.data || {}
                    await this.getInfo()
                    this.getMember()
                    this.loginVisible = false
                    this.loading = false
                    this.loginCallbacks.forEach(callback=> callback())
                    this.loginCallbacks = []
                } else {
                    this.loading = false
                    this.errMessage = res.message || 'error'
                }
            } catch (e) {
                this.loading = false
            }
        },
        async LogOut(home: unknown) {
            this.token = ''
            this.userInfo = null
            this.memberInfo = {}
            this.setToken(null)
            const design = designStore()
            design.refresh = !design.refresh
            await requestLogout()
            if (home) {
                return goGptHome('logout')
            }
        },
        getInfo() {
            return requestUserInfo().then((res) => {
                return new Promise((resolve) => {
                    this.userInfo = res as unknown as UserInfoRes
                    this.loginVisible = false
                    resolve(true)
                })
            })
        },
        oauthLogin() {
            const width = 600,
                height = 600
            const top = (window.screen.height - 30 - width) / 2
            const left = (window.screen.width - 10 - height) / 2
            const tempPage = window.open('', '_blank', `width=${width},height=${height},top=${top},left=${left}`)
            /**/
            const redirect = location.pathname.indexOf('studio') > -1 ? 'studio' : 'design'
            const hostname = location.hostname
            if (hostname === 'museclip.ai') {
                getMuseclipLoginData({loginType: 'google', type: 'login', redirect}).then((res: any) => {
                    if (tempPage) {
                        tempPage.location = `https://accounts.google.com/o/oauth2/v2/auth?scope=profile email&include_granted_scopes=true&response_type=code&state=state&client_id=${
                            res.clientId
                        }&redirect_uri=${encodeURI(res.redirectUrl)}`
                    }
                })
                return
            }
            requestGetLoginData({loginType: 'google', type: 'login', redirect}).then((res: any) => {
                if (tempPage) {
                    tempPage.location = `https://accounts.google.com/o/oauth2/v2/auth?scope=profile email&include_granted_scopes=true&response_type=code&state=state&client_id=${
                        res.clientId
                    }&redirect_uri=${encodeURI(res.redirectUrl)}`
                }
            })
        },
        oauthLoginCallback(body: { type: string; auth: string }) {
            requestCheckOauthLogin(body.auth).then((res: any) => {
                if (res && res.success) {
                    this.userInfo = res.data || {}
                    this.loginVisible = false
                    this.loading = false
                    this.getInfo()
                    this.getMember()
                }
            })
        },
        showLogin() {
            this.loginVisible = true
            this.loginType = 'login'
        },
        showUpgrade() {
            if (!this.hasLogin) {
                return this.showLogin()
            }
            this.upgrade.visible = true
            this.upgrade.payVisible = false
            this.upgrade.paymentId = ''
            this.upgrade.payType = ''
            this.upgrade.index = 1
        },
        onCloseIframe(isCloseIframe: boolean) {
            this.isCloseIframe = isCloseIframe
        },
        closeIframe(){
          window.parent.postMessage('closeIframe', "*");
        },
    }
})
