export const menu = {
    redesign: '重新设计',
    dashboard_pricing: '定价',
    backToHomepage: '返回首页',
    creditHistory: '积分历史记录',
    billingInformation: '账单信息',
    billingHistory:'付款历史记录',
    myDesign: '我的设计',
    enterprise: '企业合作',
    pricing: '定价',
    community: '社区',
    DesignGallery: '设计画廊',
    Stylecommunity: '风格社区',
    designer: '需要设计师帮助？',
    account: '我的账户',
    enterpriseManage: '企业管理',
    Profile: '个人信息',
    dashboard_home: '主页',
    dashboard_apps:'应用',
    dashboard_tools: 'AI工具',
    dashboard_myAssets: '我的项目',
    dashboard_enterprise: '企业',
    dashboard_team: '团队',
    freePackage: '免费套餐',
    dashboard_publish: '发布',
    roomType: '房间类型',
    userStyle: '用户风格',
    'Feature': '功能',
    'Usage': '用量',
    'AI Design': 'AI设计',
    'Instant Home Redesign': '即时家居重新设计',
    'Kitchen Remodeling': '厨房改造',
    'Partial Modification': '部分修改',
    'Your Design Model': '您的设计模型',
    'Chat Designer': '聊天设计师',
    'Product Storyboard': '产品故事板',
    'InteriorGen': '室内设计生成',
    'Image Up-scale': '图像放大',
    'Visual Assistant': '视觉助手',
    'Remove Background': '去除背景',
    'Item Perfection': '背景填充',
    'Short video generation':'短视频生成',
    'No Watermark':'无水印',
    'Real time design change style & color':'实时设计切换风格和颜色',
    'New real time design project':'新建实时设计项目',
    perGeneration:'每次生成',
    Ask:'每次提问',
    message:'每条消息',
    AIApplications:'AI应用程序',
    developmentTools:'开发工具',
    database:'数据库',
    Amount:'金额',
    Date:'日期',
    Status:'状态',
    'Payment Method':'付款方式',
    'Service':'服务',
    'Cost':'花费',
    'Remaining':'余额',
    FAILED:'失败',
    Paid:'成功',
    dashboard_community: '社区',
    Design: '设计',
    Assistant: '助手',
    Product: '物品',
    BETA: '测试版',
    "TermsConditions": "条款和条件",
    "TermsofService": "服务条款",
    "PrivacyPolicy": "隐私政策",
    /*chat*/
    "replaceFurniture": "更换这个家具",
    "textDesign": "设计一个白墙、灰色沙发、浅木地板的现代客厅",
    "textDesignwooden": "设计一个带有木横梁、石墙、农家水槽和奶油橱柜的乡村厨房",
    "textDesignoffice": "设计一间优雅的办公室，配有桃花心木桌子、皮椅、内置书架、豪华地毯，并且可以俯瞰花园的窗户。",
    "GenVideo": "一位女士正在现代厨房里烹饪",
    "GenVideobedroom": "阳光下的现代卧室",
    "GenVideoparty": "展示布置一个用于派对的庭院",
    "ChatDesigner": "请为我设计一间带有特大床和梳妆台的现代卧室",
    "ChatDesignerroom": "请重新设计我的房间",
    "ChatDesignerlivingroom": "请为我的客厅设计",
    "INTELIGENT_CUSTOMER_SERVICER_1":'请告诉我目前销售最火爆的商品是哪个？',
    "INTELIGENT_CUSTOMER_SERVICER_2":'请给我推荐下目前最流行的风格',
    "INTELIGENT_CUSTOMER_SERVICER_3":'请给我推荐一款预算在一万块钱以内的商品',
    "ProductVideoStoryboard": "为我的新厨房设计一个广告脚本",
    "ProductVideoStoryboardColor": "颜色心理学室内设计，面向年轻人，现代，5个关键帧",
    "ProductVideoStoryboardPerfume": "香水，面向年轻女士，4个关键帧",
    "publishSuccessDetail": "给我设计建议",
    "defaultChat": "为我的新厨房设计一个广告脚本。",
    "defaultChatColor": "颜色心理学室内设计，面向年轻人，现代，5个关键帧。",
    "defaultChatPerfume": "香水，面向年轻女士，4个关键帧。",
    "style": "官方风格",
    "styleImage": "上传参考图",
    "prompt": "文本提示",
    background:'背景',

    collov: '官方',
    yours: '你的',
    Share_with_me: '分享',
    Generate:'生成',
    team:'团队',

};
