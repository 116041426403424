export const floorDesign = {
  Introduce: {
    title:'Flooring Real Fill',
    subtitle: 'Visualize Real Materials in Your Space with AI Precision',
    text: 'Transforms how you design spaces, seamlessly integrating real materials for floors with AI accuracy, bringing your visions to life with stunning realism.',
    JoinNow: 'Join Now',
    TryDemo: 'Try Demo',
    Before: 'Before',
    After: 'After'
  },
  Sales: {
    l: 'Bridging <span>Design</span> and <span>Sales</span>',
    r: 'Bridge the gap between design and sales and offer customers the ability to explore a rich variety of floorings. This comprehensive access empowers customers to experiment with diverse options, ensuring informed decisions that align with their vision.'
  },
  Tools: {
    title: '5x Increase'
  },
  Transformation: {
    title: 'Create unique design and sales experiences as exceptional as your products',
    subTitle: 'All tools you need in one platform for showcasing and selling your products.',
    RealWorldFlooringLibrary: 'Real-World Flooring Library',
    RealWorldFlooringLibraryContent:`Transform your SKUs into dynamic digital assets with Collov AI. Provide customers with an extensive selection
    of real materials directly sourced from you, seamlessly linking home design with the shopping experience.`
  },
  Precision: {
    title: 'AI-PAI-Powered Precision Fit',
    subTitle: 'Utilize advanced AI to detect and precisely scale floorings, optimizing material placement and design harmony.'
  },
  Visuals: {
    title: 'Instant Ultra-Realistic Visuals',
    subTitle: 'Achieve ultra-realistic title, wallpaper and painting renderings in seconds, where cutting-edge technology meets design to bring your vision to life instantly and beautifully.'
  },
  Compare: {
    Before: 'Before',
    After: 'After',
    title: 'Compare and Contrast',
    subTitle: 'Effortlessly view before-and-after scenes or compare various design options side-by-side, empowering informed decisions through clear, visual transformation.'
  },
  More: {
    title: `Best Roomvo<br/>
    Alternative`,
    li: `<li>Expands beyond floorings to include cabinets, furniture, and more, all within Collov's AI design platform.</li>
    <li>Delivers a harmonized room design experience with intelligent matching.</li>
    <li>Ensures accurate fit with enhanced scaling and sizing for realistic visualizations.</li>`,
    AllinOnePlatform: 'All-in-One Platform',
    IntelligentDesignMatching: 'Intelligent Design Matching',
    MultiSurfaceSupported: 'Multi-surface Supported',
  }
  
}