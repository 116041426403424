import {corsImgSrc} from "@/hooks/imageCors";
import {userRecord} from "@/hooks/record";
import {designStore} from "@/stores/design";
import {useEventBus} from '@vueuse/core'
const eraseBus = useEventBus('erase')
function detectBrowser() {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf("Chrome") !== -1 && userAgent.indexOf("Safari") !== -1 && userAgent.indexOf("Edg") === -1) {
        return "Google Chrome"; // Chrome
    } else if (userAgent.indexOf("Firefox") !== -1) {
        return "Mozilla Firefox"; // Firefox
    } else if (userAgent.indexOf("Safari") !== -1 && userAgent.indexOf("Chrome") === -1 && userAgent.indexOf("Edge") === -1) {
        return "Apple Safari"; // Safari
    } else if (userAgent.indexOf("Edg") !== -1) {
        return "Microsoft Edge"; // Edge
    } else {
        return "Unknown browser"; // 其他浏览器...（可根据自己需要确定是否新增其他浏览器的判断）
    }
}

export const hasEmpty = () => {
    if (!result[key]) {
        return true
    }
    const blank = document.createElement('canvas');//系统获取一个空canvas对象
    blank.width = result[key].canvas.width;
    blank.height = result[key].canvas.height;
    return result[key].canvas.toDataURL() == blank.toDataURL();//比较值相等则为空

}
const scaleInfo={
    scale:1,
    offsetX:0,
    offsetY:0,
}
const CANVAS_STATUS = {
    IDEL: 0,
    START: 1,
    DRAW: 2,
    DISABLED: 3,
}
const result = {}
let key: string = 'default'
const setKey = (id?: string) => {
    if (id) {
        key = id
    }
}

class Draw {
    canvas: HTMLCanvasElement
    originWidth: number
    originHeight: number
    context: any
    current: string
    state: {
        type: string
        size: number
        lastX: number
        lastY: number
        status: number
    }

    constructor(canvas, context, type) {
        this.canvas = canvas
        this.originWidth = 0
        this.originHeight = 0
        this.context = context
        this.state = {
            type: type || 'cursor',
            size: 20,
            lastX: 0,
            lastY: 0,
            status: 0,
        }
        this.init()
    }

    init() {
        this.current = this.canvas.toDataURL("image/png")
        const record = userRecord()
        record.addData({image: this.current})
        this.setType(this.state.type)
        this.bindEvent()
    }

    setCurrent() {
        this.current = this.canvas.toDataURL("image/png")
    }

    bindEvent() {
        this.canvas.addEventListener('mousedown', this.handleMouseDown, false)
        this.canvas.addEventListener('touchstart', this.handleMouseDown, false)
        this.canvas.addEventListener('mousemove', this.handleMouseMove, false)
        this.canvas.addEventListener('touchmove', this.handleMouseMove, false)
        this.canvas.addEventListener('mouseup', this.handleMouseUp, false)
        this.canvas.addEventListener('touchend', this.handleMouseUp, false)
        document.addEventListener('mouseup', this.handleMouseUp, false)
    }
    scaleCanvas(offsetX,offsetY,scale){
        scaleInfo.scale=scale
        scaleInfo.offsetX=offsetX
        scaleInfo.offsetY=offsetY
    }
    handleMouseDown(e) {
        e.preventDefault()
        e.stopPropagation()
        result[key].context.closePath();
        result[key].context.save();
        if(scaleInfo.scale!==1){
            const s=result[key].canvas.width/(scaleInfo.scale*result[key].canvas.width)
            result[key].context.scale(s,s);
        }
        // result[key].context.translate(scaleInfo.offsetX,  scaleInfo.offsetY);
        if (!result[key].state.type || result[key].state.type == 'mask') {
            return;
        }
        if (result[key].state.status === CANVAS_STATUS['DISABLED']) {
            return
        } else {
            result[key].setStatus('START')
        }
    }

    getPostion(e: MouseEvent) {
        let agent = 0
        const rect = this.canvas.getBoundingClientRect();
        if (detectBrowser() === 'Apple Safari') {
            agent = this.context.lineWidth
        } else {
            agent = 0
        }
        e=  e.touches ? e.touches[0] : e
        const offsetX = this.context.lineWidth / 2 - agent
        const offsetY = this.context.lineWidth / 2 - agent
        const [x, y] = [e.clientX - rect.left - offsetX, e.clientY - rect.top - offsetY];
        return {x, y}
    }

    /*鼠标移动*/
    handleMouseMove(e) {
        e.preventDefault()
        e.stopPropagation()
        result[key].context.setLineDash([0]);
        if (result[key].state.status === CANVAS_STATUS['START']) {
            const {x, y} = result[key].getPostion(e)
            result[key].setStatus('DRAW')
            result[key].context.beginPath();
            result[key].context.moveTo(x, y);
        }
        if (result[key].state.status === CANVAS_STATUS['DRAW']) {
            const {x, y} = result[key].getPostion(e)
            if (result[key].state.type === 'brush' || result[key].state.type === 'deselect') {
                result[key].context.globalCompositeOperation = 'xor'
                result[key].handleBrush(result[key].state.lastX, result[key].state.lastY, x, y)
            } else if (result[key].state.type === 'lasso') {
                result[key].context.globalCompositeOperation = 'source-over'
                result[key].context.lineTo(x, y)
                result[key].context.setLineDash([5, 10]);
                result[key].context.stroke()
            } else {
                result[key].context.globalCompositeOperation = 'destination-out'
                result[key].handleBrush(result[key].state.lastX, result[key].state.lastY, x, y)
            }
        }
    }

    findContentRegions() {
        var canvas = this.canvas;
        var ctx = canvas.getContext('2d');
        var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        var pixels = imageData.data;

        var regions = [];
        var currentRegion = [];

        function floodFillNonRecursive(startX, startY) {
            var stack = [];
            stack.push({x: startX, y: startY});

            while (stack.length > 0) {
                var current = stack.pop();
                var x = current.x;
                var y = current.y;

                if (x < 0 || x >= canvas.width || y < 0 || y >= canvas.height) {
                    continue;
                }

                var index = (y * canvas.width + x) * 4;

                if (pixels[index + 3] === 0) {
                    continue;
                }

                pixels[index + 3] = 0; // Mark pixel as visited

                currentRegion.push({x: x, y: y});

                stack.push({x: x + 1, y: y});
                stack.push({x: x - 1, y: y});
                stack.push({x: x, y: y + 1});
                stack.push({x: x, y: y - 1});
            }
        }

        for (var y = 0; y < canvas.height; y++) {
            for (var x = 0; x < canvas.width; x++) {
                var index = (y * canvas.width + x) * 4;

                if (pixels[index + 3] !== 0) {
                    currentRegion = [];
                    floodFillNonRecursive(x, y);

                    if (currentRegion.length > 0) {
                        regions.push(currentRegion);
                    }
                }
            }
        }

        return regions;
    }

    async highlightContentRegions(url,pos) {
        return new Promise(resolve => {
            var canvas = document.createElement('canvas');
            canvas.width = this.canvas.width
            canvas.height = this.canvas.height
            var ctx = canvas.getContext('2d');
            this.loadImage(url).then(image => {
                ctx.drawImage(image, 0, 0, this.canvas.width, this.canvas.height)
                ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height)
                var regions = this.findContentRegions();

                // 在每个内容区域用红色虚线包裹起来
                ctx.strokeStyle = "red";
                ctx.lineWidth = 12;
                if(pos){
                    ctx.strokeRect(pos.x1, pos.y1, pos.x2 - pos.x1, pos.y2 - pos.y1)
                }else {
                    for (var i = 0; i < regions.length; i++) {
                        var region = regions[i];

                        if (region.length > 1) {
                            // 如果区域的像素数量大于1，绘制虚线包裹
                            var firstPixel = region[0];
                            var minX = firstPixel.x;
                            var minY = firstPixel.y;
                            var maxX = firstPixel.x;
                            var maxY = firstPixel.y;

                            for (var j = 1; j < region.length; j++) {
                                var pixel = region[j];
                                minX = Math.min(minX, pixel.x);
                                minY = Math.min(minY, pixel.y);
                                maxX = Math.max(maxX, pixel.x);
                                maxY = Math.max(maxY, pixel.y);
                            }

                            ctx.strokeRect(minX, minY, maxX - minX, maxY - minY);
                        }
                    }
                }
                resolve(canvas.toDataURL('image/png'))
            })
        })
    }

    /*鼠标抬起*/
    handleMouseUp(e) {
        result[key].context.restore();
        if (result[key].state.status === CANVAS_STATUS['DRAW']) {
            if (result[key].state.type === 'lasso') {
                result[key].context.globalCompositeOperation = 'source-over'
                result[key].context.stroke()
                result[key].context.fill()
                result[key].context.closePath()
            }
            result[key].setStatus('IDEL')
            result[key].setCurrent()
            const record = userRecord()
            const design = designStore()
            const {undo, redo} = record.save({image: result[key].current})
            design.undo = undo
            design.redo = redo
            design.form.maskUrl = result[key].saveCanvas()
            design.stepObj.active = design.getNextStep
            const {x, y} = result[key].getAskPos()
            design.stepObj.x = x
            design.stepObj.y = y
            design.stepObj.left = x
            design.stepObj.top = y
            if (!hasEmpty()) {
                design.stepObj.ask = true
            }
            record.addData({
                next: true,
            })
            if(result[key].state.type === 'deselect'){
              console.log('deselect', eraseBus)
              eraseBus.emit()
            }
            // result[key].highlightContentRegions(design.form.generateUrl);
        }else {
            const design = designStore()
            result[key].setStatus('IDEL')
            if (!hasEmpty()) {
                design.stepObj.ask = true
            }
        }
    }

    saveRecord() {
        const record = userRecord()
        const design = designStore()
        const {undo, redo} = record.save({image: result[key].current})
        design.undo = undo
        design.redo = redo
    }

    handleBrush(x1: number, y1: number, x2: number, y2: number) {
        // 使用贝塞尔曲线绘制平滑路径
        /*       result[key].context.beginPath();
               result[key].context.moveTo(x1, y1);
               result[key].context.lineTo(x2, y2);
               result[key].context.stroke();
               result[key].context.closePath();*/
        result[key].context.lineTo(x2, y2);
        result[key].context.stroke();
        result[key].state.lastX = x2
        result[key].state.lastY = y2
    }

    setOrigin(width, height) {
        this.originWidth = width
        this.originHeight = height
    }

    setColor(color, fcolor) {
        this.context.strokeStyle = color
        this.context.fillStyle = fcolor || color
    }

    resetSize(width: number, height: number) {
        const type = this.state.type
        const base64 = this.canvas.toDataURL();
        this.canvas.width = width||this.canvas.width
        this.canvas.height = height||this.canvas.height
        const img = new Image();
        const _this = this
        img.onload = function () {
            _this.context.drawImage(img, 0, 0, _this.canvas.width, _this.canvas.height);
        }
        img.src = base64;
        const templateCanvas = document.createElement('canvas')
        const ctx = templateCanvas.getContext('2d')
        templateCanvas.width = this.canvas.width
        templateCanvas.height = this.canvas.height
        ctx.drawImage(this.canvas, 0, 0)
        /*重制*/
        this.canvas.width = width
        this.canvas.height = height
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
        this.context.drawImage(templateCanvas, 0, 0, width, height)
        this.bindEvent()
        this.setType(type)
    }

    setPointer() {
        if (this.state.type === 'lasso') {
            this.canvas.style.cursor = 'crosshair'
        } else if (this.state.type === 'brush' || this.state.type === 'eraser' || this.state.type === 'deselect') {
            const cursorURL = `url('data:image/svg+xml;utf8, \
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="${this.context.lineWidth}" height="${this.context.lineWidth}"> \
          <circle opacity="0.8" cx="${this.context.lineWidth / 2}" cy="${this.context.lineWidth / 2}" r="${this.context.lineWidth / 2}" fill="white"/> </svg>') \
          ${this.context.lineWidth} ${this.context.lineWidth}, auto`;
            this.canvas.style.cursor = cursorURL
        } else if (this.state.type === 'mask') {
            this.canvas.style.cursor = 'pointer'
        } else if (this.state.status === 3) {
            this.canvas.style.cursor = 'not-allowed'
        }
    }

    setStatus(status) {
        if (typeof status === "number") {
            this.state.status = status
        } else {
            this.state.status = CANVAS_STATUS[status]
        }

    }

    loadImage(url) {
        return new Promise(((resolve, reject) => {
            const image = new Image()
            if (!url) {
                reject(false)
            }
            if (url.indexOf('http') > -1) {
                image.src = corsImgSrc(url)
            } else {
                image.src = url
            }
            image.setAttribute("crossOrigin", 'Anonymous')
            image.onload = function () {
                resolve(image)
            }
        }))
    }

    clear() {
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
    }

    setSize(size) {
        if (this.state.type === 'lasso') {
            this.context.lineWidth = 1
        } else {
            this.state.size = size
            this.context.lineWidth = size
        }
        this.setPointer()
    }

    setType(type) {
        this.state.type = type
        this.setSize(this.state.size)
        if (type === 'lasso') {
            this.setColor('#fff', 'rgba(255, 255, 255, 0.50)')
        } else {
            this.setColor('rgba(255, 255, 255, 0.50)')
        }
        if (type === 'mask') {
            this.context.globalAlpha = 0.5
        } else {
            this.context.globalAlpha = 1
        }
        const design = designStore()
        design.drawType = type
        return this
    }

    getAskPos() {
        const imageData = this.context.getImageData(0, 0, this.canvas.width, this.canvas.height);
        const pixels = imageData.data;
        // 查找最下边位置
        let bottomY = this.canvas.height - 1;
        let rightX = this.canvas.width - 1;
        for (var y = this.canvas.height - 1; y >= 0; y--) {
            for (var x = this.canvas.width - 1; x >= 0; x--) {
                var index = (y * this.canvas.width + x) * 4;

                // 查找最下边位置
                if (pixels[index + 3] !== 0 && bottomY === this.canvas.height - 1) {
                    bottomY = y;
                }

                // 查找最右边位置
                if (pixels[index + 3] !== 0 && rightX === this.canvas.width - 1) {
                    rightX = x;
                }

                // 如果已经找到最下边和最右边位置，退出循环
                if (bottomY !== this.canvas.height - 1 && rightX !== this.canvas.width - 1) {
                    break;
                }
            }
        }
        return {y: bottomY, x: rightX};
    }

    drawImage(list, notClear?: boolean) {
        this.setPointer()
        if (!notClear) {
            this.clear()
        }
        this.context.globalAlpha = 0.5
        return new Promise(resolve => {
            this.context.globalCompositeOperation = 'source-over'
            if (list.length) {
                for (let i = 0; i < list.length; i++) {
                    this.loadImage(list[i]).then(image => {
                        this.context.drawImage(image, 0, 0, this.canvas.width, this.canvas.height)
                        if (this.state.type === 'mask') {
                            const design = designStore()
                            const {x, y} = result[key].getAskPos()
                            design.stepObj.x = x
                            design.stepObj.y = y
                            design.stepObj.left = x
                            design.stepObj.top = y
                            design.stepObj.ask = true
                        }
                        resolve()
                    })
                }
            } else {
                if (this.state.type === 'mask') {
                    const design = designStore()
                    design.stepObj.ask = false
                }
            }
            resolve()

        })
    }

    saveCanvas() {
        const width = this.originWidth
        const height = this.originHeight
        const imageData = this.context.getImageData(0, 0, this.canvas.width, this.canvas.height)
        for (let i = 0; i < imageData.data.length; i += 4) {
            const a = imageData.data[i + 3]
            if ((a !== 0)) {
                // 设置涂抹的部分
                imageData.data[i] = 255;
                imageData.data[i + 1] = 255;
                imageData.data[i + 2] = 255;
                imageData.data[i + 3] = 255;
            } else {
                imageData.data[i] = 0;
                imageData.data[i + 1] = 0;
                imageData.data[i + 2] = 0;
                imageData.data[i + 3] = 0;
            }
        }
        const saveCanvas = document.createElement("canvas")
        saveCanvas.width = this.canvas.width
        saveCanvas.height = this.canvas.height
        const ctx = saveCanvas.getContext('2d')
        ctx.putImageData(imageData, 0, 0)
        if (width && height && width !== saveCanvas.width) {
            const newCanvas = document.createElement('canvas')
            newCanvas.width = width
            newCanvas.height = height
            const newCtx = newCanvas.getContext('2d')
            newCtx.drawImage(saveCanvas, 0, 0, newCanvas.width, newCanvas.height)
            return newCanvas.toDataURL("image/png")
        } else {
            return saveCanvas.toDataURL("image/png")
        }
    }

    savePosCanvas(pos) {
        const saveCanvas = document.createElement("canvas")
        const width = this.originWidth
        const height = this.originHeight
        saveCanvas.width = this.canvas.width
        saveCanvas.height = this.canvas.height
        const ctx = saveCanvas.getContext('2d')
        ctx.fillStyle = 'white'
        ctx.strokeStyle = 'white'
        ctx.fillRect(pos.x1, pos.y1, pos.x2 - pos.x1, pos.y2 - pos.y1)
        if (width && width !== this.canvas.width) {
            const newCanvas = document.createElement('canvas')
            newCanvas.width = width
            newCanvas.height = height
            const newCtx = newCanvas.getContext('2d')
            newCtx.drawImage(saveCanvas, 0, 0, newCanvas.width, newCanvas.height)
            return newCanvas.toDataURL("image/png")
        } else {
            return saveCanvas.toDataURL("image/png")
        }
    }

    saveImagePos(url, pos) {
        if(!pos||!pos.box){
            pos={
                box: [ 99.1530990600586, 14.161324501037598,  222.650146484375,  170.50291442871094 ],
            }
        }

        return new Promise(resolve => {
            const saveCanvas = document.createElement("canvas")
            const saveContent = saveCanvas.getContext('2d')
            saveCanvas.width = this.canvas.width
            saveCanvas.height = this.canvas.height
            const ctx = saveCanvas.getContext('2d')
            this.loadImage(url).then(image => {
                saveCanvas.width=image.width
                saveCanvas.height=image.height
                saveContent.drawImage(image, 0, 0, image.width, image.height)
                const x1=pos.box[0]
                const x2=pos.box[2]
                const y1=pos.box[1]
                const y2=pos.box[3]
                const croppedCanvas = document.createElement('canvas');
                croppedCanvas.width = x2-x1;
                croppedCanvas.height = y2-y1;
                const croppedContext = croppedCanvas.getContext('2d');
                croppedContext.drawImage(saveCanvas, x1,y1, croppedCanvas.width, croppedCanvas.height, 0, 0, croppedCanvas.width, croppedCanvas.height);
                /*保存mask图片*/
                saveContent.clearRect(0,0,saveCanvas.width,saveCanvas.height)
                saveContent.fillStyle = 'white';
                saveContent.fillRect(x1, y1, croppedCanvas.width, croppedCanvas.height);
                const imageBase64=croppedCanvas.toDataURL('image/jpeg')
                const maskBase64=saveCanvas.toDataURL('image/jpeg')
                resolve({image:imageBase64,mask:maskBase64,url:url})
            })
        })

    }

}

export const createDraw = (canvasElement: HTMLCanvasElement | '', width: number, height: number, id: string, type) => {
    setKey(id)

    /*    if(result[key]){
            return result[key]
        }*/

    function init() {
        const canvas = canvasElement ? canvasElement : document.createElement('canvas')
        const context = canvas.getContext('2d', {willReadFrequently: true})
        canvas.width = width
        canvas.height = height
        context.lineCap = 'round'
        context.lineJoin = 'round'
        result[key] = new Draw(canvas, context, type)
    }

    init()
    return result[key]
}
export const useDraw = (id?: string) => {
    setKey(id)
    return result[key]
}
