<template>
  <div class="component-login">
    <h5 class="title" v-if="$i18n.locale==='zh'">抢先注册，每天送10个VIP</h5>
    <h5 class="title" v-t="'btn.signUp'" v-else></h5>
    <div class="emphasis-footnote">
      {{ $t('title.register') }} <span class="btn" @click="changeLoginType('login')" v-t="'btn.signIn'"></span>
    </div>
    <div class="custom-form-error" v-if="store.errMessage">
      {{ store.errMessage }}
    </div>
    <div style="margin-top: 48px;">
      <mobile-code  v-if="$i18n.locale==='zh'" loginType="register"/>
      <login-form v-else/>
      <div class="tip">{{ $t('form.passwordTip') }}</div>
      <c-button color="white" block type="primary" square @toClick="toRegister" :loading="store.loading"
                style="margin-top: 48px;">
        <span v-t="'btn.continue'" style="text-transform: uppercase"></span></c-button>
      <oauth-login/>
    </div>
  </div>
</template>
<script setup lang="ts">
import LoginForm from '../form/index.vue'
import MobileCode from '../form/mobileCode.vue'
import OauthLogin from '../oauthLogin.vue'
import {clientStore} from "@/stores/client";
import { useI18n } from 'vue-i18n'
const { locale}  = useI18n()

const store = clientStore()
const changeLoginType = (type: string) => {
  store.loginVisible = true
  store.loginType = type
}

//去注册
const toRegister = () => {
  store.register(locale.value)
  /*const status=vaildateForm()
  if (!status){
    return
  }
  loading.value=true
  errMessage.value=''
  store.register(form.value).then((res:any)=>{
    loading.value=false
    if(res.success){
      store.$patch(state=>{
        state.clientData={
          ...form.value
        }
      })
      changeLoginType('created')
    }else {
      errMessage.value=res.message
    }
  }).catch(()=>{
    loading.value=false
  })*/
}

</script>
<style scoped lang="less">
.component-login {
  width: 456px;

  .title {
    font-size: clamp(18px, 2.42vw, 24px);
    color: #44484c;
    line-height: 1.33;
    font-weight: 600;
    letter-spacing: .01em;
  }

  .custom-form-error {
    color: @color-error;
    font-size: 13px;
    margin-top: 12px;
  }

  .emphasis-footnote {
    font-size: 14px;
    color: #71787f;
    line-height: 1.57;
    font-weight: 600;
    letter-spacing: .0175em;

    .btn {
      cursor: pointer;
      line-height: 1;
      color: #806b5a;
      display: inline-block;
      border-bottom: 1px solid #806b5a;
    }
  }

  .login-btn {
    .flex-end();
    margin-top: 12px;
  }

  .tip {
    font-size: 12px;
    line-height: 18px;
    color: #aaaeb2;
    font-family: ProximaNova;
  }
}
</style>
